/**
 * 
 * Notes -
 * 
 * Links
 * 
 * [Nick's tutorial custom model in TFJS](https://www.youtube.com/watch?v=ZTSRZt04JkY&ab_channel=NicholasRenotte)
 * 
 * 
 * 
 */


import { useMemo, useRef, useState, useEffect, useCallback } from "react";
import { useHistoryState, useWindowSize } from "@uidotdev/usehooks";
import { Camera, CameraFill, Hypnotize, Repeat, Lightbulb, CameraVideo, ArrowRight, Play, CameraVideoFill } from "react-bootstrap-icons";
import { Button, Col, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Canvas from "../helpers/canvasHelpers";
import { filesize } from "filesize";
import axios from "axios";
import { API_URL, REMOTE_URL, MODEL_URL } from "../../config/api";
import authHeader from "../services/auth-header";
import * as tf from "@tensorflow/tfjs"
import * as cocossd from "@tensorflow-models/coco-ssd";
import predictMask from "../helpers/predictMask";
import { predictOnPotentialTubes } from "../helpers/predictOnPotentialTubes";
import maskToTubes from "../helpers/maskToTubes";
import { annotateTube } from "./annotateTube";
import { useNavigate } from "react-router-dom";
import GloAnnotate from "./annotate";
import Webcam from "react-webcam";

import { drawRect } from "../helpers/drawRect";


const GloObjectDemo = () => {
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    const size = useWindowSize();
    const ratio = useMemo(() => {
        return size.width/size.height;
    }, [size]);

    // Main function
    const runCoco = async () => {
        const net = await cocossd.load();
        //  Loop and detect hands
        setInterval(() => {
            detect(net);
        }, 125);
    };

    const detect = async (net) => {
        console.info('detecting')
        // Check data is available
        if (
          typeof webcamRef.current !== "undefined" &&
          webcamRef.current !== null &&
          webcamRef.current.video.readyState === 4
        ) {
          // Get Video Properties
          const video = webcamRef.current.video;
          const videoWidth = webcamRef.current.video.videoWidth;
          const videoHeight = webcamRef.current.video.videoHeight;
    
          // Set video width
          webcamRef.current.video.width = videoWidth;
          webcamRef.current.video.height = videoHeight;
    
          // Set canvas height and width
          canvasRef.current.width = videoWidth;
          canvasRef.current.height = videoHeight;
    
          // Make Detections
          const obj = await net.detect(video);

          console.info('obj', obj)
    
          // Draw mesh
          const ctx = canvasRef.current.getContext("2d");
          drawRect(obj, ctx); 
        }
      };

    
    useEffect(()=>{
        console.log("loading coco")
        runCoco()
    },[]);
    
    return <div>
        <Webcam ref={webcamRef}
            muted={true} 
            //  height={size.height} 
            //  width={size.width}
            // height={880}
            // width={640}
             videoConstraints={{
                // facingMode: 'user', 
                facingMode: 'environment', 
                // aspectRatio: ratio
                aspectRatio:1.375,
                height: 680,
                width:640
            }}
             style={{
                // objectFit: 'cover',
                // objectPosition: 'center center'
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                left: 0,
                right: 0,
                textAlign: "center",
                zindex: 9,
                border:'1px solid grey'
              }}
        />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 8,
            width: 640,
            height: 680,
          }}
        />
    </div>
}

export default GloObjectDemo