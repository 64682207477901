import { Component } from "react";
import AuthService from "./services/auth.service";
import { Link, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import { Trash, Wrench } from "react-bootstrap-icons";
import axios from "axios";
import { API_URL, REMOTE_URL } from "../config/api";
import { useEffect } from "react";
import { FileImage, Save } from "react-bootstrap-icons";
import {filesize} from "filesize";
import authHeader from "./services/auth-header"
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';

import * as tf from "@tensorflow/tfjs"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// see http://localhost:8080/api/protocols for api with protocol name+id


const Canvas = props => {
  
    const canvasRef = useRef(null)
    
    if(props.img !== undefined) {
        console.log("canvas has img");
        console.log(props.img);
    }
    
    const draw = ctx => {
      ctx.fillStyle = '#000000'
      ctx.beginPath()
      ctx.arc(50, 100, 20, 0, 2*Math.PI)
      ctx.fill()
    }
    
    useEffect(() => {
      
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      
      // calc some padding?
      const natural_wh_ratio = props.img.naturalWidth / props.img.naturalHeight;
      const canvas_wh_ratio = props.width / props.height;

      var new_height = 1;
      var new_width = 1;

      console.log(`Canvas w${canvas.width} h${canvas.height}`)

      if (natural_wh_ratio <= canvas_wh_ratio) {
        console.log("pad x");
        new_height = canvas.height;
        new_width = canvas.height * natural_wh_ratio;
        console.log(`newh:${new_height} neww:${new_width}`)
      } else {
        // pad h
        console.log("pad h")
        new_width = canvas.width;
        new_height = canvas.width / natural_wh_ratio;
        console.log(`newh:${new_height} neww:${new_width}`)
      }
      console.log(`newh:${new_height} neww:${new_width}`)
      console.log(props.img.naturalWidth + "x" + props.img.naturalHeight)

      context.drawImage(props.img, 0, 0, new_width, new_height);

      //Our draw come here
      draw(context)
    }, [draw])
    
    return <canvas ref={canvasRef} {...props}/>
  }


export default function Batch() {

    const currentUser = AuthService.getCurrentUser();
    const {id} = useParams();
    const hiddenFileInput = useRef(null);
    const canvasOverlay = useRef();

    const [columns, setColumns] = useState([{id:'new',tubes:[]}]);
    const [currentModel, setCurrentModel] = useState(undefined);
    const [net, setNet] = useState(undefined);
    const [numWindows, setNumWindows] = useState(1);
    const [originalImage, setOriginalImage] = useState(undefined);
    const [predictedMask, setPredictedMask] = useState(null);
    const [protocols, setProtocols] = useState([]);
    const [preview, setPreview] = useState();
    const [progress, setProgress] = useState(0);
    const [projects, setProjects] = useState([]);
    const [selectedProtocolId, setSelectedProtocolId] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [status, setStatus] = useState('idle');
    const [visionmodels, setVisionmodels] = useState([]);

    const [batchMetadata, setBatchMetadata] = useState({})

    const navigate = useNavigate();


    function getBatch() {
        axios
            .post(
                API_URL +'api/batch/' + id,
                [],
                { 
                    headers: Object.assign(authHeader())
                }    
            )
            .then(function (response) {
                console.log("batch info:")
                console.info(response.data);

                const batch = response.data.batch;

                if (batch.protocolId === null) {
                    batch.protocolId = "";
                }
                // setcolumns

                setColumns(batch.columns);
                setSelectedProtocolId(batch.protocolId);
                setSelectedProjectId(batch.projectId);
                setBatchMetadata(batch);

                if (batch.images.length > 0) {
                    console.log("Setting setSelectedFile to latest image:")
                    const latest_image = batch.images[batch.images.length-1];
                    latest_image.last_modified_str = new Date(latest_image.last_modified).toString()
                    setSelectedFile(latest_image)
                }
            })
            .catch(function (err) {
                //handle error
                console.warn(err);
                setStatus('idle');
            });

    };

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        // exiting file = serve thumbnail
        if (selectedFile.id) {
            setPreview(API_URL + "images/preview/" + selectedFile.userId + "/" + selectedFile.id)
        } else {
            // new file, temporarily stored locally.
            const objectUrl = URL.createObjectURL(selectedFile)
            setPreview(objectUrl)
    
            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [selectedFile])

    // On file select (from the pop up)
    function onFileChange(event) {
        const newFile = event.target.files[0];

        // check selected file is an image..
        if (newFile.type !== undefined && newFile.type.substr(0,6) != "image/") {
            console.log("selected file is not an image");
            setSelectedFile(undefined);
            return;
        }

        // set a friendly string for last_modified, this is only used to display
        if (newFile.lastModified !== undefined) {
            console.info(newFile)
            console.info(new Date(newFile.lastModified))
            newFile.last_modified_str = new Date(newFile.lastModified).toString()
        } else if (newFile.lastModifiedDate !== undefined) {
            newFile.last_modified_str = newFile.lastModifiedDate;
        } else {
            newFile.last_modified_str = new Date(Date.now());
        }

        console.info('set selected file', newFile)

        // Update the state
        setSelectedFile(newFile);        
    };
    
    // On file upload (click the upload button)
    // if we switch to a component, we should place inside componentDidMount - see https://www.bezkoder.com/react-file-upload-axios/
    function useBatchUpload(event) {
        setStatus('uploading');

        const formData = new FormData();
        // formData.append('file', this.file);

        formData.append('batchId',id);
        formData.append('columns',JSON.stringify(columns));
        formData.append('selectedProtocol', selectedProtocolId);
        
        formData.append('selectedProject', selectedProjectId);

        
        console.log("columns:")
        console.info(columns)
        console.info(JSON.stringify(columns))

        if (selectedFile !== undefined) {
            formData.append('fileMetadata', JSON.stringify(selectedFile, ['name','lastModified','lastModifiedDate','size']));
            formData.append('imageId', 'new')
            formData.append('image', selectedFile);
        }

        axios.post(
            // method: 'post',
            API_URL + "api/batch",
            formData,
            { 
                headers: Object.assign(authHeader()),
                onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

                    if (percentCompleted !== progress) {
                        setProgress(percentCompleted);
                        console.log(percentCompleted + '%');
                    }
                  }
            }
        )
        .then(
                function (response) {
                    //handle success
                    setStatus('idle');
                    const redirect_url = '/batch/' + response.data.batch.id
                    navigate(redirect_url);
                }
        )
        .catch(function (response) {
            //handle error
            console.warn(response);
            setStatus('error');
        });
    };


    // File content to be displayed after
    // file upload is complete
    function fileData() {

        if (selectedFile !== undefined) {
            
          return (
            <div className='row'>
                <div className='col col-md9'>
                    <h2>File Details:</h2>
                    <p>File Name: {selectedFile.name}</p>
            
                    <p>File Type: {selectedFile.type}</p>
            
                    <p>
                        Last Modified:{" "}
                        {selectedFile.last_modified_str}
                    </p>
                    <p>
                        File size:{" "}
                        {selectedFile.filesize_human ? selectedFile.filesize_human : filesize(selectedFile.size, {round:1})}
                    </p>
                </div>
                <div className='col col-md3'>
                    {selectedFile &&  <img src={preview} style={{maxWidth: "100%", maxHeight: "300px"}} alt={`Preview of ${selectedFile.name}`} /> }
                </div>
    
            </div>
          );
        } else {
          return (
            undefined
          );
        }
      };
       


    function addColumn() {
        let newColumns = [...columns] // shallow copy
        newColumns.push({id:'new',tubes:[]})
        setColumns(newColumns)
    }
    
    const header = <div className='row'>
        <div className='col col-6'>
            <h1 className="glo display-1"><a href='/'>GLO</a></h1>
        </div>
        <div className='col col-6'>
            <div className='signedInInfo py-2 text-info'>
                <div>{currentUser.forename}</div>
                <Link to='/dashboard'>Dashboard</Link>
                {' | '}
                <Link to='/benchmarking'>Benchmarking</Link>
            </div>
        </div>
    </div>

    function Tube({tube, colIndex, tubeIndex}) {

        function tubeChanged(e) {
            let newColumns = [...columns] // shallow copy
            let column = newColumns[colIndex]

            console.log("tube changed from")
            const tube = column.tubes[tubeIndex]
            
            console.log(tube)

            column.tubes[tubeIndex] = {'id':tube.id, 'description': e.target.value, 'updated': true}
            newColumns[colIndex] = column
            setColumns(newColumns)
        }
        
        function deleteTube() {
            let newColumns = [...columns] // shallow copy
            let column = newColumns[colIndex]
            column.tubes.splice(tubeIndex, 1)
            newColumns[colIndex] = column
            setColumns(newColumns)
        }

        return <div>
            <input onBlur={tubeChanged} 
              type='text'
              placeholder={"Tube " + tubeIndex}
              defaultValue={tube.description === "" ? "" : tube.description}
              />
            <button onClick={deleteTube} className='btn btn-outline-danger'><Trash /></button>
        </div>
    }

    function Column({column, colIndex}) {
        function addTube() {
            let newColumns = [...columns] // shallow copy
            console.log("Adding tube to column " + colIndex)
            newColumns[colIndex].tubes.push({id:'new','description':'', createdAt: Date.now(), updatedAt: Date.now()})
            setColumns(newColumns)
        }

        function deleteColumn() {
            let newColumns = [...columns] // shallow copy
            newColumns.splice(colIndex,1)
            setColumns(newColumns)
        }

        const tubesDOM = column.tubes.map((obj, i) => {
            return <Tube tube={obj} key={i} colIndex={colIndex} tubeIndex={i} />
        })

        return <div className='card col mx-1 pb-2'>
            <p>{column.id}</p>

            <button onClick={addTube} className='btn btn-primary'>Add tube</button>
            
            <p>{column.tubes.length} tubes</p>
            
            {tubesDOM}

            { column.tubes.length === 0
              && <button onClick={deleteColumn} className='btn btn-secondary'>Delete column</button>
            }

        </div>
    }

    const columnsHtml = columns.map((obj,i) => {
        return <Column column={obj} colIndex={i} key={i} />
      })

    const protocolOptions = protocols.map((obj,i) => {
        return <option value={obj.id} key={i}>{obj.id}. {obj.title}</option>
    })

    const projectOptions = projects.map((obj,i) => {
        return <option value={obj.id} key={i}>{obj.id}. {obj.title}</option>
    })

    function callProtocols() {
        try {
          axios
            .get(API_URL + "api/protocols")
            .then(response => {
              setProtocols(response.data);
              if (selectedProtocolId !== "") {
                console.log('loaded protocols, setting to ' + selectedProtocolId);

                setSelectedProtocolId(selectedProtocolId);
              }
            })
            .catch(function(error) {
              console.warn("Axios caught an error")
              
              setProtocols([])
  
              if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                  if (error.response.data.message) {
                      console.log("Setting message to ",error.response.data.message)
                  }
              } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.warn(error.request);
              } else {
                  // Something happened in setting up the request that triggered an Error
                  console.warn('Error', error.message);
              }
            })
        } catch(err) {
            console.warn("ERRRRROR")
            console.info(err)
            setProtocols([])
        }
    }

    useEffect(() => {
        callProtocols();
        
        // get projects
        axios.get(
                // url
                API_URL + "api/projects",
                // params
                {
                    headers: Object.assign(authHeader())
                }
            ).then((response) => {
                setProjects(response.data.projects);
            });

        // load vision models
        axios.get(
            // url
            API_URL + "api/visionmodels",
            // data
            {},
            // extra stuff
            {
                headers: Object.assign(authHeader())
            }
        ).then((response) => {
            setVisionmodels(response.data);
        });
    },[]);


    useEffect(() => { 
        if (id == 'new') {
            return;
        }

        getBatch(); },[])

    function progressBar() {
        return <div className="progress">
            <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
                >
            {progress}%
            </div>
        </div>
    }

    const TubeColumns = <>
        <p>{columns.length} columns</p>
        
        <div className='row'>
            {columnsHtml}
        </div>
    </>

    const ProtocolSteps = () => {
        if (selectedProtocolId === "") {
            return <>not selected</>;
        }

        var selectedProtocol = protocols.filter(
            protocol => {
                return protocol.id === selectedProtocolId;
            }
        )

        if (selectedProtocol.length===1) {
            selectedProtocol = selectedProtocol[0];
        } else {
            return <>not found</>;
        }

        return <div>
                <h4>{selectedProtocol.protocolSteps.length} steps:</h4>
                <ol>
                    {selectedProtocol.protocolSteps.map((step,i) => {
                            return(<li key={step.id}>{step.instruction}</li>);
                        })
                    }
                </ol>
            </div>

        // return <div>protocol steps tbc {selectedProtocolId}</div>
    }


    
        
    function handleFileClick(event) {
        hiddenFileInput.current.click();
    }

   

    function selectProtocol(e) {
        const selected_protocol_id = parseInt(e.target.value);
        setSelectedProtocolId(selected_protocol_id);
    }

    function selectProject(e) {
        const selected_project_id = parseInt(e.target.value);
        setSelectedProjectId(selected_project_id);
        console.log("selected project " + selected_project_id)
    }

    const BatchTimeStamps = () => {
        if (batchMetadata.createdAt !== undefined) {
            return <p>Batch metadata goes here: created {batchMetadata.createdAt} | last saved: {batchMetadata.updatedAt}</p>
        }
        return <p>Batch metadata goes here.</p>
    }

    
    const loadModel = (e) => {
        console.log("loadModel")
        console.log(e.target.value);

        if (e.target.value === "") {
            console.info("unsetting model");
            setCurrentModel(undefined);
            setNet(undefined);
        }

        const model = visionmodels.find(obj => {
            return obj.id == e.target.value;
        })
        setCurrentModel(model);

        // load model from cache.
        const localKey = `indexeddb://net_${model.id}`;

        const onModelLoadCallback = (net, saveToLocal) => {
            setNet(net)
            console.log("loaded net")
            
            // save net to localstorage
            if (saveToLocal) {
                console.log(`Saving model to ${localKey}`)
                net.save(localKey)
                .then(() => {
                    console.log(`saved to ${localKey}`)
                });
            }
        }

        tf.loadLayersModel(localKey)
            .then(net => {
                console.log("loaded model from " + localKey);
                onModelLoadCallback(net, false);
            })
            .catch(err => {
                console.log("not found - loading model from URL.")
                // const modelUrl = process.env.PUBLIC_URL + "/" + model.path + "/model.json";

                
                const modelUrl = API_URL + "/" + model.path + "/model.json";

                tf.loadLayersModel(modelUrl, {
                    // onProgress: progressEvent
                }).then(net => {
                    onModelLoadCallback(net, true);
                })
            })
    }


    const TUBE_SEGMENTATION_COLOURS = [
        [180, 180, 180, 30],   // background - grey
        [255, 0, 0 , 128],      // tip
        [80, 160, 255, 128]     // tube
      ]

    const getPrediction = () => {
        // calculate canvas wh stuff
        const canvas = document.getElementById('myCanvas');

        // calc some padding?
        const natural_wh_ratio = selectedFile.width / selectedFile.height;
        const canvas_wh_ratio = canvas.width / canvas.height;

        var new_height = 1;
        var new_width = 1;

        console.log(`Canvas w${canvas.width} h${canvas.height}`)

        if (natural_wh_ratio <= canvas_wh_ratio) {
            console.log("pad x");
            new_height = canvas.height;
            new_width = canvas.height * natural_wh_ratio;
            console.log(`newh:${new_height} neww:${new_width}`)
        } else {
            // pad h
            console.log("pad h")
            new_width = canvas.width;
            new_height = canvas.width / natural_wh_ratio;
            console.log(`newh:${new_height} neww:${new_width}`)
        }
        const scale = new_width / selectedFile.width;
        console.log(`newh:${new_height} neww:${new_width} scale:${scale}`)


        // proportion to overlap
        var overlap = (numWindows==1 ?  0 : .1);

        var windowSize = (1 + overlap) * Math.min(selectedFile.width, selectedFile.height)/numWindows;
        
        // console.log(`numwindows:${numWindows} overlap:${overlap} window size: ${windowSize}`)

        const numWindowsX = Math.ceil(selectedFile.width / windowSize);
        const numWindowsY = Math.ceil(selectedFile.height / windowSize);

        var totalOverlapX = (numWindowsX * windowSize) - selectedFile.width;
        var totalOverlapY = (numWindowsY * windowSize) - selectedFile.height;

        // console.log(`window array: ${numWindowsX}x${numWindowsY}`)
        // console.log(`Total overlapX:${totalOverlapX} Y:${totalOverlapY}`)

        var xOverlap = (numWindowsX==1 ? 0 : totalOverlapX / (numWindowsX-1));
        var yOverlap = (numWindowsY==1 ? 0 : totalOverlapY / (numWindowsY-1));


        var windows = [];
        var boxes = [];
        for (var i = 0; i < numWindowsX; i++) {
            for (var j=0; j < numWindowsY; j++) {
                var x1 = Math.round(i * (windowSize-xOverlap));
                var x2 = Math.round(x1 + windowSize);
                var y1 = Math.round(j * (windowSize-yOverlap));
                var y2 = Math.round(y1 + windowSize);
                // console.log(`${i},${j} - ${x1},${y1} - ${x2},${y2}`)
                windows.push({x1:x1, x2:x2, y1:y1, y2:y2})
                boxes.push([y1 / selectedFile.height,x1 / selectedFile.width ,y2 / selectedFile.height,x2 / selectedFile.width]);


            }
        }
        // console.log(windows);
        // console.log(boxes);

        var originalTensor = tf.browser.fromPixels(originalImage, 3);

        window.originalTensor = originalTensor;

        // normalise by /255 ?

        var normalized = (
              currentModel.normalise 
            ? originalTensor.div(tf.scalar(255.0)) 
            : originalTensor
            );

        // var normalized = originalTensor
        //     .div(tf.scalar(255.0));

        normalized = normalized.expandDims(0);

        window.normalized = normalized;

        window.tf = tf;
        window.boxes = boxes;

        var counter = 0;

        
        const ctx = canvasOverlay.current.getContext('2d');

        const callback = () => {
            const box = boxes.pop();
            const window = windows.pop();
            counter = counter + 1;
            console.info("CALLBACK " + counter);

            // make a prediction on the window portion of originalTensor
            // var windowTensor = tf.image.cropAndResize(normalized, [box], [0], [224,224])
            var windowTensor = tf.image.cropAndResize(normalized, [box], [0], [net.feedInputShapes[0][1],net.feedInputShapes[0][2]])

            // console.log('windowTensor:')
            // console.log(windowTensor);


            // console.log("making a prediction")
            console.log("NET");
            console.info(net);

            // make prediction
            const timer_start = performance.now();
            const prediction =  net.predict(windowTensor);
            const timer_end = performance.now();

            console.log(`Made a prediction in ${timer_end - timer_start}ms`)

            console.log(prediction);
            const pred_mask = tf.argMax(prediction, -1)
            const pred_mask_array = pred_mask.dataSync()

            
            // draw to canvas.
            // const numPixels = 224 * 224
            const numPixels = net.feedInputShapes[0][1] * net.feedInputShapes[0][2];

            const rgbVals = new Uint8ClampedArray(numPixels*4);

            for (var x = 0; x < numPixels; x++) {
                var pixel_prediction = pred_mask_array[x]
                
                var rgba = TUBE_SEGMENTATION_COLOURS[pixel_prediction]
                rgbVals[4 * x + 0] = rgba[0]
                rgbVals[4 * x + 1] = rgba[1]
                rgbVals[4 * x + 2] = rgba[2]
                rgbVals[4 * x + 3] = rgba[3]
            }

            const maskImageData = new ImageData(rgbVals,net.feedInputShapes[0][1],net.feedInputShapes[0][2]);

            console.log(box);
            console.log(window);

            var px = window.x1 * scale;
            var py = window.y1 * scale;
            var pw = (window.x2 - window.x1) * scale;
            var ph = (window.y2 - window.y1) * scale;

            console.log(`${px},${py} w:${pw} h:${ph} s:${scale}`)
            createImageBitmap(maskImageData).then((sprites) => 
                ctx.drawImage(sprites, px, py, pw, ph)
            )
            ctx.strokeStyle = "red";
            ctx.strokeRect(px, py, pw, ph);


            if (boxes.length === 0) {
                console.log("finished");
                return;
            }

            setTimeout(() => {
                tf.tidy(() => callback());
                // callback()
            },10);

            return(pred_mask_array);
        }


        // callback();
        const pred_mask = tf.tidy(() => callback());
        window.pm = pred_mask;
        const pred_mask_array = window.tf.reshape(window.pm,[224,224]).arraySync()

        console.log("PPPPPM:")
        console.info(pred_mask_array);
        window.pma = pred_mask_array

        
    }


    const Tab1 = <div>
        <div>
            <input type="file" onChange={onFileChange} id='imageUpload' ref={hiddenFileInput} style={{display: "none"}} accept="image/png, image/jpeg" /> 
            <button className='btn btn-primary' onClick={useBatchUpload}><Save /> Save</button>
            <button className='btn btn-primary mx-1' onClick={handleFileClick}><FileImage /> Upload image</button>
            <Link to={`/batch2/${id}`} className='btn btn-secondary'><Wrench /> Switch to dev</Link>
        </div>

        {status !== "idle" && <div>Status: {status}</div>}


        {(selectedFile !== undefined) && (status === 'uploading') && progressBar()}



        <div className='row'>
            <Form.Select aria-label="Project"  value={selectedProjectId} onChange={selectProject}>
                <option value=''>Select a project:</option>
                {projectOptions}
            </Form.Select>
        </div>

        {fileData()}
        
        <hr />
        
        
        <button className='btn btn-primary' onClick={addColumn}>Add column</button>
        
        {TubeColumns}



        <div className='row'>
            <label htmlFor='protocol'>Protocol:</label>
            <select onChange={selectProtocol} id='protocol' value={selectedProtocolId}>
                <option value='0'>Select an option:</option>
                {protocolOptions}
            </select>
        </div>

        <div className='info'>
            <ProtocolSteps />
        </div>

        <div className='well py-4 small'>
            <BatchTimeStamps  />
        </div>
    </div>;


    // add Promise around img.onload:
    const getImagePromise = (src) => {
        return new Promise((resolve, reject) => {
          let img = new Image()
          img.onload = () => resolve(img)
          img.onerror = reject
          img.crossOrigin = "anonymous";
          img.src = src
        })
    }

    const loadOriginalImage = async () => {
        console.log("loadOriginalImage");
        console.info(selectedFile);

        const img = selectedFile;


        if (selectedFile.id) {
            // setPreview(API_URL + "images/preview/" + selectedFile.userId + "/" + selectedFile.id)

            const src = API_URL + "images/preview/" + img.userId + "/" + img.id + "/" + img.width + "/" + img.height;

            await getImagePromise(src)
                .then(im => {
                    console.log(im);
                    setOriginalImage(im);
                })

        } else {
            // new file, temporarily stored locally.
            console.log("setting original image locally.")
            const objectUrl = URL.createObjectURL(selectedFile)
            setOriginalImage(objectUrl);



        }



    }



    const setNumWindowsFromSelect = (e) => {
        setNumWindows(e.target.value);
    }


    const ModelResults = () => {

        const canvas_height = 1000;
        const canvas_width = 730;

        
      
        // calc some padding?
        const natural_wh_ratio = originalImage.naturalWidth / originalImage.naturalHeight;
        const canvas_wh_ratio = canvas_width / canvas_height;

        var new_height;
        var new_width;

        if (natural_wh_ratio <= canvas_wh_ratio) {
            console.log("pad x");
            new_height = canvas_height;
            new_width = canvas_height * natural_wh_ratio;
            console.log(`newh:${new_height} neww:${new_width}`)
        } else {
            // pad h
            console.log("pad h")
            console.log(`cw:${canvas_width} whr${natural_wh_ratio}`)
            new_width = canvas_width;
            new_height = canvas_width / natural_wh_ratio;
            console.log(`newh:${new_height} neww:${new_width}`)
        }



        return <div>
            <div id='canvasHolder' style={{position: "relative", width:`${canvas_width}px`, height:`${canvas_height}px` }}>
                <Canvas img={originalImage} style={{position: "absolute"}} width={canvas_width} height={canvas_height} id='myCanvas'/>
                <canvas id='myCanvasOverlay' ref={canvasOverlay} style={{position: "absolute" }} width={canvas_width} height={canvas_height}></canvas>
            </div>
        </div>
    }

    const Tab2ImageStuff = () => {
        console.log("Tab2ImageStuff")
        var models = visionmodels.filter(obj => {
            return obj.projectId == selectedProjectId
        })

        if (preview !== undefined && originalImage === undefined) {
            console.log("Loading img for T2IS");
            loadOriginalImage();
            console.log('not ready')
            return;
        }

        if (selectedFile === undefined) {
            return <div>not ready - no file</div>
        }
        if (selectedFile.width === undefined) {
            console.info(selectedFile);
            return <div>no width data available for file - try refreshing</div>
        }

        const modelOptions = models.map((obj, i) => {
            return(<option key={i} value={obj.id}>{obj.modelname}</option>);
        })

        const ModelSelector = <Form.Select aria-label={`${models.length} models:`} value={currentModel && currentModel.id ? currentModel.id : ""} onChange={loadModel}> 
            <option value="">Select a model...</option>
            {modelOptions}
        </Form.Select>

        const maxNumWindows = Math.ceil(Math.max(selectedFile.width, selectedFile.height)/244)


        const windowOptions = [...Array(maxNumWindows).keys()].map((obj,i) => {
            return <option key={i} value={obj+1}>{obj+1}</option>;
        })
        
        const NumWindowsSelector = <Form.Select aria-label={`How many windows?:`} value={numWindows} onChange={setNumWindowsFromSelect}> 
            <option value="0">Select how many columns...</option>
            {windowOptions}
        </Form.Select>

        return <div>
            <div className='row pb-3'>
                <div className='col-6'>
                    {ModelSelector}
                </div>
                <div className='col-4'>
                    {NumWindowsSelector}
                </div>
                <div className='col-2'>
                    {net !== undefined && numWindows>0 && <button className='btn btn-primary' onClick={getPrediction}>Predict</button>}
                </div>
            </div>
            {net === undefined ? <div>No model</div> : <ModelResults />}
        </div>
    }


    const Tab2 = <div>
        {
            preview === undefined
            ? <p>No preview</p>
            : <Tab2ImageStuff />
        }
    </div>


    function renderBatch() {
        if (! currentUser) {
            return(
                <div>Not logged in</div>
            )
        }




        return(
            <div>
                {header}
                {/* <hr /> */}

                <Tabs
                    defaultActiveKey='tab1'
                    id='mytabs'
                    className='mr-2 ml-2 mb-3'
                >
                    <Tab eventKey='tab1' title='File'>
                    
                        <h1>{id==='new' ? "New batch" : "Batch " + id}</h1>
                        {Tab1 }
                    </Tab>
                    {   id !== "new" && 
                        <Tab eventKey='tab2' title='Image analysis' disabled={preview === undefined}>
                            {Tab2}
                        </Tab>
                    }

                </Tabs>

                

                
            </div>
        )
    }

    return renderBatch();
}