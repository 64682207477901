import { useEffect, useMemo, useRef, useState } from "react";
// import radians_to_degrees from "./radiansToDegrees";
import Chart from "react-apexcharts";

export default function CanvasAnnotation(props) {
    console.log('CanvasAnnotation', props)
  
    const canvasRef = useRef(null)
    const [rgbCounts, setRGBCounts] = useState(null)
    
    if(props.img !== undefined) {
        // console.log("canvas has img");
        // console.log(props.img);
    }

    const rgbInfo = useMemo(() => {
        if (rgbCounts == null) {
            return null
        }

        console.log("RGB INFO", rgbCounts)

        window.rgb = rgbCounts

        const maxR = Math.max(...rgbCounts.r)
        const maxG = Math.max(...rgbCounts.g)
        const maxB = Math.max(...rgbCounts.b)

        console.log()


        const options = {
            chart: {
                type: 'line',
                stacked: false,
            },
            colors: ["#FF0000","#00FF00","#0000FF"],
            xaxis: {
                labels: {
                    show: false
                }
            }
        }

        const series = [
            {
                name: 'Red',
                data: rgbCounts.r
            },
            {
                name: "Green",
                data: rgbCounts.g
            },
            {
                name: "Blue",
                data: rgbCounts.b
            }
        ]

        return <>
            <Chart 
              options={options}
              series={series}
              type="line"
              width="100%"
              height= "300px"
              
              />
        </>

    }, [rgbCounts])

    const ColourAnalysis = useMemo(() => {

        const r = Array(255).fill(0);
        const g = Array(255).fill(0);
        const b = Array(255).fill(0);

        // db example:
        // {\"box\":[0.30357142857142855,0.8794642857142857,0.44642857142857145,0.9330357142857143],
        // \"dimensions\":{\"height\":112,\"width\":265,\"top\":1106.71,\"left\":92.571},
        // \"result\":\"negative\",
        // \"status\":\"modified\"}

        const w = Math.round(props.img.naturalWidth * (props.tube.box[2] - props.tube.box[0]))
        const h = Math.round(props.img.naturalHeight * (props.tube.box[3] - props.tube.box[1]))

        // new canvas
        const offscreen = new OffscreenCanvas(w, h)
        const ctx = offscreen.getContext('2d', {willReadFrequently:true})

        const dx = Math.round(props.img.naturalWidth * props.tube.box[0])
        const dy = Math.round(props.img.naturalHeight * props.tube.box[1])
        const dWidth = Math.round(props.img.naturalWidth * (props.tube.box[2]-props.tube.box[0]))
        const dHeight = Math.round(props.img.naturalWidth * (props.tube.box[3]-props.tube.box[1]))


        ctx.fillStyle = 'rgb(255,255,255)';
        ctx.fillRect(0,0,w,h);

        // ctx.drawImage(props.img, dx, dy, w, h, 0, 0, dWidth, dHeight);

        // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
        // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
        ctx.drawImage(props.img, dx, dy, w, h, 0, 0, w, h);

        console.log(`drawing image w:${w} h${h} dx${dx} dy${dy} dw${dWidth} djh${dHeight}`)

        // for (var x = 0; x < w; x++) {
        //     for (var y = 0; y < h; y++) {
        //         const pixel = ctx.getImageData(x,y,1,1)
        //         r[pixel.data[0]] += 1
        //         g[pixel.data[1]] += 1
        //         b[pixel.data[2]] += 1
        //     }
        // }

        const rgbas = ctx.getImageData(0,0,w,h)
        console.log('rgbas',rgbas)

        for (var x = 0; x < w; x++) {
            for (var y = 0; y < h; y++) {
                const start = (4 * x) + (4 * x * y)
                r[rgbas.data[start]] += 1
                g[rgbas.data[start+1]] += 1
                b[rgbas.data[start+2]] += 1

                // console.log(`rgba ${x},${y} ${start} = (${rgbas.data[start]},${rgbas.data[start+1]},${rgbas.data[start+2]})`)
            }
        }

        setRGBCounts({r:r, g:g, b:b})
    },[])

    
    const draw = (ctx, new_width, new_height) => {

        // draw image to canvas, from 0,0
        ctx.drawImage(props.img, 0, 0, new_width, new_height);

        if (props.tube === undefined) {
            return
        }    
        const x = new_width  * props.tube.box[0]
        const y = new_height * props.tube.box[1]
        const w = new_width  * (props.tube.box[2] - props.tube.box[0])
        const h = new_height * (props.tube.box[3] - props.tube.box[1])

        // draw semi-opaque boxes around the tube.
        ctx.fillStyle = 'rgba(255,255,255,0.6)';
        ctx.fillRect(0,0,new_width,y);
        ctx.fillRect(0,y,x,h);
        ctx.fillRect(x+w,y,new_width-x-w,h);
        ctx.fillRect(0,y+h,new_width,new_height-y-h);

        // draw outline rect around tube
        ctx.lineWidth = 3;
        ctx.strokeStyle = "black";

        ctx.strokeRect(x-ctx.lineWidth/2,y-ctx.lineWidth/2,w+ctx.lineWidth,h+ctx.lineWidth);

        // calc crop xy etc
        const cw = props.img.naturalWidth  * (props.tube.box[2] - props.tube.box[0])
        const ch = props.img.naturalHeight * (props.tube.box[3] - props.tube.box[1])
        const cx = props.img.naturalWidth  * props.tube.box[0] 
        const cy = props.img.naturalHeight * props.tube.box[1]
        const crop_wh_ratio = cw / ch

        // calc same xywh stats but with some padding.
        const padding = 0.1 // proportion of w+h to pad

        // these calcs are a bit more complicated, min/max is used to ensure the padding doesn't go <0 or >naturalWidth or naturalHeight
        const padded_x = Math.max(0, cx - (cw*padding))
        const padded_y = Math.max(0, cy - (ch*padding))

        // calculating width
        const padded_w = Math.min(props.img.naturalWidth,  cx + cw + (cw*padding)) - padded_x
        const padded_h = Math.min(props.img.naturalHeight, cy + ch + (ch*padding)) - padded_y

        const padded_wh_ratio = padded_w / padded_h


        // set up destination canvas area
        const dx = 120 // point to start drawing cropped version on canvas
        const dy = 0   //
        var dWidth = props.width - dx
        var dHeight = props.height

        var dest_wh_ratio = dWidth / dHeight
        var msg = "xx"
        var crop_scale = 1

        if (dest_wh_ratio < padded_wh_ratio) {
            // limited by destination height
            crop_scale = dWidth/padded_w
            const crop_height = padded_h * crop_scale
            dHeight = crop_height
            msg = `W:${Math.round(dWidth)} Recalc'd height: ${Math.round(dHeight)}`

        } else {
            // limited by destination width
            
            crop_scale = dHeight/padded_h
            const crop_width = padded_w * crop_scale
            dWidth = crop_width
            msg = `Recalc'd width: ${Math.round(dWidth)} H:${Math.round(dHeight)}`
        }

        ctx.drawImage(props.img, padded_x, padded_y, padded_w, padded_h, dx, dy, dWidth, dHeight);
        // ctx.font = "20px Arial";
        // ctx.fillStyle = "red";
        // ctx.fillText(msg, dx, 30)
        // ctx.fillText(`P: ${padded_x},${padded_y} -> ${padded_w},${padded_h}`, dx, 60)

        // // console.log('props.predictedtubes', props.predictedtubes)
        // const xOutputScale = new_width / props.predictedtubes.width;
        // const yOutputScale = new_height / props.predictedtubes.height;

        //   // console.log(`xOutputScale:${xOutputScale} yOutputScale:${yOutputScale}`)

        // //   const center = props.tube.ellipse.getCenter()
        // //   const axisLength = props.tube.ellipse.getAxisLength()


        // // //   const angle = radians_to_degrees(props.tube.ellipse.equation.angle)

        // //   // radius x+y?
        // //   const rx = Math.max(axisLength[0],axisLength[1])
        // //   const ry = Math.min(axisLength[0],axisLength[1])

        // //   // calc ellipse centre x+y
        // //   const ex = center[0] * xOutputScale
        // //   const ey = center[1] * yOutputScale

        // //   // console.log(`Ellipse from ${Math.round(ex)},${Math.round(ey)} 
        // //   // rx:${rx} ry:${ry} 
        // //   // L1:${axisLength[0]} L2:${axisLength[1]}
        // //   // angle:${angle}
        // //   // xos:${xOutputScale} yos:${yOutputScale}`)
          
        // //   ctx.lineWidth = 2;
        // //   ctx.strokeStyle = "chartreuse";

        // //   ctx.beginPath();
        // //   ctx.ellipse(
        // //       ex, 
        // //       ey, 
        // //       rx * xOutputScale, 
        // //       ry * yOutputScale, 
        // //       -props.tube.ellipse.equation.angle, 
        // //       10, 
        // //       350);
        // //   ctx.stroke();

        // //   // draw small dot at centre of ellipse
        // //   ctx.strokeStyle = "red";

        // //   ctx.beginPath();
        // //   ctx.ellipse(
        // //       center[0]* xOutputScale, 
        // //       center[1]* yOutputScale, 
        // //       2,
        // //       2, 
        // //       0, 
        // //       10, 
        // //       350);
        // //   ctx.stroke();

        // //   ctx.fillText(`E: ${Math.round(ex)},${Math.round(ey)} ${Math.round(angle*100)/100}deg`, dx, 120)
        // //   ctx.fillText(`E: ${Math.round(center[0])},${Math.round(center[1])} ${Math.round(angle*100)/100}deg`, dx, 140)

        // // finally, draw an overlay on top of the cut out.            
        // createImageBitmap(props.predictedtubes.predicted_mask).then((sprites) => {
        //     // console.log(`drawingbitmap 0,0 => ${new_width},${new_height}`, sprites)

        //     // draw overlay on thumbnail
        //     ctx.drawImage(sprites, 0, 0, Math.round(new_width), Math.round(new_height))

        //     // // draw overlay on big img
        //     const xScale = props.predictedtubes.width / props.img.naturalWidth
        //     const yScale = props.predictedtubes.height / props.img.naturalHeight

        //     // console.log(`xScale: ${xScale} nw: ${props.img.naturalWidth} pw:${props.predictedtubes.width}`)
        //     // console.log(`X:${props.predictedtubes.width * padded_x / props.img.naturalWidth}`)


        //     ctx.drawImage(sprites, 
        //     props.predictedtubes.width * padded_x / props.img.naturalWidth,   // crop x on prediction
        //     props.predictedtubes.height * padded_y / props.img.naturalHeight, // crop y on prediction
        //     props.predictedtubes.width * padded_w / props.img.naturalWidth, 
        //     props.predictedtubes.height * padded_h / props.img.naturalHeight,  
        //     dx,
        //     dy, 
        //     dWidth, 
        //     dHeight
        //     )
        // })
    }
    
    useEffect(() => {
      
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      
      // calc some padding?
      const natural_wh_ratio = props.img.naturalWidth / props.img.naturalHeight;
      const canvas_wh_ratio = props.width / props.height;

      var new_height = 1;
      var new_width = 1;

      console.log(`Canvas fn w${canvas.width} h${canvas.height}`)

      if (natural_wh_ratio <= canvas_wh_ratio) {
        // console.log("Cpad x");
        new_height = canvas.height;
        new_width = canvas.height * natural_wh_ratio;
        // console.log(`newh:${new_height} neww:${new_width}`)
      } else {
        // pad h
        // console.log("Cpad h")
        new_width = canvas.width;
        new_height = canvas.width / natural_wh_ratio;
        // console.log(`Cnewh:${new_height} neww:${new_width}`)
      }
      // console.log(`Cnewh:${new_height} neww:${new_width}`)
      // console.log(props.img.naturalWidth + "x" + props.img.naturalHeight)

      // context.drawImage(props.img, 0, 0, new_width, new_height);

      //Our draw come here
      draw(context, new_width, new_height)
    }, [draw])

    
    // if (! props.tube?.box) {
    //     console.warn("CanvasAnnotation - no box", props.tube)
    //     return null
    // }
    
    return <div>
            <div>
                <canvas ref={canvasRef} {...props}/>
            </div>      
            <div>
                {/* {ColourAnalysis} */}
                {rgbInfo}
            </div>      
        </div>
}