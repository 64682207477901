import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import authService from "../components/services/auth.service";
import { useWindowSize } from "@uidotdev/usehooks";
import { Col, Container, Row } from "react-bootstrap";

const team_videos = {
    'heidi': {
        name: "Heidi Baker",
        poster: '/app_videos/team/heidi_thumbnail.jpg',
        video: 'heidi_landscape_fwvga.mp4',
        text: <div>
            <p>Kia ora! My name is Heidi Baker and I work in ESR's forensic research and development team. My role in the CRISPR project is to ensure that our science is delivering solutions to the problems facing our communities so I look forward to hearing what those problems are. When I'm not working on the CRISPR project I'm in the garden playing with cats.</p>
        </div>
    },
    
    'richard': {
        name: "Richard Dean",
        poster: '/app_videos/team/richard_thumbnail.jpg',
        // video: 'richard_locost.mp4',
        video: 'richard_1_v_m.mp4',
        text: <div>
            <p>Kia ora! My name is Richard Dean. I'm a data scientist in ESR's data and analytics team. I live in Porirua, where the sun always shines! On this project, I'll be using artificial intelligence and computer vision techniques to help us build a rapid diagnostic app which will help our communities stay safe through rapid, low cost and accurate testing.</p>
        </div>
    }
}




export default function About() {
    const size = useWindowSize();
    
    const currentUser = authService.getCurrentUser();
    console.info("Current user:")
    console.info(currentUser)

    const AboutHeader = () => {
        return <div className='row'>
            <div className='col col-6'>
                <h1 className="glo display-1">
                    <Link to='/'>GLO</Link>
                </h1>
            </div>
            <div className='col col-6'>
                {
                currentUser
                ? <div className='signedInInfo py-2 text-info'>
                    <div>{currentUser.forename}</div>
                    <Link to='/'>Home</Link>
                    {/* {' | '}
                    <Link to='benchmarking'>Benchmarking</Link> */}
                    </div>
                : <div className='signedInInfo py-2 text-info'>
                    <div>Not signed in</div>
                    <Link to='benchmarking'>Benchmarking</Link>
                    </div>
                }
            </div>
        </div>
    }


    const AboutHome = () => {
        return <div>
            {/* <video 
                width={size.width} 
                height={size.height }
                // height="100%"
                // ref={videoRef}
                autoPlay muted loop disablePictureInPicture playsInline 
                style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: -1,


                }}>
                <source src={`/app_videos/about002.mp4`} type='video/mp4' />
                Your browser doesnt support this video.
            </video> */}

            <div
                className="container d-flex flex-column gloBody" 
                style={{backgroundColor:'#ffffff33'}}
            >

                <AboutHeader />
                <h1>Rapid diagnostics</h1>
                <h2>What is CRISPR?</h2>
                

                <video 
                // width={size.width} 
                // height={size.height} 
                // ref={videoRef}
                poster={`/app_videos/what_is_crispr.jpeg`}
                autoPlay muted loop disablePictureInPicture playsInline controls
                style={{zIndex: 400}}>
                        <source src={`/app_videos/about_what_is_crispr_001_lo.mp4`} type='video/mp4' />
                        Your browser doesnt support this video.
                </video>

                <h2 style={{marginTop:'1em' }}>Meet the team</h2>

                <Container fluid="md" style={{
                    textAlign: 'center',
                    // color: '#ffffff',
                }}>
                    <Row>
                        <Col>
                        
                            <div style={{
                                backgroundImage: `url(/team_photos/rachel.jpg)`,
                                backgroundSize: 'cover',
                                height: '190px',
                                width: '190px',
                                borderRadius: '50%',
                                margin: '10px',
                                boxShadow: '3px 3px 5px #00000066'
                            }}></div>
                            <div style={{width: '190px'}}>Rachel Fleming</div>
                        </Col>
                        <Col>
                            <div style={{
                                backgroundImage: `url(/team_photos/craig.jpg)`,
                                backgroundSize: 'cover',
                                height: '190px',
                                width: '190px',
                                borderRadius: '50%',
                                margin: '10px',
                                textAlign: 'center',
                                boxShadow: '3px 3px 5px #00000066'
                            }}></div>
                            <div style={{width: '190px'}}>Craig Billington</div>
                        </Col>
                        <Col>
                        <Link to='heidi'>
                            <div style={{
                                    backgroundImage: `url(/team_photos/heidi.jpg)`,
                                    backgroundSize: 'cover',
                                    height: '190px',
                                    width: '190px',
                                    borderRadius: '50%',
                                    margin: '10px',
                                    // textAlign: 'center',
                                    boxShadow: '3px 3px 5px #00000066'
                                    // border: '5px solid #ffcc99cc'
                                }}></div>
                            <div style={{width: '190px'}}>Heidi Baker</div>    

                        </Link>                
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Link to='richard'>
                                <div style={{
                                    backgroundImage: `url(/team_photos/richard.jpg)`,
                                    backgroundSize: 'cover',
                                    height: '190px',
                                    width: '190px',
                                    borderRadius: '50%',
                                    margin: '10px',
                                    textAlign: 'center',
                                    boxShadow: '3px 3px 5px #00000066'
                                }}></div>
                                <div style={{width: '190px'}}>Richard Dean</div>
                            </Link>
                        </Col>
                        <Col>
                            <div style={{
                                backgroundImage: `url(/team_photos/courtney.jpg)`,
                                backgroundSize: 'cover',
                                height: '190px',
                                width: '190px',
                                borderRadius: '50%',
                                margin: '10px',
                                // width: '200px',
                                textAlign: 'center',
                                boxShadow: '3px 3px 5px #00000066'
                            }}></div>
                            <div style={{width: '190px'}}>Courtney Lynch</div>
                        </Col>
                        <Col>
                            <div style={{
                                backgroundImage: `url(/team_photos/ras.jpg)`,
                                backgroundSize: 'cover',
                                height: '190px',
                                width: '190px',
                                borderRadius: '50%',
                                margin: '10px',
                                textAlign: 'center',
                                boxShadow: '3px 3px 5px #00000066'
                            }}></div>
                            <div style={{width: '190px'}}>Erasmus Smit</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{
                                backgroundImage: `url(/team_photos/lauren.jpg)`,
                                backgroundSize: 'cover',
                                height: '190px',
                                width: '190px',
                                borderRadius: '50%',
                                margin: '10px',
                                // width: '200px',
                                textAlign: 'center',
                                boxShadow: '3px 3px 5px #00000066'
                            }}></div>
                            <div style={{width: '190px'}}>Lauren Baker</div>
                        </Col>
                        <Col>
                        
                            <div style={{
                                backgroundImage: `url(/team_photos/anower.jpg)`,
                                backgroundSize: 'cover',
                                height: '190px',
                                width: '190px',
                                borderRadius: '50%',
                                margin: '10px',
                                textAlign: 'center',
                                boxShadow: '3px 3px 5px #00000066'
                                }}></div>
                            <div style={{width: '190px'}}>Anower Jabed</div>
                        </Col>
                        <Col>
                            <div style={{
                                backgroundImage: `url(/team_photos/jemma.jpg)`,
                                backgroundSize: 'cover',
                                height: '190px',
                                width: '190px',
                                borderRadius: '50%',
                                margin: '10px',
                                boxShadow: '3px 3px 5px #00000066'
                            }}></div>
                            <div style={{width: '190px'}}>Jemma Harbrow</div>

                    </Col>
                    </Row>
                    <Row>
                    <Col>
                            <div style={{
                                backgroundImage: `url(/team_photos/olivia.jpg)`,
                                backgroundSize: 'cover',
                                height: '190px',
                                width: '190px',
                                borderRadius: '50%',
                                margin: '10px',
                                boxShadow: '3px 3px 5px #00000066'
                            }}></div>
                            <div style={{width: '190px'}}>Olivia Martin</div>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                    </Row>
                </Container>
            </div>

        </div>
    }

    const AboutVideo = (video_info) => {

        const video_element = (
            video_info['video'] == undefined
            ? null
            : <video 
                width={`100%`}
                poster={(video_info['poster'] === undefined ? `/app_videos/what_is_crispr.jpeg` : `${video_info['poster']}`)}
                autoPlay
                loop 
                disablePictureInPicture 
                playsInline 
                controls
                >
                    <source src={`/app_videos/team/${video_info['video']}`} type='video/mp4' />
                    Your browser doesnt support this video.
                </video>
        )

        return <div>
                <AboutHeader />      
                <div style={{marginBottom:'1em'}}>
                    <Link to='/about'>About</Link> &gt; {video_info['name']}
                </div>           
                {/* <h1>{video_info['name']}</h1> */}
                {video_element}                        
                {video_info['text']}
            </div>
    }





    

    const { id } = useParams();

    const element = useMemo(() => {
        if (id === undefined) {
            return <AboutHome />;
        }

        const video_info = team_videos[id]

        console.info('video_info;m',video_info)

        if (video_info !== undefined) {

            return AboutVideo(video_info)
        }
        return <h1>About ;-) {id}</h1>

    }, [id])

    return element
}