export default function Legal() {
  return (
    <div>
      <h1>Legal stuff</h1>
      <p>
        Nostrud velit labore anim id sit in est aliquip elit ipsum excepteur.
        Adipisicing non consectetur labore elit ad ullamco qui aute mollit non.
        Dolore dolore et qui tempor. Magna cillum laboris nisi consectetur minim
        Lorem exercitation fugiat commodo quis aliqua esse. Reprehenderit minim
        aliquip anim nostrud exercitation ullamco minim mollit qui. Anim sint ad
        commodo cupidatat. Veniam labore in pariatur commodo adipisicing est
        cupidatat ut in.
      </p>
      <p>
        Incididunt pariatur ut est deserunt quis consequat. Ad nisi adipisicing
        et excepteur. Voluptate officia duis aute in excepteur ut laboris in
        elit enim deserunt incididunt. Id consequat duis qui esse exercitation
        eiusmod consequat id cupidatat.
      </p>
    </div>
  );
}
