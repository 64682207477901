import { Component, useMemo } from "react";
import AuthService from "./services/auth.service";
import { Link, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import { BagDashFill, DashSquare, FileArrowUp, SpeakerFill, Trash } from "react-bootstrap-icons";
import axios from "axios";
import { API_URL } from "../config/api";
import { useEffect } from "react";
import { FileImage, Save } from "react-bootstrap-icons";
import {filesize} from "filesize";
import authHeader from "./services/auth-header"
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as tf from "@tensorflow/tfjs"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';







export default function ModelAdmin() {

    const [currentUser, setCurrentUser] = useState();
    const hiddenFileInput = useRef(null);
    const modelNameRef = useRef(null);
    const modelDescriptionRef = useRef(null);
    const modelNormaliseRef = useRef(null);
    const modelPublicBenchmarkOrderRef = useRef(null);
    const modelProjectRef = useRef(null);
    const [models, setModels] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [progress, setProgress] = useState(undefined);
    const [projects, setProjects] = useState([]);
    const [selectedModel, setSelectedModel] = useState(undefined);

    
    const selectModelEvent = (e) => {
        console.log("selectModelEvent: " + e.target.value)
        setSelectedFile(undefined);

        if (e.target.value === '') {
            setSelectedModel(undefined);
            return;
        }

        if (e.target.value === 'new') {
            setSelectedModel('new');
            return;
        }

        const model = models.find(
            model => {
                return model.id == e.target.value
            })
        setSelectedModel(model);
        console.log(model);
    }

    useEffect(() => {
        setCurrentUser(AuthService.getCurrentUser());

        console.log("loading models");

        axios.get(
            `${API_URL}api/visionmodels`,
            // extra stuff
            {
                headers: Object.assign(authHeader())
            }
          ).then(msg => {
            setModels(msg.data)
          });

        axios.get(
            `${API_URL}api/projects`,
            // extra stuff
            {
                headers: Object.assign(authHeader())
            }
          ).then(msg => {
            setProjects(msg.data.projects)
          });







    },[])


    // provide a <Form.Select> element to choose a model.
    const getModelSelector = () => {
        console.log("ModelSelector called.");

        const value = (selectedModel === undefined ? 
            '':
            selectedModel.id);
  
        const model_options = models.map((model, i) => {
            return <option key={i} value={model.id}>{model.modelname}</option>
        })
        
        return <Form.Select key={`modelSelect_{value}`} aria-label="Model" onChange={selectModelEvent} defaultValue={value}>
                <option value=''>Select a model</option>
                {model_options}
                <option value='new'>New</option>
            </Form.Select>;
    };


    // On file select (from the pop up)
    function onFileChange(event) {
        const newFile = event.target.files[0];

        console.log(newFile);

        // check selected file is an image..
        if (newFile.name.substr(-4) != ".zip") {
            alert("selected file is not a .zip");
            setSelectedFile(undefined);
            return;
        }

        // set a friendly string for last_modified, this is only used to display
        if (newFile.lastModified !== undefined) {
            console.info(newFile)
            console.info(new Date(newFile.lastModified))
            newFile.last_modified_str = new Date(newFile.lastModified).toString()
        } else if (newFile.lastModifiedDate !== undefined) {
            newFile.last_modified_str = newFile.lastModifiedDate;
        } else {
            newFile.last_modified_str = new Date(Date.now());
        }

        // Update the state
        setSelectedFile(newFile);

        const newModel = {
            createdAt: newFile.lastModifiedDate,
            description: 'Model uploaded by ' + currentUser.forename + " " + currentUser.surname,
            filesize_bytes: newFile.size,
            id: 'new',
            modelname: 'New model ' + new Date(Date.now()),
            path: '',
            projectId: 1,
            updatedAt: Date.now()
        }

        console.info("setting seleted model")
        console.log(newModel);

        setSelectedModel(newModel);
    };
     
    function handleFileClick(event) {
        hiddenFileInput.current.click();
    }

    const saveModel = (event) => {
        console.log("SaveModel:")

        console.log(selectedModel);
        console.log(selectedFile);

        const data = {
            model: {
                selectedModel
            }
        }

        if (selectedModel.id === 'new' && selectedFile !== undefined) {
            data.file = selectedFile
        }



        const formData = new FormData();
        // formData.append('file', this.file);

        const model_to_save = Object.assign(selectedModel);

        model_to_save.modelname = modelNameRef.current.value;
        model_to_save.description = modelDescriptionRef.current.value;
        model_to_save.projectId = modelProjectRef.current.value;
        model_to_save.normalise = modelNormaliseRef.current.checked;
        model_to_save.public_benchmarking_order = modelPublicBenchmarkOrderRef.current.value;

        formData.append('model',JSON.stringify(model_to_save));


        if (selectedFile !== undefined) {
            formData.append('fileMetadata', JSON.stringify(selectedFile, ['name','lastModified','lastModifiedDate','size']));
            formData.append('file', selectedFile);
        }

        axios.post(
            // method: 'post',
            API_URL + "api/visionmodels",
            formData,
            { 
                headers: Object.assign(authHeader()),
                onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

                    if (percentCompleted !== progress) {
                        setProgress(percentCompleted);
                        console.log(percentCompleted + '%');
                    }
                  }
            }
        )
        .then(
                function (response) {
                    //handle success
                    console.log(response);
                    setSelectedFile(undefined);
                    setSelectedModel(undefined);
                    setProgress(undefined);
                    
                    
                    // refresh model list.
                    axios.get(
                        `${API_URL}api/visionmodels`,
                        {
                            headers: Object.assign(authHeader())
                        }
                      ).then(msg => {
                        setModels(msg.data)
                      });


                }
        )
        .catch(function (response) {
            //handle error
            console.warn(response);
        });

    }

    const NewModelUI = () => {
        return <div className='mt-3'>
            <h2>Upload new model</h2>
            <input type="file" onChange={onFileChange} id='imageUpload' ref={hiddenFileInput} accept=".zip" style={{display: "none"}}/> 
            <button className='btn btn-primary' onClick={handleFileClick}><FileArrowUp /> Upload tfjs model .zip</button>
        </div>
    }

    const getModelUI = () => {
        console.log("getModelUI called")

        if (selectedModel === undefined) {
            return <div>Please select a model from the options above.</div>
        }

        if (selectedModel == 'new') {
            return NewModelUI()
        }

        console.info(selectedModel);


        

        return <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>ID</Form.Label>
            <Col sm={10}>{selectedModel.id}</Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-1'>
            <Form.Label column sm={2}>Name</Form.Label>
            <Col sm={10}>
                <Form.Control key={`m${selectedModel.id}_modelname`}  type='text' placeholder='Name' defaultValue={selectedModel.modelname} ref={modelNameRef} />
            </Col>
          </Form.Group>
          

          <Form.Group as={Row} className='mb-1'>
            <Form.Label column sm={2}>Project</Form.Label>
            <Col sm={10}>
                {/* <Form.Control type='text' placeholder='Name' defaultValue={selectedModel.modelname} ref={modelNameRef} /> */}
                {ProjectSelector}
            </Col>
          </Form.Group>


          <Form.Group as={Row} className='mb-1'>
            <Form.Label column sm={2}>Description</Form.Label>
            <Col sm={10}>
                <Form.Control key={`m${selectedModel.id}_description`} as='textarea' rows={3} placeholder='Description' defaultValue={selectedModel.description} ref={modelDescriptionRef} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-1'>
                <Form.Label column sm={2}>Public display order</Form.Label>
                <Col sm={10}>
                    <Form.Control type='public_benchmarking_order' key={`m${selectedModel.id}_public_benchmarking_order`} ref={modelPublicBenchmarkOrderRef} placeholder='' value={selectedModel.public_benchmarking_order} />
                </Col>
            </Form.Group>
          
          {selectedModel.path != "" && <Form.Group as={Row}  className='mb-1'>
            <Form.Label column sm={2}>Path</Form.Label>
            <Col sm={10}>
                <Form.Control type='text' placeholder='Path' value={selectedModel.path} disabled />
            </Col>
          </Form.Group>}

          <Form.Group as={Row} className='mb-1'>
            <Form.Label column sm={2}>Filesize</Form.Label>
            <Col sm={10}>
                <Form.Control type='text' placeholder='Filesize' value={selectedModel.filesize_bytes} disabled />
            </Col>
          </Form.Group>

          {
            selectedModel.createdAt !== null
            &&
            <Form.Group as={Row} className='mb-1'>
                <Form.Label column sm={2}>Created at</Form.Label>
                <Col sm={10}>
                    <Form.Control type='text' placeholder='Created at' value={selectedModel.createdAt} disabled />
                </Col>
            </Form.Group>
          }
          
          
          <Form.Group as={Row} className='mb-1'>
            <Form.Label column sm={2}>Preprocessing</Form.Label>
            <Col sm={10} className='checkbox pt-2'>
                <Form.Check type='checkbox' key={`m${selectedModel.id}_normalise`}  ref={modelNormaliseRef} label='Convert rgb values from 0..255 to 0..1' id='modelNormaliseId' value='255' defaultChecked={selectedModel.normalise} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-1'>
            <Col sm={{ span: 10, offset: 2}}>

                <button className='btn btn-primary' onClick={saveModel} type='button'><FileArrowUp /> Save</button>
            </Col>
          </Form.Group>
        </Form>        
    }

    const ModelSelector = useMemo(() => getModelSelector(), [models, selectedModel])

    const ProjectSelector = useMemo(() => {

        if (selectedModel === undefined || selectedModel.id === undefined) {
            return
        }

        const project_options = projects.map((project, i) => {
            return <option key={i} value={project.id}>{project.title}</option>
        });


        
        return <Form.Select aria-label="Model" key={`m${selectedModel.id}_project`}  ref={modelProjectRef} defaultValue={(selectedModel !== undefined && selectedModel.projectId !== undefined ? selectedModel.projectId : '')} >
                <option value=''>Select a project</option>
                {project_options}
            </Form.Select>;
    }, [projects, selectedModel]);


    const ModelUI = useMemo(() => getModelUI(), [selectedModel])
    const ProgressUI = useMemo(() => {
        if (progress === undefined) {
            return null;
        }

        return <div className="progress">
            <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
                >
            {progress}%
            </div>
        </div>
    }, [progress])

    
    if (! currentUser) {
        return(
            <div>Not logged in</div>
        )
    }



    return <div>
        <h1><SpeakerFill /> Model admin</h1>
        <p>There are {`${models.length}`} models, {`${projects.length}`} projects.</p>
        {ProgressUI}
        {ModelSelector}
        {ModelUI}
    </div>


}