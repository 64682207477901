import "./qr_scanner.css"
import QrScanner from "qr-scanner";
import QrFrame from "../../assets/qr-frame.svg";
import { useRef, useState, useEffect, useMemo } from "react";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";

function QRScanner({props}) {
    const scanner = useRef();
    const videoEl = useRef(null);
    const qrBoxEl = useRef(null);
    const [qrOn, setQrOn] = useState(true);
    const qrDecodedTextRef = useRef(null);
    const currentUser = AuthService.getCurrentUser();
    
    // Result
    const [scannedResult, setScannedResult] = useState(null);
    const [scannedTextElement, setScannedTextElement] = useState(null);

    const ui_header = <div className='row'>
        <div className='col col-6'>
            <h1 className="glo display-1">GLO</h1>
        </div>
        <div className='col col-6'>
            <div className='signedInInfo py-2 text-info'>
                <div>{currentUser.forename}</div>
                <Link to='/dashboard'>Dashboard</Link>
                {' | '}
                <Link to='/benchmarking'>Benchmarking</Link>
                {' | '}
                <Link to='/'>Home</Link>
            </div>
        </div>
    </div>
    
    useMemo(() => {
        console.log("sr",scannedResult)
        if (! scannedResult) {
            console.warn("no scannedResult..")
            setScannedTextElement(null)
            return;
        }

        setScannedTextElement(
            <Container>
                <Row>
                    <Col class='qr-decoded-text'>
                        {scannedResult}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    
                    </Col>
                    <Col>
                        <Button>Continue</Button>                    
                    </Col>
                </Row>
            </Container>
            
        )
    }, [scannedResult])

    // Success
    const onScanSuccess = (result) => {
        // 🖨 Print the "result" to browser console.
        console.log(result);
        // ✅ Handle success.
        // 😎 You can do whatever you want with the scanned result.
        setScannedResult(result?.data);
        // setScannedResult(result);
    };

    // Fail
    const onScanFail = (err) => {
        // 🖨 Print the "err" to browser console.
        // console.log(err);
        // setScannedResult(null);
        // setScannedTextElement(null)
    };


    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
          // 👉 Instantiate the QR Scanner
          scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
            onDecodeError: onScanFail,
            // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
            preferredCamera: "environment",
            // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
            highlightScanRegion: true,
            // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
            highlightCodeOutline: true,
            // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
            overlay: qrBoxEl?.current || undefined,
          });
    
          // 🚀 Start QR Scanner
          scanner?.current
            ?.start()
            .then(() => setQrOn(true))
            .catch((err) => {
              if (err) setQrOn(false);
            });
        }
    
        // 🧹 Clean up on unmount.
        // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
        return () => {
          if (!videoEl?.current) {
            scanner?.current?.stop();
          }
        };
      }, []);

    // ❌ If "camera" is not allowed in browser permissions, show an alert.
    useEffect(() => {
        if (!qrOn)
        alert(
            "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
        );
    }, [qrOn]);
    
    return (
        <div>
            {ui_header}

            <div className="qr-reader">
                {/* QR */}
                <video ref={videoEl}></video>
                <div ref={qrBoxEl} className="qr-box">
                    <img
                    src={QrFrame}
                    alt="Qr Frame"
                    width={256}
                    height={256}
                    className="qr-frame"
                    />
                </div>
                {/*  */}
            </div>
            
            <div style={{
                // border:"5px solid blue",
                // backgroundColor:'hotpink',
                padding: '10px',
                height: '50px',
                textAlign: 'center'
            }}>
                {scannedTextElement}
            </div>
        </div>
        
      );
}

export default QRScanner;