import { useEffect, useMemo, useRef } from "react"

import { Link, useParams } from "react-router-dom";
import authService from "../services/auth.service";
import './annotate.css'
import { filesize } from "filesize";
import { Alert, Button, CloseButton, Col, Row } from "react-bootstrap";
import { Dash, Plus, Save, Subtract } from "react-bootstrap-icons";
import { useState } from "react";


import axios from "axios";
import { API_URL } from "../../config/api";
import authHeader from "../services/auth-header";


export default function GloAnnotate({annotation}) {
    console.log(`ga`,annotation)
    window.annotation = annotation
    const currentUser = authService.getCurrentUser();
    const edit_tube_controls = useRef();

    const [tubes, setTubes] = useState([]);
    const [currentTube, setCurrentTube] = useState(null);
    const [message, setMessage] = useState(null)

    useMemo(()=> {
        console.log('init - setting tubes', annotation.tubes)
        setTubes(annotation.tubes)
    },[annotation])

    const AnnotateHeader = () => {
        return <div className='row'>
            <div className='col col-6'>
                <h1 className="glo display-1">
                    <Link to='/'>GLO</Link>
                </h1>
            </div>
            <div className='col col-6'>
                {
                currentUser
                ? <div className='signedInInfo py-2 text-info'>
                    <div>{currentUser.forename}</div>
                    <Link to='/'>Home</Link>
                    {/* {' | '}
                    <Link to='benchmarking'>Benchmarking</Link> */}
                    </div>
                : <div className='signedInInfo py-2 text-info'>
                    <div>Not signed in</div>
                    <Link to='benchmarking'>Benchmarking</Link>
                    </div>
                }
            </div>
        </div>
    }

    const canvasRef = useRef();

    const renderCanvas = () => {
        console.log('render canvas')
    }

    const dragTube = (e_mousemove, e_mousedown, tube) => {
        const move_x = e_mousemove.clientX - e_mousedown.clientX
        const move_y = e_mousemove.clientY - e_mousedown.clientY        

        e_mousemove.target.parentElement.style.top = (tube.dimensions.top + (move_y)) +'px'
        e_mousemove.target.parentElement.style.left = (tube.dimensions.left + (move_x)) +'px'

        edit_tube_controls.current.style.top= `${(tube.dimensions.top + (move_y)) + tube.dimensions.height + 10}px`
        edit_tube_controls.current.style.left = `${(tube.dimensions.left + (move_x))}px`
    }
    
    const dragTubeHeight = (e_mousemove, e_mousedown, tube) => {
        const y_adjustment = e_mousemove.clientY - e_mousedown.clientY        
        e_mousemove.target.parentElement.style.height = (tube.dimensions.height + y_adjustment) +'px'
        edit_tube_controls.current.style.top= `${parseFloat(e_mousemove.target.parentElement.style.top.replace('px','')) + tube.dimensions.height + y_adjustment + 10}px`
    }

    const dragTubeWidth = (e_mousemove, e_mousedown, tube) => {
        const x_adjustment = e_mousemove.clientX - e_mousedown.clientX        
        e_mousemove.target.parentElement.style.width = (tube.dimensions.width + x_adjustment) +'px'
    }

    const dragTubeWithTouch = (e_touchmove, e_touchdown, tube) => {
        console.log(`dragTubeWithTouch`, e_touchmove)
        const move_x = e_touchmove.touches[0].clientX - e_touchdown.touches[0].clientX
        const move_y = e_touchmove.touches[0].clientY - e_touchdown.touches[0].clientY        

        e_touchmove.target.parentElement.style.top = (tube.dimensions.top + (move_y)) +'px'
        e_touchmove.target.parentElement.style.left = (tube.dimensions.left + (move_x)) +'px'

        edit_tube_controls.current.style.top= `${(tube.dimensions.top + (move_y)) + tube.dimensions.height + 10}px`
        edit_tube_controls.current.style.left = `${(tube.dimensions.left + (move_x))}px`

        e_touchmove.preventDefault()
    }
    
    const dragTubeHeightWithTouch = (e_touchmove, e_touchdown, tube) => {
        const y_adjustment = e_touchmove.touches[0].clientY - e_touchdown.touches[0].clientY        
        e_touchmove.target.parentElement.style.height = (tube.dimensions.height + y_adjustment) +'px'
        edit_tube_controls.current.style.top= `${parseFloat(e_touchmove.target.parentElement.style.top.replace('px','')) + tube.dimensions.height + y_adjustment + 10}px`
        e_touchmove.preventDefault()
    }

    
    const dragTubeWidthWithTouch = (e_touchmove, e_touchdown, tube) => {
        const x_adjustment = e_touchmove.touches[0].clientX - e_touchdown.touches[0].clientX        
        e_touchmove.target.parentElement.style.width = (tube.dimensions.width + x_adjustment) +'px'        
        e_touchmove.preventDefault()
    }

    const updateTubeDimensions = (tube, i, element) => {
        // console.info(`update tube dimensions for [${i}]`, tube)
        // console.info(`top: ${element.style.top} height:${element.style.height}`)
        const height = parseFloat(element.clientHeight)
        const width = parseFloat(element.clientWidth)
        const top = parseFloat(element.style.top)
        const left = parseFloat(element.style.left)
        
        console.info(`position: ${left},${top} w:${width} h:${height}`)

        const newTubes = tubes

        newTubes[i].dimensions = {
            height:height,
            width: width,
            top:   top, 
            left:  left
        }

        newTubes[i].status = 'modified'

        console.log('setting tubes', newTubes)
        setTubes(newTubes)
    }

    const saveAnnotations = async () => {
        console.log('saveAnnotations')
        console.log('tubes', tubes)
        console.log('annotation',annotation)

        const tubeData = tubes.filter((tube, i) => (tube !== null)).map((tube, i) => {
            return {
                box: tube.box,
                dimensions: tube.dimensions,
                result: tube.result,
                status: tube.status,
                // filetype: "jpg",
                // tube:tube
            }

        })

        const dataToUpload = {
            number_of_tubes: tubeData.length,
            tubes: tubeData,
            source_type: annotation.source.source,
            dimensions: {
                width:  annotation.image.width,
                height:  annotation.image.height,
                orientation: 999,
                type: "jpg",
                last_modified: new Date().toISOString()
            },
            imageId: 'new',
            visionmodelId: annotation.model.id,
            userId: currentUser?.id,
            projectId: 1,
        }

        if (annotation.source.source === 'video') {
            dataToUpload['video'] = annotation.source.video
            dataToUpload['video']['timestamp']= annotation.source.timestamp
            dataToUpload['video']['duration'] = annotation.source.duration
            dataToUpload['imageFilename'] = `video_${dataToUpload.video.filename}_at_${dataToUpload.video.timestamp}.jpg`
        } else {
            dataToUpload['imageFilename'] = `${annotation.source.source}_${new Date().toISOString()}.jpg`   
        }

        console.log(`data to upload`, dataToUpload)

        // const formData = new FormData();

        // formData.append('number_of_tubes',tubeData.length)
        // formData.append('tubes',tubeData)
        // formData.append('source_type',annotation.source.source)
        // formData.append('source',{
        //     timestamp: annotation.source.timestamp,
        //     duration: annotation.source.duration,
        //     video: annotation.source.video
        // })
        // formData.append('imageId','new')
        // formData.append('visionmodelId',annotation.model.id)

        var canvas_temp = document.createElement("canvas");
        canvas_temp.width = annotation.image.width
        canvas_temp.height = annotation.image.height
        console.log(`canvas temp w:${canvas_temp.width} height:${canvas_temp.height}`)
        const ctx = canvas_temp.getContext('2d');
        ctx.drawImage(annotation.image, 0, 0, annotation.image.width, annotation.image.height)
        console.log(`canvas temp2 w:${canvas_temp.width} height:${canvas_temp.height}`)

        // Get the data-URL formatted image
        // Firefox supports PNG and JPEG. You could check img.src to
        // guess the original format, but be aware the using "image/jpg"
        // will re-encode the image.
        var dataURL = canvas_temp.toDataURL("image/jpeg", 0.9);
        dataToUpload.image = dataURL

        axios.post(
                API_URL + "api/annotations",
                dataToUpload,
                { 
                    headers: Object.assign(authHeader()),
                }
            )
            .then(
                    function (response) {
                        //handle success
                        console.log(response);                
                        
                        if(response.status === 200) {

                            setMessage(<Alert variant='info'>
                                    <strong>Success</strong>
                                    <p>{response.status}: {response.data.message}</p>
                                    <Link to={`/annotations/${response.data.annotation.id}`}>Continue</Link>
                                </Alert>)
                        }
                    }
            )
            .catch(function (err) {
                //handle error
                console.warn(`error!`, err);
                setMessage(<Alert variant='warning'>
                    <strong>Warning</strong>
                    <p>{err.response.status}: {err.message}</p>
                    </Alert>)
            });

















    }

    const TubeOverlay = useMemo(() => {
        console.log("Refreshing tubeOverlay")

        return <div id='tube_overlay' style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0px',
            left:'0px',
        }}
            onMouseDown={(e_mousedown) => {
                const tubeOverlayDiv = document.getElementById("tube_overlay")               
                const y1 = e_mousedown.clientY -  tubeOverlayDiv.getBoundingClientRect().top
                const x1 = e_mousedown.clientX - tubeOverlayDiv.getBoundingClientRect().left

                var newTubeBox = document.getElementById("newTubeBox")

                if (newTubeBox === null) {
                    newTubeBox = document.createElement('div')
                    newTubeBox.id = "newTubeBox"
                } else {
                    // console.log(`ntb not new`, newTubeBox)
                }

                newTubeBox.style.cssText = `position: absolute; border: 5px solid hotpink; top:${y1}px; left:${x1}px; width:4px; height:4px`
                tubeOverlayDiv.appendChild(newTubeBox);

                e_mousedown.target.onmousemove = (e_mousemove) => {
                    const xOffset = e_mousemove.clientX - e_mousedown.clientX
                    const yOffset = e_mousemove.clientY - e_mousedown.clientY

                    const width = (xOffset < 0 ? xOffset*-1: xOffset)
                    const height= (yOffset < 0 ? yOffset*-1: yOffset)
                    const left = (xOffset < 0 ? x1-width: x1)
                    const top  = (yOffset < 0 ? y1-height: y1)
                    newTubeBox.style.cssText = `position: absolute; border: 5px solid blue; top:${top}px; left:${left}px; width:${width}px; height:${height}px`
                }
            }}
            onMouseUp={(e_mouseup) => {
                e_mouseup.target.onmousemove = null
                e_mouseup.stopPropagation()

                // add this tube to the list
                var newTubeBox = document.getElementById("newTubeBox")

                if (newTubeBox == null) {
                    console.warn("new tube box is null")
                    return;
                }

                const height= parseFloat(newTubeBox.style.height)
                const width = parseFloat(newTubeBox.style.width)
                const top   = parseFloat(newTubeBox.style.top)
                const left  = parseFloat(newTubeBox.style.left)

                newTubeBox.remove()
                
                if (width < 15 | height < 15) {
                    console.info(`width=${width} height=${height} - this is too small for a tube so will ignore`)
                    return;
                }

                const newTubes = tubes

                const newTube ={
                    // x: left,
                    // y: top,
                    dimensions: {
                        height: height,
                        width: width,
                        top: top, 
                        left: left
                    },
                    status: 'new',
                }

                newTubes.push(newTube)
                console.log('pushed new + setting tubes', newTubes)

                setTubes(newTubes)
            }}
            onClick={e => {
                console.warn('click')
                e.target.onmousemove = null
            }} 
        >
            {tubes.map((tube,i) => {
                // console.log(`tube ${i}`, tube)
                if (tube === null) {
                    return null
                }

                return <div 
                    id={`tube_outline_${i}`}
                    style={{ 
                        position: 'absolute', 
                        top:`${tube.dimensions.top}px`, 
                        left:`${tube.dimensions.left}px`, 
                        width:`${tube.dimensions.width}px`, 
                        height:`${tube.dimensions.height}px`}}
                    class={`tubeRect ${tube.result}`}
                    onMouseEnter={(e) => {
                        console.log(`entered tube`, tube)
                        edit_tube_controls.current.style={
                            position:'absolute',
                            top: `${tube.dimensions.top + tube.dimensions.height + 100}px`
                        }
                        edit_tube_controls.current.style.position='absolute'
                        edit_tube_controls.current.style.top= `${tube.dimensions.top + tube.dimensions.height + 10}px`
                        edit_tube_controls.current.style.left= `${tube.dimensions.left}px`
                        edit_tube_controls.current.style.display='block'
                        setCurrentTube({i:i, tube:tube})
                        window.etc = edit_tube_controls;
                    }}
                    >
                        <div 
                          class="tubeRectMain"
                          style={{ 
                            position: 'absolute', 
                            top:`0px`, 
                            left:`0px`, 
                            right:`15px`,
                            bottom:`15px`,
                            }}
                          onMouseDown={(e_mousedown) => {
                                console.log(`mousedown`)
                                e_mousedown.target.onmousemove=(e_mousemove) => {
                                    dragTube(e_mousemove, e_mousedown, tube)
                                }
                                e_mousedown.stopPropagation()
                            }}
                          onMouseUp={(e) => {
                                console.log(`mouseup`)
                                e.target.onmousemove=null
                                updateTubeDimensions(tube, i, e.target.parentElement)
                                e.stopPropagation()
                            }}
                          onMouseLeave={(e) => {
                                e.target.onmousemove=null
                                e.stopPropagation()
                            }}
                          onTouchStart={(e_touchstart) => {
                            console.log('touchstart', e_touchstart)
                            e_touchstart.target.ontouchmove = (e_touchmove) => {
                                console.log(`touchmove`)
                                dragTubeWithTouch(e_touchmove, e_touchstart, tube)
                            }
                          }}
                          onTouchEnd={(e) => {
                            console.log(`onTouchEnd`, e)
                            e.target.ontouchmove = null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                          }}
                        
                            >
                            {`${i}`}
                        </div>
                        <div 
                          class="tubeRectBottom"
                          style={{ /* bottom */
                            position: 'absolute',
                            bottom: '0px',
                            left:`0px`, 
                            right: `0px`,
                            height:`15px`,
                        }}
                        
                        
                        onMouseDown={(e_mousedown) => {
                            e_mousedown.target.onmousemove=(e_mousemove) => dragTubeHeight(e_mousemove, e_mousedown, tube)
                            e_mousedown.stopPropagation()
                        }}
                        onMouseUp={(e) => {
                            e.target.onmousemove=null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                            e.stopPropagation()
                        }}
                        onTouchStart={(e_touchstart) => {
                            e_touchstart.target.ontouchmove=(e_touchmove) => dragTubeHeightWithTouch(e_touchmove, e_touchstart, tube)
                        }}
                        onTouchEnd={(e) => {
                            e.target.ontouchmove=null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                        }}
                        onMouseLeave={(e) => {
                            e.target.onmousemove=null
                        }}
                        
                        ></div>
                        <div 
                          class='tubeRectRight'
                          style={{ /* right */
                            position: 'absolute',
                            top:`0px`,
                            right:'0px',
                            width:`15px`, 
                            bottom:`0px`,
                            // outline: '3px solid magenta'
                        }}
                        
                        onMouseDown={(e_mousedown) => {
                            e_mousedown.target.onmousemove=(e_mousemove) => dragTubeWidth(e_mousemove, e_mousedown, tube)
                            e_mousedown.stopPropagation()
                        }}
                        onMouseUp={(e) => {
                            e.target.onmousemove=null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                            e.stopPropagation()
                        }}
                        onMouseLeave={(e) => {
                            e.target.onmousemove=null
                            // updateTubeDimensions(tube, i, e.target.parentElement)
                        }}


                        
                        onTouchStart={(e_touchstart) => {
                            e_touchstart.target.ontouchmove=(e_touchmove) => dragTubeWidthWithTouch(e_touchmove, e_touchstart, tube)
                        }}
                        onTouchEnd={(e) => {
                            e.target.ontouchmove=null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                        }}
                        
                        ></div>
                    </div>
            })}

        </div>
    },[tubes])

    useEffect(() => {
        console.log(`annotate effect`, canvasRef)
        const ctx = canvasRef.current.getContext('2d');
        ctx.drawImage(annotation.image, 0, 0, annotation.image.width, annotation.image.height);        
    },[canvasRef])

    // const TubeOverlayElement = () => {
    //     console.log('TubeOverlayElement')
    //     return <div>
    //         {TubeOverlay}
    //     </div>

    // }

    return <div className='gloBody'>
            <AnnotateHeader />
            <h1>Edit Annotations</h1>
            
            <div style={{
                    width:`${annotation.image.width}px`, 
                    height:`${annotation.image.height}px`, 
                    position:'relative'}}
                className='prevent-select'>
                <canvas ref={canvasRef} onLoad={renderCanvas} width={annotation.image.width} height={annotation.image.height} />
                {/* <TubeOverlay /> */}
                {TubeOverlay}
                {/* <TubeOverlayElement /> */}
                <div id='edit_tube_controls' ref={edit_tube_controls}>
                    <Button variant='danger' onClick={(e) => {
                        // const newTubes = tubes.map((tube, i) => {
                        //     return (i===currentTube.i ? null : tube)
                        // })

                        const newTubes = tubes.filter((v,i) => i !== currentTube.i)
                        setTubes(newTubes)
                        edit_tube_controls.current.style.display='none'
                    }}>
                        <CloseButton /> Delete
                    </Button>
                    <Button variant='warning' style={{margin:'0px 1em'}} onClick={(e) => {
                        const newTubes = tubes.map((tube, i) => {
                            if (i !== currentTube.i) {
                                return tube
                            }

                            tube.result = 'positive'
                            return tube
                        })
                        setTubes(newTubes)
                    }}>
                        <Plus /> +ve
                    </Button>
                    <Button variant='warning' style={{marginRight:'1em'}} onClick={(e) => {
                        const newTubes = tubes.map((tube, i) => {
                            if (i !== currentTube.i) {
                                return tube
                            }

                            tube.result = 'negative'
                            return tube
                        })
                        setTubes(newTubes)
                    }}>
                        <Dash /> -ve
                    </Button>
                    <Button onClick={(e) => edit_tube_controls.current.style.display='none'}>
                        <CloseButton /> Close
                    </Button>
                </div>
            </div>
            
            <h3>Metadata</h3>
            <p>Image dimensions: {annotation.image.width} x {annotation.image.height}</p>
            {
                (annotation.source.source === 'video'
                 ? <p><strong>Source:</strong> {annotation.source.video.filename} {
                        annotation.source.timestamp.toLocaleString("en-NZ", {maximumFractionDigits: 2, minimumFractionDigits: 2})
                        } seconds ({
                        (100*annotation.source.timestamp/annotation.source.duration).toLocaleString("en-NZ", {maximumFractionDigits: 2, minimumFractionDigits: 2})
                        }%)</p>
                 : <p>Source: {annotation.source.source}</p>
                )
            }
            
            <p><strong>Model:</strong> {annotation.model.modelname} [{annotation.model.id}]
                &nbsp;{filesize(annotation.model.filesize_bytes, {round: 1})} 
                &nbsp;{annotation.prediction.width} x {annotation.prediction.height}</p>
            {message}
            <Button
                onClick={saveAnnotations}
                >
                    <Save /> Save annotations
                </Button>
        </div>
}