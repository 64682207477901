import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import './register.css'
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../config/api";
import authHeader from "./services/auth-header";
import { useNavigate } from "react-router-dom";

// see https://www.positronx.io/add-confirm-password-validation-in-react-with-hook-form/

export default function Register() {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  const [message, setMessage] = useState("Please fill in the form");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  function formSubmitted(data) {
    console.log("formSubmitted")
    console.log(data)

    if (data.password != data.password2) {
      console.warn("passwords dont match")
      setMessage("Passwords do not match");
      errors.password2 = {message:"passwords do not match",type:"wrong"}
      return false;
    }

    if(errors.length > 0) {
      console.warn(`${errors.length} errors to fix..`)
      console.warn(errors)
      return false;
    }

    setMessage("Please wait..")

    axios.
      post(
        API_URL + "api/auth/signup",
        data,
        { 
            headers: Object.assign(authHeader()),
            // onUploadProgress: function(progressEvent) {
            //     var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

            //     if (percentCompleted !== progress) {
            //         setProgress(percentCompleted);
            //         console.log(percentCompleted + '%');
            //     }
            //   }
        }
    )
    .then(
            function (response) {
                //handle success
                console.log(response);                
                
                if(response.status === 200) {
                  setMessage(response.data.message);
                  setRegistrationSuccess(true);
                }
            }
    )
    .catch(function (err) {
        //handle error
        setMessage('error');
        console.log("caught error");
        console.warn(err);
        if (err.response.data !== undefined && err.response.data.message !== undefined) {
          setMessage(err.response.data.message);

          if (err.response.data.error_type !== undefined) {
            if (err.response.data.error_type === "email") {
              // errors.email = {message:response.data.message}
              setError('email', err.response.data.message);
            }
          }
        }
    });
  }

  if (errors.length > 0) {
    console.warn(`${errors.length} errors:`)
    console.warn(errors)
  }

  const registrationForm = <form
          onSubmit={handleSubmit((data) => formSubmitted(data))}
          id="registrationForm"        
      >
        <div className="container">
          <h1 className="display-1">Create an account</h1>
          <p>
            Register today to unlock <span className="glo">glo</span>'s cool
            features.
          </p>
  
          <div className="alert alert-info">{message}</div>
  
          <div className="row mb-3">
            <div className="form-floating g-2">
              <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder="name@example.com"
                  autoComplete="email"
                  {...register("email", { required: true, minLength: 5, pattern:/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}
              />
              <label htmlFor="email">Email address</label>
              {errors.email && 
                      <div className="pleaseCheck">Please check email format</div>
              }
            </div>
          </div>
  
  
          <div className="row mb-3">
            <div className="col form-floating  g-2">
              <input
                type="text"
                // className="form-control"
                className={`form-control ${errors.forename ? 'is-invalid' : ''}`}
                placeholder="Forename"
                autoComplete="given-name"
                {...register("forename", { required: true, minLength: 1, maxLength: 60 })}
              />
              <label htmlFor="forename">Forename</label>
              {errors.forename && 
                <p className="pleaseCheck">Please enter your first name</p>
              }
            </div>
            <div className="col form-floating g-2">
              <input
                className={`form-control ${errors.surname ? 'is-invalid' : ''}`}
                placeholder="Surname"
                autoComplete="family-name"
                {...register("surname", { required: true, minLength: 1, maxLength: 60 })}
              />
              <label htmlFor="surname">Surname</label>
              {errors.surname && <p className="pleaseCheck">Surname is required.</p>}
            </div>
          </div>
  
          <div className="row mb-3">
            <div className="col form-floating g-2">
              <input
                type="password"
                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                id="password"
                placeholder=""
                autoComplete="new-password"
                {...register("password", { required: true, minLength: 8, maxLength: 60 })}
              />
              <label htmlFor="password" className="form-label">
                Password
              </label>
              {errors.password && <p className="pleaseCheck">Make the password more complex.</p>}
            </div>
            <div className="col form-floating g-2">
              <input
                type="password"
                className={`form-control ${errors.password2 ? 'is-invalid' : ''}`}
                id="password2"
                placeholder=""
                autoComplete="new-password"
                {...register("password2", { required: true, validate: (val: string) => {
                  if (watch('password') != val) {
                    return "Passwords do not match";
                  }
                } })}
              />
              <label htmlFor="password2" className="form-label">
                Confirm password
              </label>
              {errors.password2 && <p className="pleaseCheck">Check passwords match</p>}
            </div>
          </div>
  
          <div className="mb-3 form-check">
            <input
              className={`form-check-input${errors.agree ? ' is-invalid' : ''}`}
              type="checkbox"
              value="yes"
              id="invalidCheck"
              {...register("agree", { required: true })}
            />
            <label className="form-check-label" htmlFor="invalidCheck">
              Agree to terms and conditions
            </label>
            { errors.agree && 
              <div className="pleaseCheck">
                You must agree before submitting.
              </div>
            }
          </div>
  
          <div className="col-12">
            <button className="btn btn-primary" type="submit">
              Register
            </button>
          </div>
        </div>
      </form>;

  const successMessage = <div>
    <h1 className="display-1">Success!</h1>
    <p>
      Your <span className="glo">glo</span>'s user account has been created.
    </p>
    <p>
      <Link to='/login'>Login</Link>
    </p>
  </div>

  return(
      (registrationSuccess
        ? successMessage
        : registrationForm
      )
  );
}
