// 21/6/2023 - run potential tubes through the net again.

import { makeArray } from "./makeArray"
import predictMask from "./predictMask"
import * as tf from "@tensorflow/tfjs"
import EllipseFromPoints from "./ellipse";

export const predictOnPotentialTubes = (predicted_tubes, net, image_tensor) => {
    
    console.log(`********* Running ${predicted_tubes.tube_clusters.length} potential tubes through net again ****`)

    // create image data for this predicted mask
    const colours = {
        tip: [255, 0, 0, 160],
        tube: [0,255,255, 150],
        background: [255,255,255,0]
    }

    const rgb_array = image_tensor.arraySync()[0]

    // console.info('rgb_array',rgb_array)
    const img_h = rgb_array.length
    const img_w = rgb_array[1].length
    
    // const padding_x = 0.015
    // const padding_y = 0.015
    
    const padding_x = 0.02
    const padding_y = 0.02

    tf.tidy(() => {
        predicted_tubes.tube_clusters.forEach((tube, i) => {
            const x1 = tube.box[1]-padding_x
            const y1 = tube.box[0]-padding_y
            const x2 = tube.box[3]+padding_x
            const y2 = tube.box[2]+padding_y

            const padded_box = [x1,y1,x2,y2]

            const prediction = predictMask(net, image_tensor, padded_box)

            // predicted_tubes.tube_clusters[i].prediction2 = prediction

            const mask = makeArray(net.feedInputShapes[0][1], net.feedInputShapes[0][2], colours.background)
            const tip_pixels = []
            const tip_and_tube_pixels = []
            const tip_rgbs = []

            for(var y=0; y < prediction.predicted_mask.length; y++) {
                const row = prediction.predicted_mask[y]
                for (var x=0; x < row.length; x++) {
                    const point = row[x]
                    if (point == 2) { // tube
                        mask[y][x] = colours.tube
                        tip_and_tube_pixels.push({x:x, y:y})
                    } else if (point==1) { // tip
                        mask[y][x] = colours.tip
                        tip_pixels.push({x:x, y:y})
                        tip_and_tube_pixels.push({x:x, y:y})

                        
                        const px = Math.round(img_w * (x1 + (x/row.length) * (x2-x1)))
                        const py = Math.round(img_h * (y1 + (y/ prediction.predicted_mask.length) * (y2-y1)))

                        tip_rgbs.push(rgb_array[py][px])
                    }
                }
            }

            const flattened_predicted_mask = new Uint8ClampedArray([].concat.apply([], [].concat.apply([], mask)))
            const predicted_mask = new ImageData(flattened_predicted_mask, net.feedInputShapes[0][2], net.feedInputShapes[0][1]);

            var likelyPositive = 0
            tip_rgbs.forEach((rgb, i) => {
                if ( rgb[0] > .77 
                    & rgb[1] > .63 
                    & rgb[2] < .4 
                    ) {
                        likelyPositive = likelyPositive + 1
                    }
            })

            // fit ellipse to the tube here.
            var ellipse = EllipseFromPoints(tip_and_tube_pixels)
            var tip_ellipse = EllipseFromPoints(tip_pixels)

            // predicted_tubes.tube_clusters[i].imageMask = predicted_mask
            predicted_tubes.tube_clusters[i].hq_prediction = {
                mask_image: predicted_mask,
                model_dimensions: {
                    w: net.feedInputShapes[0][2],
                    h: net.feedInputShapes[0][1]
                },
                box: {
                    x1: x1,
                    y1: y1,
                    x2: x2,
                    y2: y2,
                    w: x2-x1,
                    h: y2-y1
                },
                ellipse: ellipse,
                tip_ellipse: tip_ellipse,
                tip_pixels: tip_pixels,
                tip_rgbs: tip_rgbs,
                likelyPositive: (likelyPositive / tip_rgbs.length)
            }
        })
        
    })
    return predicted_tubes
}