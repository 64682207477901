import { PersonCircle, CodeSquare } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./register.css";
import { useState } from "react";
import { API_URL } from "../config/api";
// const API_URL = "http://localhost:8080/api/auth/";


export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [warningMessage, setWarningMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);

  
  const navigate = useNavigate();

  const handleLogin = function(data) {
    console.info("sending this data to sign in api:");
    console.log(data);

    // at this point, submit the credentials to the api
    try {
      return axios
        .post(API_URL + "api/auth/signin", data)
        .then(response => {
          if(response.data.accessToken) {
            console.log("access token recieved- saving to local storage.")
            console.log(response.data)
            localStorage.setItem("user",JSON.stringify(response.data));
            // setWarningMessage("Logged in");
            // redirect to another page.
            navigate("/dashboard");
          }

          return response.data;
        })
        .catch(function(error) {
          console.warn("Axios caught an error")
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response.data.message) {
              console.log("Setting message to ",error.response.data.message)
              setWarningMessage(error.response.data.message);
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        })
    } catch(error) {
      console.warn("There was an error not picked up by axios:")
      console.error(error)
    }
  }

  
  return (
    <div className="container">
      <h1 className="display-1">Sign in</h1>
      <p>Please enter your  <span className="glo">glo</span> email and password.</p>
      <form onSubmit={handleSubmit(handleLogin)}>

        {
          warningMessage && <div className="alert alert-danger">{warningMessage}</div>
        }

        <h2>
          <PersonCircle /> Email
        </h2>

        <div className="form-floating">
          <input
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            id="email"
            placeholder="name@example.com"
            autoComplete="email"
            {...register("email", {
                required:true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          <label>Email address</label>
          {errors.email && (
            <div className="pleaseCheck">Please check email</div>
          )}
        </div>

        <h2>
          <CodeSquare /> Password
        </h2>

        <div className="form-floating">
          <input
            type="password"
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
            autoComplete="current-password"
            {...register("password", { required: true })}
          />
          
          <label htmlFor="password" className="form-label">
              Password
            </label>

            
          {errors.password && (
            <div className="pleaseCheck">Check password</div>
          )}
        </div>

        <button className="btn btn-primary">Sign in</button>
        <p className="py-5">
          <Link to="/register">Create an account</Link>
        </p>
      </form>
    </div>
  );
}
