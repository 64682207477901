// show list of annotations

import axios from "axios";
import { useMemo } from "react";
import { API_URL } from "../config/api";
import { useState } from "react";
import authHeader from "./services/auth-header";
import authService from "./services/auth.service";
import { Link } from "react-router-dom";


const AnnotationList = () => {
    const [annotationData, setAnnotationData] = useState(null)
    const currentUser = authService.getCurrentUser();

    useMemo(() => {
        axios.get(
            `${API_URL}api/annotation/all`,
            // params
            {
                headers: Object.assign(authHeader())
            }
        )
        .then((data) => {
            console.warn('data', data.data)
            setAnnotationData(data.data)
        })


    },[])

    
    const AnnotateHeader = () => {
        return <div className='row'>
            <div className='col col-6'>
                <h1 className="glo display-1">
                    <Link to='/'>GLO</Link>
                </h1>
            </div>
            <div className='col col-6'>
                {
                currentUser
                ? <div className='signedInInfo py-2 text-info'>
                    <div>{currentUser.forename}</div>
                    <Link to='/'>Home</Link>
                    </div>
                : <div className='signedInInfo py-2 text-info'>
                    <div>Not signed in</div>
                    <Link to='benchmarking'>Benchmarking</Link>
                    </div>
                }
            </div>
        </div>
    }

    if (annotationData === null) {
        return <>Please wait</>
    }

    return <>
        <AnnotateHeader />
        <h1>{annotationData.annotations.length} annotations</h1>
        {annotationData.annotations.map((v,i) => {
            return <Link 
                key={`annotation_${i}`} 
                to={`${v.id}`} 
                style={{textDecoration:'none', color: '#1a1313'}}>
              <div 
                style={{
                    border: '1px solid #CCC', 
                    marginBottom: '1em',
                    // backgroundImage: `url('https://devapi.poops.nz/images/thumbnails/${v.user.id}/${v.image.id}')`,
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: '0% 0%',
                    background: `no-repeat 0% 0% url('https://devapi.poops.nz/images/thumbnails/${v.user.id}/${v.image.id}') `,
                    paddingLeft: '210px',
                    minHeight: '100px'
                }}
                >
                <div>{v.id}. {v.image.filename} ({v.image.filesize_human})</div>
                <div>{v.number_of_tubes} tubes</div>
                <div>Created by <span style={{color: 'cornflowerblue'}}>{v.user.forename} {v.user.surname}</span></div>
                <div>{v.createdAt}</div>
              </div>
            </Link>
        })}
    </>
}


export default AnnotationList;