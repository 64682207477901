// run dbscan on a mask to convert to clusters
import { makeArray } from "./makeArray";
import { jDBSCAN } from "./jDBSCAN";
import EllipseFromPoints from "./ellipse";

function add_boxes(clusters, points, width, height) {
    // calculate the bounding boxes for each tube
    for (var i = 0; i < clusters.length; i++) {
        var cluster = clusters[i]
        var minX = 9999 //cluster.x
        var maxX = -1 //cluster.x
        var minY = 9999 //cluster.y
        var maxY = -1 //cluster.y

        for (var j in cluster.parts) {
            const point = points[cluster.parts[j]]
            minX = Math.min(minX, point.x)
            maxX = Math.max(maxX, point.x)
            minY = Math.min(minY, point.y)
            maxY = Math.max(maxY, point.y)
        }
        clusters[i]['box_int'] = [minX, minY, maxX, maxY]

        // convert box coordinates to relative scale from mask wh
        minX = minX / width
        maxX = maxX / width
        minY = minY / height
        maxY = maxY / height

        clusters[i]['box'] = [minX, minY, maxX, maxY]
    }
    return clusters
}



function add_ellipses(clusters, points) {
    // calculate the bounding boxes for each tube
    for (var i = 0; i < clusters.length; i++) {
        var cluster = clusters[i]
        const cluster_points = []
        
        for (var j in cluster.parts) {
            cluster_points.push(points[cluster.parts[j]])
        }

        clusters[i]['points'] = cluster_points

        var ellipse = EllipseFromPoints(cluster_points)

        clusters[i]['ellipse'] = ellipse

    }
    return clusters
}



function add_percentage_of_pixels_covered(clusters, width, height) {
    // calculate the bounding boxes for each tube
    for (var i = 0; i < clusters.length; i++) {
        var cluster = clusters[i]

        clusters[i]['percentage_of_pixels_covered'] = 100 * cluster.dimension / (width*height)
        // var minX = 9999 //cluster.x
        // var maxX = -1 //cluster.x
        // var minY = 9999 //cluster.y
        // var maxY = -1 //cluster.y

        // for (var j in cluster.parts) {
        //     const point = points[cluster.parts[j]]
        //     minX = Math.min(minX, point.x)
        //     maxX = Math.max(maxX, point.x)
        //     minY = Math.min(minY, point.y)
        //     maxY = Math.max(maxY, point.y)
        // }
        // clusters[i]['box_int'] = [minX, minY, maxX, maxY]

        // // convert box coordinates to relative scale from mask wh
        // minX = minX / width
        // maxX = maxX / width
        // minY = minY / height
        // maxY = maxY / height

        // clusters[i]['box'] = [minX, minY, maxX, maxY]
    }
    return clusters
}


export default function maskToTubes(combined_mask) {
    const width = combined_mask[0].length
    const height = combined_mask.length

    const colours = {
        tip: [255, 0, 0, 0],
        tube: [0,255,255, 150],
        background: [255,255,255,133]
    }

    // create data set for dbscan.
    var tip_points = []
    var tube_points = []


    for (const y in combined_mask) {
        const row = combined_mask[y]
        for (const x in row) {
            const val = row[x]

            if (val == 1) {
                tip_points.push({x: Number(x), y:Number(y)})
                tube_points.push({x: Number(x), y:Number(y)})
            } else if (val > 0) {
                tube_points.push({x: Number(x), y:Number(y)})
            }
        }
    }

    // console.log(`Tip points:${tip_points.length} tubes:${tube_points.length}`)

    var dbscanner_tips = jDBSCAN()
        .eps(5)
        .minPts(1)
        .distance('EUCLIDEAN')
        .data(tip_points)

        
    var dbscanner_tubes = jDBSCAN()
        .eps(4)
        .minPts(2)
        .distance('EUCLIDEAN')
        .data(tube_points)
    

    // console.log("Resulting dbscan output", dbscanner_tips)

    var tube_array = makeArray(width, height, ".")
    var prediction_mask = makeArray(width, height, colours.background)

    // now we get cluster ids from scanResults, alongside
    var tip_cluster_ids = dbscanner_tips()
    var tip_clusters = dbscanner_tips.getClusters()
    var tube_cluster_ids = dbscanner_tubes()
    var tube_clusters = dbscanner_tubes.getClusters()

    // console.log(`Tip cid:${tip_cluster_ids.length} tubes:${tube_cluster_ids.length}`)
    
    // okay, now change the tube_array values to match the predicted clusters.

    const tube_symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890+-)(*&^%$#@!"
    const tip_symbols = "abcdefghijklmnopqrstuvwxyz1234567890+-)(*&^%$#@!ABCDEFGHIJKLMNOPQRSTUVWXYZ"

    for(var i=0; i < tube_cluster_ids.length; i++) {
        var point = tube_points[i]
        var cluster_id = tube_cluster_ids[i]

        tube_array[point.y][point.x] = tube_symbols[cluster_id]
        prediction_mask[point.y][point.x] = colours.tube
    }

    // console.log("tip clusters", tip_clusters)
    
    // shade the tip backgrounds.
    for (var i = 0; i < tip_cluster_ids.length; i++) {
        var point = tip_points[i]
        var cluster_id = tip_cluster_ids[i]

        if (cluster_id === 0) {
            tube_array[point.y][point.x] = `<b style=\"background-color:red\">0</b>`
        }
        else if (tip_clusters[cluster_id-1].dimension >= 5) {
            tube_array[point.y][point.x] = `<b style=\"background-color:khaki\">${tip_symbols[cluster_id]}</b>`
            prediction_mask[point.y][point.x] = colours.tip
        }
        else {
            tube_array[point.y][point.x] = `<b style=\"background-color:aqua\">${tip_symbols[cluster_id]}</b>`
        }
    }

    tube_clusters = add_boxes(tube_clusters, tube_points, width, height)
    tip_clusters = add_boxes(tip_clusters, tip_points, width, height)

    var tube_prediction = tube_array.map(function(a) { return a.join('') }).join("\n")


    // Add ellipses
    // e.setFromPoints(points)
    tube_clusters = add_ellipses(tube_clusters, tube_points) 
    tip_clusters = add_ellipses(tip_clusters, tip_points) 


    // Add percentage_coverage
    tube_clusters = add_percentage_of_pixels_covered(tube_clusters, width, height)
    tip_clusters = add_percentage_of_pixels_covered(tip_clusters, width, height)

    // Assign tips to tubes.
    for (var j = 0; j < tube_clusters.length; j++) {
        // find tube which has max overlap with tip box.
        var tube = tube_clusters[j]
        tube_clusters[j]['tip_index'] = []

        
        for (var i = 0; i < tip_clusters.length; i++) {
            // find which tube the tip belongs to
            var tip = tip_clusters[i]

            // is the centre of the tip within the tube bounding box?
            if (tip.x >= tube.box_int[0] &
                tip.x <= (tube.box_int[2]) &
                tip.y >= tube.box_int[1] &
                tip.y <= (tube.box_int[3])
            ) {
                // console.log(`allocating tip ${i} to tube ${j}`)
                tip_clusters[i]['tube_index'] = j
                tube_clusters[j]['tip_index'].push(i)
            }
        }
    }

    // flatten the predicted_mask array twice
    const flattened_predicted_mask = new Uint8ClampedArray([].concat.apply([], [].concat.apply([], prediction_mask)))
    const predicted_mask = new ImageData(flattened_predicted_mask, width, height);

    return({
        height: height,
        width: width,
        // tips: dbscanner_tips,
        tip_points: tip_points,
        tip_clusters: tip_clusters,
        // tubes: dbscanner_tubes,
        // tube_array: tube_array,
        tube_points: tube_points,
        tube_prediction: tube_prediction,
        tube_clusters: tube_clusters,
        // prediction_mask: prediction_mask,
        predicted_mask: predicted_mask
    })
}