import { Component, useMemo } from "react";
import AuthService from "./services/auth.service";
import { Link, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import { Trash } from "react-bootstrap-icons";
import axios from "axios";
import { API_URL } from "../config/api";
import { useEffect } from "react";
import { FileImage, Save } from "react-bootstrap-icons";
import {filesize} from "filesize";
import authHeader from "./services/auth-header"
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';

import * as tf from "@tensorflow/tfjs"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// see http://localhost:8080/api/protocols for api with protocol name+id
import { prediction } from "./prediction";

import { jDBSCAN } from "./helpers/jDBSCAN";


function makeArray(w, h, val) {
    var arr = [];
    for(let i = 0; i < h; i++) {
        arr[i] = [];
        for(let j = 0; j < w; j++) {
            arr[i][j] = val;
        }
    }
    return arr;
}

function runScan() {
    
    var new_prediction = prediction.replaceAll("0",".")    
    new_prediction = new_prediction.replaceAll("1","<b style=\"background-color:orange\">1</b>")
    new_prediction = new_prediction.replaceAll("2","<b style=\"background-color:khaki\">2</b>")

    const lines = prediction.split("\n")
    const width = prediction.indexOf("\n")
    const height = lines.length

    // create data set for dbscan.
    var tip_points = []
    var tube_points = []
    window.lines = lines

    for (var y = 0; y < height; y++) {
        const line = lines[y]
        for (var x = 0; x < width; x++) {
            var val = line[x]

            if (val == 1) {
                tip_points.push({x:x, y:y})
            }
            
            if (val > 0) {
                tube_points.push({x:x, y:y})
            }
        }
    }
    console.log(`Tip points:${tip_points.length} tubes:${tube_points.length}`)

    // console.log(tip_points)
    // console.log(tube_points)

    var dbscanner_tips = jDBSCAN()
        .eps(5)
        .minPts(1)
        .distance('EUCLIDEAN')
        .data(tip_points)

        
    var dbscanner_tubes = jDBSCAN()
        .eps(4)
        .minPts(2)
        .distance('EUCLIDEAN')
        .data(tube_points)
    

    // console.log("Resulting dbscan output", dbscanner_tips)

    var tube_array = makeArray(width, height, ".")

    // now we get cluster ids from scanResults, alongside

    var tip_cluster_ids = dbscanner_tips()
    var tip_clusters = dbscanner_tips.getClusters()
    var tube_cluster_ids = dbscanner_tubes()
    var tube_clusters = dbscanner_tubes.getClusters()

    console.log(`Tip cid:${tip_cluster_ids.length} tubes:${tube_cluster_ids.length}`)
    
    // okay, now change the tube_array values to match the predicted clusters.

    const tube_symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890+-)(*&^%$#@!"
    const tip_symbols = "abcdefghijklmnopqrstuvwxyz1234567890+-)(*&^%$#@!ABCDEFGHIJKLMNOPQRSTUVWXYZ"

    for(var i=0; i < tube_cluster_ids.length; i++) {
        var point = tube_points[i]
        var cluster_id = tube_cluster_ids[i]

        tube_array[point.y][point.x] = tube_symbols[cluster_id]
    }

    console.log("tip clusters", tip_clusters)
    
    // shade the tip backgrounds.
    for (var i = 0; i < tip_cluster_ids.length; i++) {
        var point = tip_points[i]
        var cluster_id = tip_cluster_ids[i]

        if (cluster_id === 0) {
            tube_array[point.y][point.x] = `<b style=\"background-color:red\">0</b>`
        }
        else if (tip_clusters[cluster_id-1].dimension >= 5) {
            tube_array[point.y][point.x] = `<b style=\"background-color:khaki\">${tip_symbols[cluster_id]}</b>`
        }
        else {
            tube_array[point.y][point.x] = `<b style=\"background-color:aqua\">${tip_symbols[cluster_id]}</b>`
        }
    }

    // calculate the bounding boxes for each tube
    for (var i = 0; i < tube_clusters.length; i++) {
        var cluster = tube_clusters[i]
        var minX = 9999 //cluster.x
        var maxX = -1 //cluster.x
        var minY = 9999 //cluster.y
        var maxY = -1 //cluster.y

        console.log("cluster", cluster)

        for (var j in cluster.parts) {
            const point = tube_points[cluster.parts[j]]
            minX = Math.min(minX, point.x)
            maxX = Math.max(maxX, point.x)
            minY = Math.min(minY, point.y)
            maxY = Math.max(maxY, point.y)
        }

        tube_clusters[i]['box'] = [minX, minY, maxX, maxY] //-minX, maxY-minY]
    }

    var tube_prediction = tube_array.map(function(a) { return a.join('') }).join("\n")

    return({
        height: height,
        width: width,
        new_prediction: new_prediction,
        tips: dbscanner_tips,
        tip_clusters: tip_clusters,
        tubes: dbscanner_tubes,
        tube_array: tube_array,
        tube_prediction: tube_prediction,
        tube_clusters: tube_clusters
    })
}



export default function Test() {

    const [scanInfo, setScanInfo] = useState(undefined)

    useMemo(() => {
        const scanResults = runScan()
        setScanInfo(scanResults)
        window.scanResults = scanResults
    },[])


    if (scanInfo === undefined) {
        return <div>No scan info</div>
    }

    return <div>
        <p>Height: {scanInfo.height} Width: {scanInfo.width}  Len: {prediction.length}</p>
        <h2>Tubes with dbscan</h2>
        <div style={{fontSize:'.5em', fontFamily: 'courier'}} dangerouslySetInnerHTML={{ __html: scanInfo.tube_prediction}} />


        <hr />
        <h2>Original prediction</h2>
        <div style={{fontSize:'.5em', fontFamily: 'courier'}} dangerouslySetInnerHTML={{ __html: scanInfo.new_prediction}} />
    </div>
}