import { useMemo, useRef, useState, useEffect, useCallback } from "react";
import { useHistoryState, useWindowSize } from "@uidotdev/usehooks";
import { Camera, CameraFill, Hypnotize, Repeat, Lightbulb, CameraVideo, ArrowRight, Play, CameraVideoFill } from "react-bootstrap-icons";
import { Button, Col, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Canvas from "../helpers/canvasHelpers";
import { filesize } from "filesize";
import axios from "axios";
import { API_URL, REMOTE_URL, MODEL_URL } from "../../config/api";
import authHeader from "../services/auth-header";
import * as tf from "@tensorflow/tfjs"
import predictMask from "../helpers/predictMask";
import { predictOnPotentialTubes } from "../helpers/predictOnPotentialTubes";
import maskToTubes from "../helpers/maskToTubes";
import { annotateTube } from "./annotateTube";
import { useNavigate } from "react-router-dom";
import GloAnnotate from "./annotate";
import Webcam from "react-webcam";



const CanvasBorders = (props) => {
    console.log('CanvasBorders', props)

    useMemo(() => {

        console.log("width changed!")
        const canvas = props.ref.current
        const context = canvas.getContext('2d')

        
    }, [props.style.width])

    return <canvas ref={props.ref} style={props.style} />
}


const GloVideoDemo = () => {
    let navigate = useNavigate();

    const cameraRef = useRef(null);
    const canvasBordersRef = useRef(null);
    const canvasRef = useRef(null);
    // const [mode, setSwitchMode] = useState('pixels')
    let timer
    var interval_ms = 1000; ///1000/15;
    const size = useWindowSize();
    const isLandscape = size.height <= size.width;
    const ratio = size.width/size.height;
    const [isSafari, setIsSafari] = useState(false)
    const videoRef  = useRef(null)

    // ----- functions to get a list of video devices -----
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [devices, setDevices] = useState([]);
    const [cameraScreenshotSize, setCameraSceenshotSize] = useState({width:44, height: 55})

    
    const [imageSrc, setImageSrc] = useState(null);
    // debugging msgs
    const [msg, setMsg] = useState('xxx--as')
  
    const handleDevices = useCallback(
      mediaDevices => {
        const videoInputs = mediaDevices.filter(({ kind }) => kind === "videoinput")

        videoInputs.map((vi, i) => {
            videoInputs[i].capabilities = vi.getCapabilities()
        })

        setDevices(videoInputs)
      },
      [setDevices]
    );
  
    useEffect(
      () => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
      },
      [handleDevices]
    );
    // ----------------------------------------


    const videoFiles = [
        
        {
            filename: '14tube_1.mp4',
            description: '14 tube, v1',
            filesize: 10909000,
            // bitmap_rotated: true
        },
        {
            filename: '14tube_2.mp4',
            description: '14 tube, v2',
            filesize: 4614000,
            // bitmap_rotated: true
        },
        {
            filename: '14tube_3.mp4',
            description: '14 tube, v3 - glare',
            filesize: 6990000,
            // bitmap_rotated: true
        },


        {
            filename: 'IMG_0958.MOV',
            description: '5 tube, 3+ve',
            filesize: 12917000,
            bitmap_rotated: true
        },
        {
            filename: 'IMG_0959.MOV',
            description: '5 tube, 3+ve v2',
            filesize: 12126000,
            bitmap_rotated: true
        },
        {
            filename: 'IMG_0960.MOV',
            description: '5 tube, 3+ve rollover',
            filesize: 16149000,
            bitmap_rotated: true
        },
        {
            filename: 'IMG_0961.MOV',
            description: '5 tube, 4+ve',
            filesize: 11088000,
            bitmap_rotated: true
        },
        {
            filename: 'IMG_0962.MOV',
            description: '5 tube, 4+ve side roll',
            filesize: 30390000,
            bitmap_rotated: true
        },
        {
            filename: 'IMG_1011.MOV',
            description: '11 tube, tight crop',
            filesize: 13653000,
            bitmap_rotated: true
        },
        {
            filename: 'IMG_1012.MOV',
            description: '11 tube, reflections',
            filesize: 16358000,
            bitmap_rotated: true
        },
        {
            filename: 'IMG_1013.MOV',
            description: '11 tube, another tight crop',
            filesize: 9300,
            bitmap_rotated: true
        },
        {
            filename: 'IMG_1014.MOV',
            description: '11 tube, facing right',
            filesize: 18165000,
            bitmap_rotated: true
        },
        // {
        //     filename: 'OfficeCats.mp4',
        //     description: 'Cats working in an office',
        //     filesize: 2605000,
        //     bitmap_rotated: false
        // },
        // {
        //     filename: 'DancingCats.mp4',
        //     description: 'Cats dancing in an office',
        //     filesize: 1604000,
        //     bitmap_rotated: false
        // },
        // {
        //     filename: 'CatDates.mp4',
        //     description: 'Cats dating',
        //     filesize: 2960000,
        //     bitmap_rotated: false
        // },
        // // bridget's cat videos
        // {
        //     filename: 'bridget/puppy_kitten_vids/cat_sofa.MP4',
        //     description: 'Cats on sofa',
        //     filesize: 3209000,
        //     bitmap_rotated: true
        // },
        // {
        //     filename: 'bridget/puppy_kitten_vids/dog_duck.MP4',
        //     description: 'Dog n duck',
        //     filesize: 3209000,
        //     bitmap_rotated: true
        // },
        // {
        //     filename: 'bridget/puppy_kitten_vids/entyrely_dogs.MOV',
        //     description: 'Entyrely dogs',
        //     filesize: 22153000,
        //     bitmap_rotated: true
        // },
        // {
        //     filename: 'bridget/puppy_kitten_vids/puppy.MP4',
        //     description: 'Puppy',
        //     filesize: 8035000,
        //     bitmap_rotated: true
        // },
        // {
        //     filename: 'bridget/puppy_kitten_vids/sleepy_cats.mp4',
        //     description: 'Sleepy cats',
        //     filesize: 14575000,
        //     bitmap_rotated: false
        // },
    ]

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [predictedTubes, setPredictedTubes] = useState(null);
    const [bitmap, setBitmap] = useState(null);
    const [modelStatus, setModelStatus] = useState('')
    const [net, setNet] = useState(null);
    const [showFileList, setShowFileList] = useState(true);
    const [showModelList, setShowModelList] = useState(false);
    const [count, setCount] = useState(0)
    const [annotation, setAnnotation] = useState(null);
    const [mode, setMode] = useState(null); // null / camera / video

    const handleCloseFiles = () => {
        setShowFileList(false);
        
        if (selectedModel === null) {
            handleShowModels()
        }
    }
    const handleShowFiles = () => {
        if (timer) {
            clearTimeout(timer)
        }

        setSelectedVideo(null)
        setMode(null)
        setShowFileList(true)
    }
    const handleCloseModels = () => setShowModelList(false);
    const handleShowModels = () => {
        // setSelectedVideo(null)
        setShowModelList(true)
    }

    
    const [models, setModels] = useState(null)

    const handleCapture = () => {
        console.warn("capture button pressed :-)")
        window.vr = videoRef
        window.cr = cameraRef

        const videoCurrent = videoRef.current

        // use frame from video?
        if (videoCurrent !== null) {
            console.log('handling video')
            // toggle play/pause
            if (videoCurrent.paused) {
                videoRef.current.play()
                if (selectedModel !== null) {
                    runTimer()
                }
            } else {
                videoRef.current.pause()
                if (timer) {
                    console.log("clearning timer")
                    // clearTimeout(timer)
                    return
                }
            }
        }

        // capture frame from camera?
        if (selectedDevice !== null) {
            console.log('handling camera')

            // unfreeze
            if (imageSrc) {            
                console.log('unfreezing webcam')

                setImageSrc(null)
                if (selectedModel !== null) {
                    runTimer()
                }
                return
            } 

            // else - freeze.
            clearTimeout(timer)
            console.log(`timer STOPPED / not will stop after ${count}`)

            const newImageSrc = cameraRef.current.getScreenshot({ width:cameraScreenshotSize.width, height:cameraScreenshotSize.height });

            
            // console.log('newImageSrc', newImageSrc)
            console.log('size', size)
            console.log('cameraScreenshotSize', cameraScreenshotSize)



            const newImage = new Image()
            newImage.src = newImageSrc
            newImage.width = cameraScreenshotSize.width
            newImage.height = cameraScreenshotSize.height

            window.newImage = newImage
            console.log(`screenshot has been captured - processing newImage count ${count}`, newImage)

            processCameraFrame(newImage)



            setImageSrc(newImage);
            // setImageSrc(<img src={newImageSrc} />)
            // runTimer() // don't need to runTimer as this will automatically kick in on a change to imageSrc.
            return null
        }
        console.log('videoRef.current is null AND selectedDevice is null - returning')
        return;
    }


    const loadVideo = (meta) => {
        console.log("Loading video", meta)
        setSelectedVideo(meta)
        setSelectedDevice(null)
        setImageSrc(null)
        setMode('video')
        handleCloseFiles()
    }

    const showCamera = (device) => {
        console.log('showCamera',device)
        setSelectedDevice(device)
        calcCameraScreenshotSize(device)
        setSelectedVideo(null)
        setMode('camera')
        setImageSrc(null)
        handleCloseFiles()
    }

    useEffect(() => {
        // see https://stackoverflow.com/questions/7944460/detect-safari-browser\


        var safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)


        console.log(`Checking for safari: ${safari}`, navigator.userAgent)

        setIsSafari(safari)
    },[])


    const predictAndDrawTubes = (pixelPredictions, tensor) => {
        var predicted_tubes = maskToTubes(pixelPredictions);
        console.log("predicted_tubes v1",predicted_tubes)

        if (predicted_tubes?.tube_clusters?.length > 0) {
            if (tensor.isDisposed) {
                console.warn("TENSOR IS DISPOSED>>!")
                return
            }
            predicted_tubes = predictOnPotentialTubes(predicted_tubes, net, tensor)
        } else {
            console.warn("predicted_tubes?.tube_clusters?.length > 0 is false, so no point in processing potential tubes.")
        }

        tensor.dispose()

        console.log("predicted_tubes v2", predicted_tubes)
        setPredictedTubes(predicted_tubes)
    }

    async function processFrame() {
        const video = videoRef.current
        window.video = video
        window.canvas = canvasRef


        
        const video_height = video.videoHeight
        const video_width = video.videoWidth
        const video_wh_ratio = video_width / video_height


        const bitmap = await createImageBitmap(video, 
            0, 
            0, 
            (selectedVideo.bitmap_rotated & ! isSafari ? video.videoHeight : video.videoWidth), 
            (selectedVideo.bitmap_rotated & ! isSafari ? video.videoWidth : video.videoHeight ),
            {
            resizeQuality: "low", 
        
        })

        setBitmap(bitmap)
        
        if (bitmap === null) {
            console.error("bitmap is null")
            clearTimeout(timer)
            return
        }

        var originalTensor = tf.browser.fromPixels(bitmap, 3);

        // console.log('originalTensor1', originalTensor.arraySync()[0])

        // normalise by /255 ?
        const normalized = (
            selectedModel.normalise 
            ? originalTensor.div(tf.scalar(255.0)) 
            : originalTensor
            );


        // expand
        const tensor = normalized.expandDims(0);


        // tidy up originalTensor
        originalTensor.dispose()

        // dispose of normalized
        normalized.dispose()

        // boxes are in format y1 x1 y2 x2
        var windowTensor = tf.image.cropAndResize(tensor, [[0,0,1,1]], [0], [net.feedInputShapes[0][1],net.feedInputShapes[0][2]])
        
        // console.log('windowTensor1', windowTensor.arraySync()[0])

        const prediction =  net.predict(windowTensor);
        const pred_mask = tf.argMax(prediction, -1)

        prediction.dispose()
        windowTensor.dispose()
        
        if (videoRef.current.paused) {
            // do extra processing to detect individual tubes.
            console.log("detecting tubes from pausedVideoRef")
            const pixelPredictions = await pred_mask.array()
            pred_mask.dispose()

            return predictAndDrawTubes(pixelPredictions[0], tensor)
        }
        const pred_mask_array = pred_mask.dataSync()

        const myColours = [
                [255,255,255,  12],
                [0  ,  0,244, 198],
                [0  ,255,  0, 198]
            ]

        // draw to canvas.
        // const numPixels = 224 * 224
        const numPixels = net.feedInputShapes[0][1] * net.feedInputShapes[0][2];

        const rgbVals = new Uint8ClampedArray(numPixels*4);

        for (var x = 0; x < numPixels; x++) {
            var pixel_prediction = pred_mask_array[x]
            
            var rgba = myColours[pixel_prediction]
            rgbVals[4 * x + 0] = rgba[0]
            rgbVals[4 * x + 1] = rgba[1]
            rgbVals[4 * x + 2] = rgba[2]
            rgbVals[4 * x + 3] = rgba[3]
        }

        // console.log('rgbVals',rgbVals)

        const maskImageData = new ImageData(rgbVals,net.feedInputShapes[0][1],net.feedInputShapes[0][2]);


        const ctx = canvasRef.current.getContext('2d')

        const window_wh_ratio = size.width / size.height

        // calculate canvas width + height to match the scaled video window.
        const canvas_width = (window_wh_ratio < video_wh_ratio 
            ? size.width // constrained by height
            : size.height * video_wh_ratio // constrained by width
            )
        const bitmap_height = (window_wh_ratio < video_wh_ratio 
                ? size.width / video_wh_ratio // constrained by height
                : size.height // constrained by width
                )
        const canvas_height = size.height
            
        canvasRef.current.width = canvas_width
        canvasRef.current.height = canvas_height
        
        canvasRef.width = canvas_width
        canvasRef.height = canvas_height

        createImageBitmap(maskImageData).then(function(imgBitmap) {
            ctx.clearRect(0, 0, canvas_width, canvas_height);

            
            // // draw the bitmap frame?
            const bitmap_y = (window_wh_ratio < video_wh_ratio 
                // ? (canvas_height - (canvas_width * video_wh_ratio))/2 // constrained by height
                ? (size.height - (size.width / video_wh_ratio ))/2
                : 0 // constrained by width
                )

            console.info(`s.h: ${size.height} cH: ${canvas_height} bitmap_y: ${bitmap_y}`)
            
            ctx.globalAlpha = 0.79
            ctx.drawImage(imgBitmap, 0, bitmap_y, canvas_width, bitmap_height);
            
        });
    }








    async function processCameraFrame(source) {

        console.log(`[${count}] processCameraFrame ${cameraScreenshotSize.width}x${cameraScreenshotSize.height} source w:${source.width} H:${source.height}`, source)
        const frame_wh_ratio = cameraScreenshotSize.width / cameraScreenshotSize.height



        // pixels passed to tf.browser.fromPixels() must be either an HTMLVideoElement, HTMLImageElement, HTMLCanvasElement, ImageData in browser, or OffscreenCanvas, ImageData in webworker or {data: Uint32Array, width: number, height: number}, but was String

        var originalTensor = tf.browser.fromPixels(source, 3);
        // console.log('originalTensor2', originalTensor.arraySync()[0])
        console.log('originalTensor shape', originalTensor.shape)

        const newMsgArray = [
            <div>OriginalTensor shape: ${originalTensor.shape.join('x')}</div>
        ]

        if (originalTensor.shape[0]===55 && originalTensor.shape[1] === 44) {
            calcCameraScreenshotSize()
            console.warn('originalTensor shape is default value, calling caclScreenshotSize')
            return;
        }

        var croppedTensor = originalTensor

        // // check aspect ratios and slice if we've got something weird.
        // if (  originalTensor.shape[1] !== cameraScreenshotSize.width
        //     | originalTensor.shape[0] !== cameraScreenshotSize.height
        //     ) {
        //     // var newMsg = `OriginalTensor shape error: ${originalTensor.shape.join('x')}`
        //     // setMsg(newMsg)

        //     const tensorAR = originalTensor.shape[1] / originalTensor.shape[0]

        //     if (tensorAR > cameraScreenshotSize.aspectRatio) {
        //         // too wide - crop horizontally.
        //         const newH = originalTensor.shape[0]
        //         const newW = Math.floor(originalTensor.shape[0] * cameraScreenshotSize.aspectRatio)
        //         const xPad = Math.floor(( originalTensor.shape[1] - newW ) /2)

        //         const x0 = xPad
        //         const x1 = x0 + newW
        //         const y0 = 0
        //         const y1 = y0 + newH

        //         newMsgArray.push(<>
        //             <div>new wh: {newW}x{newH}</div>
        //             <div>H crop {x0},{y0}-{x1},{y1}</div>
        //             </>)
        //         croppedTensor = originalTensor.slice([y0,x0,0],[y1,x1,originalTensor.shape[2]])
        //         // originalTensor.dispose()
        //         // originalTensor = croppedTensor
        //         console.info(`HCrop ${x0},${y0}-${x1},${y1} (${newW}x${newH}) xPad:${xPad}`)


        //     } else if (tensorAR < cameraScreenshotSize.aspectRatio) {
        //         // too high - crop vertically
        //         const newH = Math.floor(originalTensor.shape[1] / cameraScreenshotSize.aspectRatio)
        //         const newW = originalTensor.shape[1]

        //         const x0 = 0  
        //         const x1 = x0 + newW
        //         const y0 = Math.floor(( originalTensor.shape[0] - newH ) /2)
        //         const y1 = y0 + newH

        //         newMsgArray.push(<>
        //                 <div>new wh: {newW}x{newH}</div>
        //                 <div>V crop {x0},{y0}-{x1},{y1}</div>
        //             </>)
        //         console.info(`VCrop ${x0},${y0}-${x1},${y1} (${newW}x${newH}`)

        //         croppedTensor = originalTensor.slice([y0,x0,0],[y1,x1,originalTensor.shape[2]])

        //     } else {
        //         newMsgArray.push(<>TF doesnt resize</>)
        //     }

        // }


        // normalise by /255 ?
        const normalized = (
            selectedModel.normalise 
            ? croppedTensor.div(tf.scalar(255.0)) 
            : croppedTensor
            );


        // expand
        const tensor = normalized.expandDims(0);

        console.log('tensor shape', tensor.shape.join('x'))

        console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)

        // tidy up originalTensor
        originalTensor.dispose()
        console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)
        croppedTensor.dispose()
        console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)

        // dispose of normalized
        normalized.dispose()
        console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)

        // boxes are in format y1 x1 y2 x2
        var windowTensor = tf.image.cropAndResize(tensor, [[0,0,1,1]], [0], [net.feedInputShapes[0][1],net.feedInputShapes[0][2]])

        // console.log('windowTensor2', windowTensor.arraySync())
        console.log('windowTensor shape', windowTensor.shape.join('x'))
        const prediction =  net.predict(windowTensor);
        console.log('prediction shape', prediction.shape.join('x'))
        const pred_mask = tf.argMax(prediction, -1)

        console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)
        prediction.dispose()
        windowTensor.dispose()
        console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)
        
        if (imageSrc) {
            // do extra processing to detect individual tubes.
            console.log("detecting tubes from imageSrc - webcam freeze!", imageSrc)

            console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)
            // const pixelPredictions = await pred_mask.array() //- while doing this, tensor is disposed
            const pixelPredictions = pred_mask.arraySync() // - unlike await pred_mask.array(), this doesnt dispose tensor.
            console.log("pixelPredictions",pixelPredictions)
            window.pixelPredictions = pixelPredictions
            console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)
            pred_mask.dispose()
            console.info(`IS TENSOR DISPOSED? ${tensor.isDisposed}`)

            return predictAndDrawTubes(pixelPredictions[0], tensor)
        }
        const pred_mask_array = pred_mask.dataSync()

        const myColours = [
                [Math.round(Math.random()*255), Math.round(Math.random()*255)  ,Math.round(Math.random()*255),  102],
                [0  ,  0,244, 198],
                [0  ,255,  0, 198]
            ]

        // draw to canvas.
        const numPixels = net.feedInputShapes[0][1] * net.feedInputShapes[0][2];

        const rgbVals = new Uint8ClampedArray(numPixels*4);

        const predictionCounts = {0:0, 1:0, 2:0}

        for (var x = 0; x < numPixels; x++) {
            var pixel_prediction = pred_mask_array[x]
            predictionCounts[pixel_prediction] += 1
            
            var rgba = myColours[pixel_prediction]
            rgbVals[4 * x + 0] = rgba[0]
            rgbVals[4 * x + 1] = rgba[1]
            rgbVals[4 * x + 2] = rgba[2]
            rgbVals[4 * x + 3] = rgba[3]
        }

        console.log(predictionCounts)

        newMsgArray.push(<div>0: {predictionCounts[0]} 1:{predictionCounts[1]} 2:{predictionCounts[2]}</div>)

        const maskImageData = new ImageData(rgbVals,net.feedInputShapes[0][1],net.feedInputShapes[0][2]);


        const ctx = canvasRef.current.getContext('2d')

        const window_wh_ratio = size.width / size.height

        // calculate canvas width + height to match the scaled video window.
        const constrained_by = (window_wh_ratio < frame_wh_ratio? "height":"width")

        const canvas_width = (constrained_by=='width' 
            ? size.width // constrained by height
            : size.height / frame_wh_ratio // constrained by width
            )
        const bitmap_height = (constrained_by=='width'
                ? size.width * frame_wh_ratio // constrained by height
                : size.height // constrained by width
                )

        console.log(`bitmap height constrained by ${constrained_by} bh:${bitmap_height} w:${size.width} h:${size.height}`)

        const canvas_height = size.height
            
        canvasRef.current.width = canvas_width
        canvasRef.current.height = canvas_height
        
        canvasRef.width = canvas_width
        canvasRef.height = canvas_height

        createImageBitmap(maskImageData).then(function(imgBitmap) {
            ctx.clearRect(0, 0, canvas_width, canvas_height);

            
            // // draw the bitmap frame?
            const bitmap_y = (window_wh_ratio > frame_wh_ratio 
                // ? (canvas_height - (canvas_width * video_wh_ratio))/2 // constrained by height
                ? (size.height - (size.width / frame_wh_ratio ))/2
                : 0 // constrained by width
                )

            console.info(`s.h: ${size.height} cH: ${canvas_height} bh: ${bitmap_height} bitmap_y: ${bitmap_y} frame_wh_ratio:${frame_wh_ratio}`)
            
            ctx.globalAlpha = 0.79
            ctx.drawImage(imgBitmap, 0, bitmap_y, canvas_width, bitmap_height);
        });

        setMsg(<div>{newMsgArray}</div>)
    }


















    const runTimer = () => {

        if(mode === 'video') {
            if (selectedVideo === null) {
                console.log('video in timer is undefined, stopping')
                clearTimeout(timer)
                return
            }
    
            if (videoRef.current.paused) {
                console.log("Video is paused, so final run")
    
                tf.tidy(() => {
                    processFrame()
                })
    
                return
            }
    
    
            timer = setTimeout(() => {
                tf.tidy(() => {
                    processFrame()    
                    setCount(prevCount => prevCount + 1)
                })
            }, interval_ms)
            return
        }

        if (mode === 'camera') {
            console.log(`timer - taking frame from camera ${(imageSrc ? 'frozen - using capture':'using live')}`)
            if (cameraRef === null) {
                console.warn('cameraRef is null, cant take screenshot')
                return
            }

            // is imageSrc set?
            if (imageSrc) {
                // frozen, use imageSrc.
                tf.tidy(() => {

                    // const myImg = <img src={imageSrc} width={size.width} height={size.height} alt="imageSrc" />
                    // processCameraFrame(myImg, size.width, size.height)  
                    // setCount(prevCount => prevCount + 1)  

                    window.imageSrc = imageSrc
                    window.processCameraFrame = processCameraFrame
                    window.tf = tf

                    // console.log(`screenshot has been captured - processing this at count ${count}`, imageSrc)

                    // const myCanvas = document.createElement('canvas')
                    // var context = myCanvas.getContext('2d')
                    // myCanvas.width = cameraScreenshotSize.width
                    // myCanvas.height = cameraScreenshotSize.height

                    // context.drawImage(imageSrc,0,0)


                    // const newImage = new Image()
                    // newImage.src = imageSrc
                    // newImage.width = cameraScreenshotSize.width
                    // newImage.height = cameraScreenshotSize.height

                    // window.newImage = newImage
                    // console.log(`screenshot has been captured - processing newImage count ${count}`, newImage)

                    // processCameraFrame(newImage)

                    console.log("processing screenshot using ", imageSrc)
                    processCameraFrame(imageSrc)
                })

            } else {
                // live camera, take a screenshot, use that, set timeout.
                timer = setTimeout(() => {
                    tf.tidy(() => {
                        if (!cameraRef?.current) {
                            console.warn('cameraRef current is not available')
                            return
                        }

                        // window.alert(`${cameraRef.current.props.width}x${cameraRef.current.props.height}`)
                        window.cr = cameraRef
                        // const newImageSrc = cameraRef.current.getScreenshot();
                        const newImageSrc = cameraRef.current.video;
                        console.log('Using live camera. newImageSrc=', newImageSrc)
                        processCameraFrame(newImageSrc) //, cameraRef.current.props.width, cameraRef.current.props.height)   
                        setCount(prevCount => prevCount + 1)
                        
                    })
                }, interval_ms)

            }



            return null
        }
        
    }

    useEffect(() => {
        if (net !== null) {
            runTimer()
        }
        
        return () => clearTimeout(timer)
    }, [net, count])

    useEffect(() => {
        console.info("Drawing predicted tubes", predictedTubes)
        window.predictedTubes = predictedTubes

        if (predictedTubes === null) {
            console.log("predictedTubes is null - returning.")
            return
        }

        const canvas = canvasRef.current

        window.canvas = canvas

        const canvas_width = canvas.width
        const canvas_height = canvas.height

        const ctx = canvas.getContext('2d')

        ctx.clearRect(0, 0, canvas_width, canvas_height);

        // if no predicted tubes, just print a msg and return.
        if (predictedTubes.tube_clusters.length === 0) {
            const text = `No predicted tubes  ${Date().toLocaleString()}`
            ctx.font = "12px Arial";
            ctx.fillStyle = "red";
            ctx.fillText(text, 10, 20)
            return
        }

        const text = `Found ${predictedTubes.tube_clusters.length} tubes  ${Date().toLocaleString()}`
        ctx.font = "14px Arial";
        ctx.fillStyle = "chartreuse";
        ctx.fillText(text, 10, 20)

        // draw the predicted tubes
        predictedTubes.tube_clusters.map((tube, tubeIndex) => { 
            annotateTube(
                tube, 
                tubeIndex, 
                ctx,
                {   
                    canvas_width: canvas_width, 
                    canvas_height: canvas_height,
                    model_height: predictedTubes.height, 
                    model_width: predictedTubes.width
                }
                ) 
            })



    }, [predictedTubes])

    const loadModel = (model) => {
        console.log('Loading model', model)
        setSelectedModel(model)
        handleCloseModels()
        setCount(0)
        
        const localKey = `indexeddb://net_${model.id}`;
        const modelUrl = MODEL_URL + "" + model.path + "/model.json";

        setModelStatus('loading')


        const ts_loading_start = performance.now();

        // define callback once net loaded
        const onModelLoadCallback = (net, saveToLocal) => {

            const ts_loading_end = performance.now();
            const duration_loading = ts_loading_end - ts_loading_start;
            console.log("loaded model in " + duration_loading + 'ms');
            setNet(net)
            window.net = net;
            
            // save net to localstorage
            if (saveToLocal) {
                console.log(`Saving model to ${localKey}`)
                net.save(localKey)
                .then(() => {
                    console.log(`saved to ${localKey}`)
                });
            }

            setModelStatus('ready');
        }


        tf.loadLayersModel(localKey)
            .then(net => {
                console.log("loaded model from " + localKey);
                onModelLoadCallback(net, false);
            })
            .catch(err => {
                console.log("not found - loading model from URL.")
                tf.loadLayersModel(modelUrl, {
                    onProgress: (e) => { console.warn('event', e) }
                }).then(net => {
                    onModelLoadCallback(net, true);
                })
            })



    }

    // load models once:
    useMemo(() => {
        console.log("Loading models")
         // load vision models
         axios.get(
            // url
            API_URL + "api/visionmodels",
            // data
            {},
            // extra stuff
            {
                headers: Object.assign(authHeader())
            }
        ).then((response) => {

            const myModels = response.data.filter((m,i) => m.projectId===1)

            console.log(`received ${myModels.length} models`, myModels)
            setModels(myModels);
        }).catch((err) => {
            console.warn("error", err)
        });

    }, [])

    const MyVideo = useMemo(() => {
        if (selectedVideo === null) {
            return null
        }
        return <video 
            width={size.width} 
            height={size.height} 
            ref={videoRef}
            autoPlay muted loop disablePictureInPicture playsInline 
            style={{zIndex: 400}}>
                <source src={`/demo_videos/${selectedVideo.filename}`} type='video/mp4' />
                Your browser doesnt support this video.
            </video>
    }, [selectedVideo, size])

    function calcCameraScreenshotSize(newDevice) {
        const device = (newDevice ? newDevice : selectedDevice)

        if (device === null) {
            console.warn('device is null')
            return
        }

        const deviceMaxHeight = (device?.capabilities?.height?.max ? device.capabilities.height.max : size.height)
        const deviceMaxWidth = (device?.capabilities?.width?.max ? device.capabilities.width.max : size.width)

        const viewport_aspect_ratio = size.width/size.height
        const device_aspect_ratio = deviceMaxWidth / deviceMaxHeight

        const crop_direction =  (device_aspect_ratio > viewport_aspect_ratio ? 'horizontal': 'vertical')

        var width  = (crop_direction === 'horizontal' ? Math.round(deviceMaxHeight * viewport_aspect_ratio) : deviceMaxWidth)
        var height = (crop_direction === 'horizontal' ? deviceMaxHeight : Math.round(deviceMaxWidth / viewport_aspect_ratio ))


        // okay, swap rotation if camera is in portrait mode.
        const isRotated = (height > width)
        if (isRotated) {
            const temporary = height*1
            height = width
            width = temporary
        }


        console.log(`crop_direction: ${crop_direction} \nwh:${width}x${height} dm:${deviceMaxWidth}x${deviceMaxHeight} s:${size.width}x${size.height}`)


        setCameraSceenshotSize({
            width: width, 
            height: height, 
            crop:(crop_direction?'horizontal':'vertical'), 
            aspectRatio:(width/height),
            isRotated: isRotated
        })
    }

    // calculate camera screenshot sizes.
    useMemo(() => {
        calcCameraScreenshotSize()
    },[selectedDevice, size])
    
    const MyCamera = useMemo(() => {
        if (selectedDevice === null) {
            return null
        }

        if (imageSrc) {
            return <div style={{
                width: size.width,
                height: size.height,
                // backgroundColor: 'red',
                backgroundImage: `url(${imageSrc.src})`,
                // backgroundSize: (cameraScreenshotSize.crop==='vertical' ? '100% auto': 'auto 100%'),
                // backgroundSize: 'auto auto',
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
            }} />
        }

        return  <Webcam ref={cameraRef}
                    height={size.height} 
                    width={size.width}
                    // width={cameraScreenshotSize.width}
                    // height={cameraScreenshotSize.height}
                    
                    videoConstraints={{ 
                        deviceId: selectedDevice.deviceId,
                        facingMode: selectedDevice.facingMode,
                        width: {min: 224, ideal: cameraScreenshotSize.width},
                        height: {min: 224, ideal: cameraScreenshotSize.height},
                        // // width:size.width, 
                        // // height:size.height, 
                        // // aspectRatio: size.width/size.height,
                        // // aspectRatio: cameraScreenshotSize.aspectRatio,
                        // // width: {min: 300, ideal: cameraScreenshotSize.width},
                        // // height: {min: 333, ideal: cameraScreenshotSize.height},
                        // // width: { ideal: cameraScreenshotSize.width},
                        // // height: {ideal: cameraScreenshotSize.height},
                        // // width: { selectedDevice.videoConstraints.  }
                        // // resizeMode: 'crop-and-scale',
                    }}
                    style={{
                        objectFit: 'cover',
                        objectPosition: 'center center'
                    }}
                />

    }, [selectedDevice, size, imageSrc])

    // if imageSrc is set, then do some extra processing and calculate tubes.
    useMemo(() => {
        if (imageSrc === null) {
            return
        }

        // rdis
        console.info("finding tubes from imageSrc...")
        runTimer()


    },[imageSrc])
    
    function FileListOptions(props) {

        let elements = props.videoFiles.map( (v, i) => {
            return <ListGroup.Item action onClick={() => loadVideo(v) } key={`file_${i}`}>
                {i+1}. {v.filename} <span style={{color: 'grey', paddingLeft: 20}}>{v.description}</span>
            </ListGroup.Item>
        })


        if (props.camera && devices.length > 0) {

            console.log('devices', devices)

            elements = [
                devices.map((d, i) => {

                    console.log(`device ${i}`, d)

                    return <ListGroup.Item action onClick={() => {
                        showCamera(d)
                    }} key={`camera_${i}`}>
                        <CameraVideo /> <span style={{paddingLeft: 20}}>{d.label}</span>
                        &nbsp;<span style={{color: 'grey', paddingLeft:'1em'}}>
                            {/* {d?.capabilities?.facingMode[0]} */}
                        &nbsp;({d?.capabilities?.width?.max} x {d?.capabilities?.height?.max})</span>
                    </ListGroup.Item>
                }),

                elements

            ]
        }

        // fallback for iOS or any other device instance whereby the camera isn't detected - instead rely on "front" and "rear" option.
        if (props.camera) { // && devices.length == 0) {
            elements = [
                // devices.map((d, i) => {

                //     console.log(`device ${i}`, d)

                //     return <ListGroup.Item action onClick={() => {
                //         showCamera(d)
                //     }} key={`camera_${i}`}>
                //         <CameraVideo /> <span style={{paddingLeft: 20}}>{d.label}</span>
                //         &nbsp;<span style={{color: 'grey', paddingLeft:'1em'}}>
                //             {/* {d?.capabilities?.facingMode[0]} */}
                //         &nbsp;({d?.capabilities?.width?.max} x {d?.capabilities?.height?.max})</span>
                //     </ListGroup.Item>
                // }),

                // front
                <ListGroup.Item action onClick={() => {
                    showCamera({ facingMode: 'user' })
                }} key={`camera_front`}>
                    <CameraVideoFill /> <span style={{paddingLeft: 20}}>Front camera</span>
                    &nbsp;<span style={{color: 'grey', paddingLeft:'1em'}}>
                    &nbsp;(generic)</span>
                </ListGroup.Item>,

                // rear
                <ListGroup.Item action onClick={() => {
                    showCamera({ facingMode: 'environment' })
                }} key={`camera_rear`}>
                    <CameraVideoFill /> <span style={{paddingLeft: 20}}>Rear camera</span>
                    &nbsp;<span style={{color: 'grey', paddingLeft:'1em'}}>
                    &nbsp;(generic)</span>
                </ListGroup.Item>,

                elements

            ]
        }



        return <ListGroup>
            
            { elements }

            
        </ListGroup>
    }
    
    function ModelListOptions(props) {
        console.log("model list options", props)

        return <ListGroup>
            { props.models.map( (m, i) => {
                return <ListGroup.Item action onClick={() => loadModel(m) } key={`model_${i}`}>
                    {i+1}. {m.modelname} <span style={{color: 'grey', paddingLeft: 20}}>{filesize(m.filesize_bytes, {round: 1})}</span>
                </ListGroup.Item>
            })}
        </ListGroup>
    }

    function VideoMeta(props) {

        if (props.selectedVideo) {
            return <div style={props.style}>
                <div>
                    <b>{selectedVideo.filename}</b>
                </div>
                <span>{selectedVideo.description}<br/>{filesize(selectedVideo.filesize,{round:1})}</span>
            </div>
        }
        if (props.selectedDevice) {
            return <div style={props.style}>
                <div>
                    <CameraVideo /> {selectedDevice.label}
                </div>
                <div>W:{cameraScreenshotSize.width} H:{cameraScreenshotSize.height}</div>
                <div>{cameraScreenshotSize.crop}</div>
                <div>{cameraScreenshotSize.aspectRatio}</div>
            </div>
        }
        return <div style={props.style}>No video selected</div>
    }
    
    function ModelMeta(props) {
        if (props.selectedModel === null) {
            return <div style={props.style}>No model selected</div>
        }

        return <div style={props.style}>
            {modelStatus === 'loading' ? <Spinner /> : null}
            <div>
                <b>{props.selectedModel.modelname}</b>
            </div>
            <span>{filesize(props.selectedModel.filesize_bytes,{round:1})}</span>
            <div>{props.count}</div>
            <>{isSafari ? "Safari" : "Not safari"}</>
        </div>
    }

    
    function SizeMeta(props) {
        return <div style={props.style}>
            W: {size.width}<br />
            H: {size.height}<br />
            {size.width / size.height}
        </div>
    }

    function goAnnotate() {
        console.log("annotate called",predictedTubes)

        const annotation_data = {
            from:'demo_video',
            // source: {
            //     source: 'video',
            //     video: selectedVideo,
            //     videoRef: videoRef.current,
            //     timestamp: videoRef.current.currentTime,
            //     duration:  videoRef.current.duration,
            // },
            model: selectedModel,
            prediction: {
                    height: predictedTubes.height,
                    width: predictedTubes.width,
                    tubes: predictedTubes.tube_clusters,
                    tips:  predictedTubes.tip_clusters,
                },
            
        }

        if (videoRef.current !== null) {
            annotation_data['source'] = {
                source: 'video',
                video: selectedVideo,
                videoRef: videoRef.current,
                timestamp: videoRef.current.currentTime,
                duration:  videoRef.current.duration,
            }
            annotation_data['image'] = bitmap
            annotation_data['tubes'] = predictedTubes.tube_clusters.map((t,i) => {
                const left = t.box[0] * bitmap.width
                const top = t.box[1] * bitmap.height
                const width = (t.box[2] - t.box[0]) * bitmap.width
                const height = (t.box[3] - t.box[1]) * bitmap.height
    
                // too small to be a tube
                if (width < 15 | height < 15) {
                    return null
                }
                
                t.dimensions = {
                    left: left,
                    top: top,
                    width: width,
                    height: height
                }
    
                t.status = 'from_model'
                t.result = (t.hq_prediction.likelyPositive > .3 ? 'predict-positive' : 'predict-negative')
    
                return t
            })
        } else {
            window.imageSrc = imageSrc
            annotation_data['source'] = {
                source: 'camera',
                image: imageSrc
            }
            annotation_data['image'] = imageSrc
            annotation_data['tubes'] = predictedTubes.tube_clusters.map((t,i) => {
                const left = t.box[0] * imageSrc.width
                const top = t.box[1] * imageSrc.height
                const width = (t.box[2] - t.box[0]) * imageSrc.width
                const height = (t.box[3] - t.box[1]) * imageSrc.height
    
                // too small to be a tube
                if (width < 15 | height < 15) {
                    return null
                }
                
                t.dimensions = {
                    left: left,
                    top: top,
                    width: width,
                    height: height
                }
    
                t.status = 'from_model'
                t.result = (t.hq_prediction.likelyPositive > .3 ? 'predict-positive' : 'predict-negative')
    
                return t
            })
        }


        console.log('annotation data', annotation_data)
        setAnnotation(annotation_data)

        console.log('pushed')
    }


    const annotation_ui = <GloAnnotate annotation={annotation} />
    const video_ui = <div>        
            <Modal show={showFileList}>
                <Modal.Header closeButton onHide={handleCloseFiles}>
                    <Modal.Title>Select video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileListOptions videoFiles={videoFiles} camera={true} />
                </Modal.Body>
            </Modal>

            <Modal show={showModelList}>
                <Modal.Header closeButton onHide={handleCloseModels}>
                    <Modal.Title>Select model</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModelListOptions models={models} />
                </Modal.Body>
            </Modal>
        
            <div style={{
                position: 'fixed', 
                overflow: 'hidden', 
                width: size.width, 
                height: size.height, 
                backgroundColor: '#000000FF'
                }} className='fluid'>

            <div style={{position:'absolute', right: 0 }}>
                <div style={{position:'absolute', right: 0, textAlign:'right', fontSize: '.7em', zIndex: 700}}>
                    <div>
                        <VideoMeta selectedVideo={selectedVideo} selectedDevice={selectedDevice} style={{backgroundColor:'#FFFF99CC', display: 'inline-block', padding: '2px 10px 0px 10px'}} />
                    </div>
                    <div>
                        <ModelMeta selectedModel={selectedModel} count={count} style={{backgroundColor:'#CC99FFCC', display: 'inline-block', padding: '2px 10px 0px 10px'}}/>
                    </div>
                    <div>
                        <SizeMeta style={{backgroundColor:'#CC9966CC', display: 'inline-block', padding: '2px 10px 0px 10px'}}/>
                    </div>
                    <div style={{backgroundColor:'#7799CCCC', display: 'inline-block', padding: '2px 10px 0px 10px'}}>{msg}</div>
                </div>
                {MyVideo}
                {MyCamera}
            </div>

            
            

            <canvas ref={canvasRef}
                style = {{
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                left: 0,
                right: 0,
                textAlign:"center",
                zIndex: 600,
                // width: size.width,
                // height:size.height,
                }}
            />
            <div style={{ position: 'absolute', top: '1%', left: '4%', zIndex: 900 }}>
                <Link className="glo display-1" style={{fontSize: '36px'}} to='/'>GLO</Link>
            </div>
            <Row className='videoRow' 
                style={{ 
                    position: 'absolute', 
                    bottom: '0%', 
                    left: '0%',
                    width: '100%', padding: '10px 0', backgroundColor: '#00000066', zIndex:2000 }}>
                <Col>
                    <Button className='rounded-pill' onClick={handleShowFiles} ><CameraVideo /> Select video</Button>
                </Col>
                <Col style={{textAlign:'center'}}>
                    {
                        ( videoRef?.current?.paused 
                          ? <Button className='rounded-pill' onClick={handleCapture} ><Play /> Play</Button>
                          : <Button className='rounded-pill' onClick={handleCapture} ><CameraFill /> Capture</Button>

                        )
                    }
                    
                </Col>
                {
                    (
                        predictedTubes !== null
                        ? <Col style={{textAlign:'center'}}>
                            <Button className='rounded-pill' onClick={goAnnotate} >
                                Continue <ArrowRight />
                            </Button>
                          </Col>
                        : null
                    )
                }
                <Col style={{textAlign:'right'}}>
                {   
                    ( models === null 
                        ? "-" 
                        : <Button className='rounded-pill' onClick={handleShowModels} ><Lightbulb /> Select Model</Button>) 
                }
                </Col>
            </Row>
        </div>
    </div>

    // },[annotation, MyVideo, goAnnotate, handleCapture, handleCloseFiles, handleShowFiles, models, predictedTubes, selectedModel, selectedVideo, showFileList, showModelList, size, videoFiles])
    
    return (annotation === null
            ? video_ui
            : annotation_ui)
}

export default GloVideoDemo