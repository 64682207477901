import { useEffect, useMemo, useRef, useState } from "react";
import Webcam from "react-webcam";

function randomColour() {
    return "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
}

function DemoOnnx() {
    const canvasRef = useRef()
    const preprocessingCanvasRef = useRef()
    const webcamRef = useRef()
    const [statusMessage, setStatusMessage] = useState("no status")
    const [ticker, setTicker] = useState(0)
    const [image_src_for_model, setImageSrcForModel] = useState(null)
    const [image_element, setImageElement] = useState(null)
    const [preprocessedDataURL, setPreprocessedDataURL] = useState(null)

    useMemo(() => {
        // console.log("image_src_for_model is updated")//, image_src_for_model)

        if (! image_src_for_model) {
            console.warn('image_src_for_model is false')
            return
        }

        // const ctx = preprocessingCanvasRef.current.getContext('2d')
        var canvas_temp = document.createElement("canvas");
        canvas_temp.width=224
        canvas_temp.height=224

        const ctx = canvas_temp.getContext('2d')

        if (! image_src_for_model) {
            console.warn("no image_src_for_model")
        }

        // const image_element = <img src={image_src_for_model} width={224} height={224} />
        const image_element = new Image()
        image_element.width=224
        image_element.height=224
        image_element.src = image_src_for_model

        // loading the image src isn't guranteed instantaneous, so continue via this onload:
        image_element.onload = (i) => {
            // console.info('ie', image_element)
            ctx.drawImage(image_element, 0, 0, 224, 224)

            let imgData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
            let pixels = imgData.data;
            for (var i = 0; i < pixels.length; i += 4) {
          
            //   let lightness = parseInt((pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3);
            // let lightness = parseInt(pixels[i]*.299 + pixels[i + 1]*.587 + pixels[i + 2]*.114); // wikipedia lightness calc (luma)
            let lightness = 0.2126 * pixels[i] + 0.715 * pixels[i+1] + 0.0722 * pixels[i+2]; // wikipedia linear luminance
          
              pixels[i] = lightness;
              pixels[i + 1] = lightness;
              pixels[i + 2] = lightness;
            }
            ctx.putImageData(imgData, 0, 0);
            const dataURL = canvas_temp.toDataURL("image/jpeg", 0.9)

            setPreprocessedDataURL(dataURL)
        }
    },[image_src_for_model])


    useMemo(() => {
        const timeout = setTimeout(() => {
            // console.log(`increasing ticker`)
            setTicker(ticker+1)

            if ( ! (
                typeof webcamRef.current !== "undefined" &&
                webcamRef.current !== null &&
                webcamRef.current.video.readyState === 4
              )) {
                setStatusMessage(`Webcam not ready ${ticker}`)
                return
            }

            const screenshot = webcamRef.current.getScreenshot()
            setImageSrcForModel(screenshot)
            
            setStatusMessage(`Ticker @ ${ticker}`)
        }, 2500)

    },[ticker])

    function Status({message}) {
        return <div>{message}</div>
    }

    function PreprocessedImg({src, alt}) {
        if (! src) {
            return null
        }
        return <div>
                <img src={src} alt={alt} />
                <div>{alt}</div>
            </div>
    }

    return <div>
        <Status message={statusMessage}/>

        <div style={{
            position:'relative',
            border: "10px solid hotpink",
        }}>
            <Webcam ref={webcamRef}
              muted={true} 
                //  height={size.height} 
                //  width={size.width}
                // height={880}
                // width={640}
             videoConstraints={{
                // facingMode: 'user', 
                facingMode: 'environment', 
                // aspectRatio: ratio
                // aspectRatio:1.375,
                height: 500,
                width: 500
            }}
             style={{
                float: 'right',
                // objectFit: 'cover',
                // objectPosition: 'center center'
                // position: "absolute",
                // marginLeft: "auto",
                // marginRight: "auto",
                // left: 0,
                // right: 0,
                textAlign: "center",
                zindex: 9,
                border:'1px solid orange'
              }}
        />

        {/* <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 8,
            width: 640,
            height: 680,
          }}
        /> */}
    
    </div>
    <div style={{
        border: "1px solid gold",
        }}>
            <PreprocessedImg src={preprocessedDataURL} alt='preprocessed' />
            {/* <PreprocessedImg src={image_src_for_model} alt='original' /> */}
    </div>
    </div>
}

export default DemoOnnx;