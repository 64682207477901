// see https://www.bezkoder.com/react-jwt-auth/

import { PersonCircle, CodeSquare } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import AuthService from "./services/auth.service";

import "./register.css";

const API_URL = "http://localhost:8080/api/auth/";


export default function Logout() {
  const currentUser = AuthService.getCurrentUser();

  if (! currentUser) {
    return(
        <div className="container">
            <h1>You're not logged in.</h1>
            <Link to="/">Return to homepage</Link>
        </div>
    )
  }

  const handleLogout = function() {
    console.log("handleLoout called, removing user from localstore.")
    localStorage.removeItem("user");
  }

  return (
    <div className="container">
      <h1 className="display-1">Sign out</h1>

      <form onSubmit={handleLogout}>
        <p>Currently signed in as {currentUser.forename} {currentUser.surname}.</p>
        <p>Are you sure you want to sign out?</p>
        <button className="btn btn-primary">Sign out</button>
      </form>
      <Link to="/" className='py-2'>Return to homepage</Link>
    </div>
  );
}
