// import { BodyPix } from "@tensorflow-models/body-pix";
import * as bodyPix from "@tensorflow-models/body-pix"
import Webcam from "react-webcam";
import { useMemo, useRef, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { Camera, CameraFill, Hypnotize, Repeat, Lightbulb } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";


const BodyPixDemo = () => {

    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    const [mode, setSwitchMode] = useState('pixels')
    const [myInterval, setMyInterval] = useState(null)

    const size = useWindowSize();

    const isLandscape = size.height <= size.width;
    const ratio = size.width/size.height;


    const runBodysegment = async () => {
        const net = await bodyPix.load();
        console.log("bodypix model loaded")
    
        if (myInterval !== null) {
            clearInterval(myInterval)
        }

        setMyInterval(
            setInterval(() => {

                try{
                    detect(net, mode)
                    console.log("done")
                } catch (error) {
                    console.warn("error", error)
                }

            }, 300)
        )
    }

    const switchMode = () => {
        const newMode = (mode==='mask'?'pixels':'mask')
        setSwitchMode(newMode)
    }






      
    const detect = async (net, myMode) => {
        // check data is available
        if (! ( typeof webcamRef.current !== "undefined" &&
                webcamRef.current !== null &&
                webcamRef.current.video.readyState === 4)
        ) {
            // console.warn("not ready")
            return
        }

        // // get video properties
        const video = webcamRef.current.video;
        const videoHeight = video.videoHeight;
        const videoWidth = video.videoWidth


        // make detections
        const person = await net.segmentPersonParts(video); // segmentMultiPersonParts, segmentPerson...
        console.log('person', person)


        const rainbow = [
            [110, 64, 170], [143, 61, 178], [178, 60, 178], [210, 62, 167],
            [238, 67, 149], [255, 78, 125], [255, 94, 99],  [255, 115, 75],
            [255, 140, 56], [239, 167, 47], [217, 194, 49], [194, 219, 64],
            [175, 240, 91], [135, 245, 87], [96, 247, 96],  [64, 243, 115],
            [40, 234, 141], [28, 219, 169], [26, 199, 194], [33, 176, 213],
            [47, 150, 224], [65, 125, 224], [84, 101, 214], [99, 81, 195]
          ];

        // const warm = [
        //     [110, 64, 170], [106, 72, 183], [100, 81, 196], [92, 91, 206],
        //     [84, 101, 214], [75, 113, 221], [66, 125, 224], [56, 138, 226],
        //     [48, 150, 224], [40, 163, 220], [33, 176, 214], [29, 188, 205],
        //     [26, 199, 194], [26, 210, 182], [28, 219, 169], [33, 227, 155],
        //     [41, 234, 141], [51, 240, 128], [64, 243, 116], [79, 246, 105],
        //     [96, 247, 97],  [115, 246, 91], [134, 245, 88], [155, 243, 88]
        //   ];

        // draw detections
        const colouredPartImage = bodyPix.toColoredPartMask(person, rainbow)

        if (myMode === 'mask') {
            const ctx = canvasRef.current.getContext('2d')

            
            if (canvasRef.current.width != videoWidth | canvasRef.current.height != videoHeight) {
                canvasRef.current.width = videoWidth
                canvasRef.current.height = videoHeight
            }

            createImageBitmap(colouredPartImage).then(function(imgBitmap) {
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                ctx.globalAlpha = 0.7
                ctx.drawImage(imgBitmap, 0, 0, canvasRef.current.width, canvasRef.current.height);
            });
    
            canvasRef.current.globalAlpha = 0.4
        } else if (myMode === 'pixels') {
            // drawPixelatedMask works well :-)
            bodyPix.drawPixelatedMask(
                canvasRef.current,
                video,
                // null,
                colouredPartImage,
                0.7, // mask opacity
                0, // mask blur amount
                false,
                4
            )
        }

    }

    useMemo(() => {
        runBodysegment();
    },[mode])

    


    return <div style={{position: 'fixed', overflow: 'hidden', width: size.width}} className='fluid'>
        <Webcam ref={webcamRef}
             height={size.height} 
             width={size.width}
             videoConstraints={{facingMode: 'user', aspectRatio: ratio}}
             style={{
                objectFit: 'cover',
                objectPosition: 'center center'
             }}
        />
        <canvas ref={canvasRef}
            style = {{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign:"center",
            zIndex:10,
            width: size.width,
            height:size.height,
            }}
        />
        <div style={{ position: 'absolute', top: '1%', left: '4%', zIndex: 19999 }}>
            <h1 className="glo display-1" style={{fontSize: '36px'}}>GLO</h1>
        </div>
        <div
          className='row'
          style={{ position: 'absolute', bottom: '0%', left: '0%', width: '100%', padding: '10px 0', backgroundColor: '#00000066', zIndex:2000 }}>
            <div className='col-4'>
                <Button className='rounded-pill' onClick={switchMode}><Repeat /> Use {(mode==='mask'?'pixels':'mask')}</Button>
            </div>
            <div className='col-4' style={{textAlign:'center'}}>
                <Button className='rounded-pill' ><CameraFill /> Capture</Button>
            </div>
            <div className='col-4' style={{textAlign:'right'}}>
                <Button className='rounded-pill' ><Lightbulb /> Backlight</Button>
            </div>
        </div>
        

    </div>
}

export default BodyPixDemo;