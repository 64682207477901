export const annotateTube = (tube, tubeIndex, ctx, dimensions) => {
    // window.ctx = ctx
    // console.info("ctx",ctx)
    console.info("dimensions",dimensions)
    console.log(`Annotating tube ${tubeIndex}`, tube)

    // const { canvas_width, canvas_height } = ctx.getBoundingClientRect();

    // console.log(`cw:${canvas_width} h:${canvas_width}`)

    // ctx.fillStyle = "aqua";
    // ctx.fillRect(0, 0, 250, 100)

    
    // ctx.resetTransform()


    // a	Scales the drawing horizontally	
    // b	Skew the the drawing horizontally	
    // c	Skew the the drawing vertically	
    // d	Scales the drawing vertically	
    // e	Moves the the drawing horizontally	
    // f	Moves the the drawing vertically

    // ctx.transform(1, 0.5, -0.5, 1, 30, 10);
    // ctx.fillStyle = "#ff00ff22";
    // ctx.fillRect(0, 0, 250, 100);
    // ctx.resetTransform()

    // ctx.transform()
    const xScale = dimensions.canvas_width / dimensions.model_width
    const yScale = dimensions.canvas_height / dimensions.model_height

    // console.log(`xScale: ${xScale} yScale: ${yScale}`)

    // ctx.transform(xScale, 0, 0, yScale, 0, 0)
    
    // ctx.fillStyle="#ffffff99"
    // ctx.strokeStyle = "#FFFFFFCC";
    ctx.lineWidth = 2


    const x1 = tube.box_int[0] * xScale
    const y1 = tube.box_int[1] * yScale
    const x2 = tube.box_int[2] * xScale
    const w  = x2-x1
    const y2 = tube.box_int[3] * yScale
    const h  = y2-y1


    console.log(`tube${tubeIndex}  (${x1},${y1}) - (${x2},${y2})`, tube)
    window.tube = tube

    
    ctx.strokeStyle = "#FF0000FF";
    ctx.strokeRect(x1,y1,w,h);
    // ctx.strokeRect(x1, y1, x2-x1, y2-y1);

    if ( ! tube?.hq_prediction?.mask_image ) {
        console.warn('no tube.hq_prediction.mask_image - drawing box')
        // ctx.strokeRect(tube.box_int[0], tube.box_int[1], tube.box_int[2]-tube.box_int[0], tube.box_int[3]-tube.box_int[1]);
        
        return
    }
    else 
    {
        // -- paint hq pixels

        createImageBitmap(tube.hq_prediction.mask_image).then(function(imgBitmap) {

                const padding = 0.02
            
                ctx.globalAlpha = 0.99
                // ctx.drawImage(imgBitmap, x1, y1, w, h);
                ctx.drawImage(
                    imgBitmap, 
                    x1-dimensions.canvas_width*(padding), 
                    y1-(dimensions.canvas_height*padding), 
                    w + dimensions.canvas_width*(padding*2), 
                    h + (dimensions.canvas_height * padding*2)
                    );


                ctx.strokeStyle = "#FF0000FF";
                ctx.strokeRect(x1,y1,w,h);



                ctx.fillStyle = "#FFFFFF";
                ctx.font = "40px sans-serif";
                ctx.textBaseline = 'middle'
                ctx.fillText(`${tubeIndex+1}`, x1+w/2, y1+h/2);

            })
        }
    


        



    // ctx.strokeRect(tube.box_int[0], tube.box_int[1], tube.box_int[2]-tube.box_int[0], tube.box_int[3]-tube.box_int[1]);
    // ctx.resetTransform()

}