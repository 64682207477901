// batch v2
import { Component } from "react";
import AuthService from "./services/auth.service";
import { Link, useParams } from "react-router-dom";
import { useState, useRef, useMemo } from "react";
import { Trash } from "react-bootstrap-icons";
import axios from "axios";
import { API_URL } from "../config/api";
import { useEffect } from "react";
import { FileImage, Save } from "react-bootstrap-icons";
import {filesize} from "filesize";
import authHeader from "./services/auth-header"
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';

import * as tf from "@tensorflow/tfjs"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getImagePromise } from "./helpers/getImagePromise";
import maskToTubes from "./helpers/maskToTubes";
import combineMasks from "./helpers/combineMasks";
import predictMask from "./helpers/predictMask";
import radians_to_degrees from "./helpers/radiansToDegrees";

import Canvas from "./helpers/canvasHelpers";
import { CanvasOverlay } from "./helpers/canvasOverlay";
import { makeArray } from "./helpers/makeArray";

import { predictOnPotentialTubes } from "./helpers/predictOnPotentialTubes";
import { KMeansAnalysis } from "./helpers/kmeansAnalysis";

export default function Batch2() {


    const currentUser = AuthService.getCurrentUser();
    const {id} = useParams();
    const hiddenFileInput = useRef(null);
    // const canvasOverlay = useRef();

    const [currentModel, setCurrentModel] = useState(undefined);
    const [net, setNet] = useState(undefined);
    const [numWindows, setNumWindows] = useState(1);
    const [originalImage, setOriginalImage] = useState(undefined);
    const [predictedMask, setPredictedMask] = useState(null);
    const [predictedTubes, setPredictedTubes] = useState(null);
    const [protocols, setProtocols] = useState([]);
    const [preview, setPreview] = useState();
    const [progress, setProgress] = useState(0);
    const [projects, setProjects] = useState([]);
    const [selectedProtocolId, setSelectedProtocolId] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [status, setStatus] = useState('idle');
    const [visionmodels, setVisionmodels] = useState([]);

    const [canvasWidth, setCanvasWidth] = useState(730);
    const [canvasHeight, setCanvasHeight] = useState(1000);

    const [batchMetadata, setBatchMetadata] = useState({})

    const navigate = useNavigate();


    function getBatch() {
        axios
            .post(
                API_URL +'api/batch/' + id,
                [],
                { 
                    headers: Object.assign(authHeader())
                }    
            )
            .then(function (response) {
                console.log("batch info:")
                console.info(response.data);

                const batch = response.data.batch;

                if (batch.protocolId === null) {
                    batch.protocolId = "";
                }
                // setcolumns

                // setColumns(batch.columns);
                setSelectedProtocolId(batch.protocolId);
                setSelectedProjectId(batch.projectId);
                setBatchMetadata(batch);

                if (batch.images.length > 0) {
                    console.log("Setting setSelectedFile to latest image:")
                    const latest_image = batch.images[batch.images.length-1];
                    latest_image.last_modified_str = new Date(latest_image.last_modified).toString()
                    setSelectedFile(latest_image)
                }
            })
            .catch(function (err) {
                //handle error
                console.warn(err);
                setStatus('idle');
            });

    };

    // pop effects here, these are triggered at launch or when another dependency changes
    useEffect(() => { 


        // get projects
        axios.get(
            // url
            API_URL + "api/projects",
            // params
            {
                headers: Object.assign(authHeader())
            }
        ).then((response) => {
            setProjects(response.data.projects);
        });

        // load vision models
        axios.get(
            // url
            API_URL + "api/visionmodels",
            // data
            {},
            // extra stuff
            {
                headers: Object.assign(authHeader())
            }
        ).then((response) => {
            setVisionmodels(response.data);
        });



        if (id == 'new') {
            return;
        }

        getBatch(); },[])

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        // exiting file = serve thumbnail
        if (selectedFile.id) {
            setPreview(API_URL + "images/preview/" + selectedFile.userId + "/" + selectedFile.id)
        } else {
            // new file, temporarily stored locally.
            const objectUrl = URL.createObjectURL(selectedFile)
            setPreview(objectUrl)
    
            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [selectedFile])

    // calculate canvasWidth, canvasHeight
    useEffect(() => {
        // set defaults, these will be updated below.
        const default_width = 730;
        const default_height = 1000;

        if (originalImage === undefined) {
            console.log("no change to default width")
            
            setCanvasWidth(default_width)
            setCanvasHeight(default_height)
            return;
        }
      
        // calc some padding?
        const natural_wh_ratio = originalImage.naturalWidth / originalImage.naturalHeight;
        const canvas_wh_ratio = default_width / default_height;

        var new_height;
        var new_width;

        if (natural_wh_ratio <= canvas_wh_ratio) {
            console.log("pad x");
            new_height = default_height;
            new_width = default_height * natural_wh_ratio;
            console.log(`newh:${new_height} neww:${new_width}`)
        } else {
            // pad h
            console.log("pad h")
            console.log(`cw:${default_width} whr${natural_wh_ratio}`)
            new_width = default_width;
            new_height = default_width / natural_wh_ratio;
            console.log(`newh:${new_height} neww:${new_width}`)
        }

        setCanvasWidth(new_width)
        setCanvasHeight(new_height)



    }, [originalImage])

    // On file select (from the pop up)
    function onFileChange(event) {
        const newFile = event.target.files[0];

        // check selected file is an image..
        if (newFile.type !== undefined && newFile.type.substr(0,6) != "image/") {
            console.log("selected file is not an image");
            setSelectedFile(undefined);
            return;
        }

        // set a friendly string for last_modified, this is only used to display
        if (newFile.lastModified !== undefined) {
            console.info(newFile)
            console.info(new Date(newFile.lastModified))
            newFile.last_modified_str = new Date(newFile.lastModified).toString()
        } else if (newFile.lastModifiedDate !== undefined) {
            newFile.last_modified_str = newFile.lastModifiedDate;
        } else {
            newFile.last_modified_str = new Date(Date.now());
        }

        // Update the state
        setSelectedFile(newFile);        
    };
    
    // On file upload (click the upload button)
    // if we switch to a component, we should place inside componentDidMount - see https://www.bezkoder.com/react-file-upload-axios/
    function useBatchUpload(event) {
        setStatus('uploading');

        const formData = new FormData();
        // formData.append('file', this.file);

        formData.append('batchId',id);
        // formData.append('columns',JSON.stringify(columns));
        formData.append('selectedProtocol', selectedProtocolId);
        
        formData.append('selectedProject', selectedProjectId);

        
        if (selectedFile !== undefined) {
            formData.append('fileMetadata', JSON.stringify(selectedFile, ['name','lastModified','lastModifiedDate','size']));
            formData.append('imageId', 'new')
            formData.append('image', selectedFile);
        }

        axios.post(
            // method: 'post',
            API_URL + "api/batch",
            formData,
            { 
                headers: Object.assign(authHeader()),
                onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

                    if (percentCompleted !== progress) {
                        setProgress(percentCompleted);
                        console.log(percentCompleted + '%');
                    }
                  }
            }
        )
        .then(
                function (response) {
                    //handle success
                    setStatus('idle');
                    const redirect_url = '/batch/' + response.data.batch.id
                    navigate(redirect_url);
                }
        )
        .catch(function (response) {
            //handle error
            console.warn(response);
            setStatus('error');
        });
    };

    // File content to be displayed after
    // file upload is complete
    function fileData() {

        if (selectedFile !== undefined) {
            
          return (
            <div className='row'>
                <div className='col col-md9'>
                    <h2>File Details:</h2>
                    <p>File Name: {selectedFile.name}</p>
            
                    <p>File Type: {selectedFile.type}</p>

                    <p>
                        Last Modified:{" "}
                        {selectedFile.last_modified_str}
                    </p>
                    <p>
                        File size:{" "}
                        {selectedFile.filesize_human ? selectedFile.filesize_human : filesize(selectedFile.size, {round:1})}
                    </p>
                </div>
                <div className='col col-md3'>
                    {selectedFile &&  <img src={preview} style={{maxWidth: "100%", maxHeight: "300px"}} alt={`Preview of ${selectedFile.name}`} /> }
                </div>
    
            </div>
          );
        } else {
          return (
            undefined
          );
        }
    };

    // On file select (from the pop up)
    function onFileChange(event) {
        const newFile = event.target.files[0];

        // check selected file is an image..
        if (newFile.type !== undefined && newFile.type.substr(0,6) != "image/") {
            console.log("selected file is not an image");
            setSelectedFile(undefined);
            return;
        }

        // set a friendly string for last_modified, this is only used to display
        if (newFile.lastModified !== undefined) {
            console.info(newFile)
            console.info(new Date(newFile.lastModified))
            newFile.last_modified_str = new Date(newFile.lastModified).toString()
        } else if (newFile.lastModifiedDate !== undefined) {
            newFile.last_modified_str = newFile.lastModifiedDate;
        } else {
            newFile.last_modified_str = new Date(Date.now());
        }

        // Update the state
        setSelectedFile(newFile);        
    };
    
    // On file upload (click the upload button)
    // if we switch to a component, we should place inside componentDidMount - see https://www.bezkoder.com/react-file-upload-axios/
    function useBatchUpload(event) {
        setStatus('uploading');

        const formData = new FormData();
        // formData.append('file', this.file);

        // formData.append('batchId',id);
        // // formData.append('columns',JSON.stringify(columns));
        // formData.append('selectedProtocol', selectedProtocolId);
        
        // formData.append('selectedProject', selectedProjectId);

        
        // if (selectedFile !== undefined) {
        //     formData.append('fileMetadata', JSON.stringify(selectedFile, ['name','lastModified','lastModifiedDate','size']));
        //     formData.append('imageId', 'new')
        //     formData.append('image', selectedFile);
        // }

        // axios.post(
        //     // method: 'post',
        //     API_URL + "api/batch",
        //     formData,
        //     { 
        //         headers: Object.assign(authHeader()),
        //         onUploadProgress: function(progressEvent) {
        //             var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

        //             if (percentCompleted !== progress) {
        //                 setProgress(percentCompleted);
        //                 console.log(percentCompleted + '%');
        //             }
        //           }
        //     }
        // )
        // .then(
        //         function (response) {
        //             //handle success
        //             setStatus('idle');
        //             const redirect_url = '/batch/' + response.data.batch.id
        //             navigate(redirect_url);
        //         }
        // )
        // .catch(function (response) {
        //     //handle error
        //     console.warn(response);
        //     setStatus('error');
        // });
    };

    const loadOriginalImage = async () => {
        console.log("loadOriginalImage");
        console.info(selectedFile);

        const img = selectedFile;

        if (selectedFile.id) {
            // setPreview(API_URL + "images/preview/" + selectedFile.userId + "/" + selectedFile.id)

            const src = API_URL + "images/preview/" + img.userId + "/" + img.id + "/" + img.width + "/" + img.height;

            await getImagePromise(src)
                .then(im => {
                    console.log(im);
                    setOriginalImage(im);
                })
        } else {
            // new file, temporarily stored locally.
            console.log("setting original image locally.")
            const objectUrl = URL.createObjectURL(selectedFile)
            setOriginalImage(objectUrl);
        }
    }

    
    const loadModel = (e) => {
        console.log("loadModel")
        console.log(e.target.value);

        
        setPredictedTubes(null);

        if (e.target.value === "") {
            console.info("unsetting model");
            setCurrentModel(undefined);
            setNet(undefined);
            return
        }

        const model = visionmodels.find(obj => {
            return obj.id == e.target.value;
        })
        setCurrentModel(model);

        // load model from cache.
        const localKey = `indexeddb://net_${model.id}`;

        const onModelLoadCallback = (net, saveToLocal) => {
            setNet(net)
            console.log("loaded net")
            
            // save net to localstorage
            if (saveToLocal) {
                console.log(`Saving model to ${localKey}`)
                net.save(localKey)
                .then(() => {
                    console.log(`saved to ${localKey}`)
                });
            }
        }

        

        tf.loadLayersModel(localKey)
            .then(net => {
                console.log("loaded model from " + localKey);
                onModelLoadCallback(net, false);
            })
            .catch(err => {
                console.log("not found - loading model from URL.")
                // const modelUrl = process.env.PUBLIC_URL + "/" + model.path + "/model.json";

                
                const modelUrl = API_URL + "/" + model.path + "/model.json";

                tf.loadLayersModel(modelUrl, {
                    // onProgress: progressEvent
                }).then(net => {
                    onModelLoadCallback(net, true);
                })
            })
    }





    const getWindows = () => {
        // proportion to overlap
        var overlap = (numWindows==1 ?  0 : .5);

        var windowSize = (1 + overlap) * Math.min(selectedFile.width, selectedFile.height)/numWindows;
        
        // console.log(`numwindows:${numWindows} overlap:${overlap} window size: ${windowSize}`)

        const numWindowsX = Math.ceil(selectedFile.width / windowSize);
        const numWindowsY = Math.ceil(selectedFile.height / windowSize);

        var totalOverlapX = (numWindowsX * windowSize) - selectedFile.width;
        var totalOverlapY = (numWindowsY * windowSize) - selectedFile.height;

        // console.log(`window array: ${numWindowsX}x${numWindowsY}`)
        // console.log(`Total overlapX:${totalOverlapX} Y:${totalOverlapY}`)

        var xOverlap = (numWindowsX==1 ? 0 : totalOverlapX / (numWindowsX-1));
        var yOverlap = (numWindowsY==1 ? 0 : totalOverlapY / (numWindowsY-1));

        var windows = [];
        var boxes = [];
        for (var i = 0; i < numWindowsX; i++) {
            for (var j=0; j < numWindowsY; j++) {
                var x1 = Math.round(i * (windowSize-xOverlap));
                var x2 = Math.round(x1 + windowSize);
                var y1 = Math.round(j * (windowSize-yOverlap));
                var y2 = Math.round(y1 + windowSize);
                windows.push({x1:x1, x2:x2, y1:y1, y2:y2})
                boxes.push([y1 / selectedFile.height,
                    x1 / selectedFile.width ,
                    y2 / selectedFile.height,
                    x2 / selectedFile.width]);
            }
        }

        const scale = canvasWidth / selectedFile.width;

        return {
            boxes: boxes,
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            overlap: overlap,
            windowSize: windowSize,
            numWindowsX: numWindowsX,
            numWindowsY: numWindowsY,
            totalOverlapX: totalOverlapX,
            totalOverlapY: totalOverlapY,
            xOverlap: xOverlap,
            yOverlap: yOverlap,
            windows: windows,
            scale: scale
        }
        
    }

    const getPrediction = () => {
        const windowConfig = getWindows()

        // convert image to tensor
        var originalTensor = tf.browser.fromPixels(originalImage, 3);

        // normalise by /255 ?
        const normalized = (
              currentModel.normalise 
            ? originalTensor.div(tf.scalar(255.0)) 
            : originalTensor
            );


        // expand
        const tensor = normalized.expandDims(0);

            
        // tidy up originalTensor
        originalTensor.dispose()
        
        // dispose of normalized
        normalized.dispose()

        var counter = 0;

        // make predictions for each window.
        const predictions = [];
        tf.tidy(() => {
            for (const i in windowConfig.boxes) {
                predictions.push(predictMask(net, tensor, windowConfig.boxes[i]))
            }
        })

        // combine predictions
        var combined_mask = combineMasks(predictions)
        var predicted_tubes = maskToTubes(combined_mask)

        // re-run the model on the candidate tubes
        predicted_tubes = predictOnPotentialTubes(predicted_tubes, net, tensor)
        tensor.dispose()

        setPredictedTubes(predicted_tubes)
        window.predicted_tubes = predicted_tubes
        
        const canvasOverlayElement = document.getElementById('myCanvasOverlay')
        const ctx = canvasOverlayElement.getContext('2d');


        // // draw the predictions on to the canvas.
        // const single_prediction_h = Math.round(predictions[0].predicted_mask.length / (predictions[0].box[2] - predictions[0].box[0]))
        // const single_prediction_w = Math.round(predictions[0].predicted_mask[0].length / (predictions[0].box[3] - predictions[0].box[1]))

        // const xOutputScale = canvasWidth / single_prediction_w;
        // const yOutputScale = canvasHeight / single_prediction_h;

        // for (const t in predicted_tubes.tube_clusters) {
        //     const tube = predicted_tubes.tube_clusters[t]
        //     if (tube.dimension < 20) {
        //         continue
        //     }

        //     const tx = tube.box[0] * xOutputScale
        //     const ty = tube.box[1] * yOutputScale
        //     const tw = (tube.box[2] - tube.box[0]) * xOutputScale
        //     const th = (tube.box[3] - tube.box[1]) * yOutputScale

        //     ctx.lineWidth = 2;
        //     ctx.strokeStyle = "white";
        //     ctx.strokeRect(tx, ty, tw, th);

        //     // draw ellipse?!
        //     const center = tube.ellipse.getCenter()
        //     const axisLength = tube.ellipse.getAxisLength()



        //     const angle = radians_to_degrees(tube.ellipse.equation.angle)

        //     const rx = Math.max(axisLength[0],axisLength[1])
        //     const ry = Math.min(axisLength[0],axisLength[1])

        //     // console.log(`Ellipse from ${Math.round(center[0])},${Math.round(center[1])} 
        //     // rx:${rx} ry:${ry} 
        //     // L1:${axisLength[0]} L2:${axisLength[1]}
        //     // angle:${angle}
        //     // xos:${xOutputScale} yos:${yOutputScale}`)
            
        //     ctx.beginPath();
        //     ctx.ellipse(
        //         center[0]* xOutputScale, 
        //         center[1]* yOutputScale, 
        //         rx * xOutputScale, 
        //         ry * yOutputScale, 
        //         -tube.ellipse.equation.angle, 
        //         10, 
        //         350);
        //     ctx.stroke();
        //     ctx.fillStyle = "green";
        //     // ctx.fill();


        //     ctx.fillStyle = "white";
        //     ctx.font = "10px serif";
        //     ctx.fillText(`${t}. ${angle}`, center[0]* xOutputScale, center[1]* xOutputScale);
            

        //     ctx.fillStyle = "white";
        //     ctx.font = "20px serif";
        //     ctx.fillText(t, tx, ty);
        // }


        // for (const t in predicted_tubes.tip_clusters) {
        //     const tip = predicted_tubes.tip_clusters[t]
        //     // console.log(tip)

        //     if (tip.dimension < 5) {
        //         continue
        //     }


        //     const tx = tip.box[0] * xOutputScale
        //     const ty = tip.box[1] * yOutputScale
        //     const tw = (tip.box[2] - tip.box[0]) * xOutputScale
        //     const th = (tip.box[3] - tip.box[1]) * yOutputScale

        //     ctx.lineWidth = 2;
        //     ctx.strokeStyle = "aqua";
        //     // ctx.shadowColor = "#d53";
        //     // ctx.shadowBlur = 20;
        //     ctx.strokeRect(tx, ty, tw, th);
        // }



    }


    const setNumWindowsFromSelect = (e) => {
        setNumWindows(e.target.value);
    }


    
    const ImageCanvases = () => {
        return useMemo(() => {
            return <div>
                <div id='canvasHolder' style={{position: "relative", width:`${canvasWidth}px`, height:`${canvasHeight}px` }}>
                    <Canvas img={originalImage} style={{position: "absolute"}} width={canvasWidth} height={canvasHeight} id='myCanvas'/>
                    <CanvasOverlay id='myCanvasOverlay' img={originalImage} style={{position: "absolute" }} width={canvasWidth} height={canvasHeight} predictedtubes={predictedTubes} />
                </div>
                {/* <KMeansAnalysis img={originalImage} predicted_tubes={predictedTubes} /> */}
            </div>
        },[originalImage, canvasWidth, canvasHeight])
    }
        
    function handleFileClick(event) {
        hiddenFileInput.current.click();
    }


    // File content to be displayed after
    // file upload is complete
    function fileData() {

        if (selectedFile !== undefined) {
            
          return (
            <div className='row'>
                <div className='col col-md9'>
                    <h2>File Details:</h2>
                    <p>{selectedFile.filename}</p>
                        
                    <p>
                        Last Modified:{" "}
                        {selectedFile.last_modified_str}
                    </p>
                    <p>
                        File size:{" "}
                        {selectedFile.filesize_human ? selectedFile.filesize_human : filesize(selectedFile.size, {round:1})}
                    </p>
                </div>
                <div className='col col-md3'>
                    {selectedFile &&  <img src={preview} style={{maxWidth: "100%", maxHeight: "300px"}} alt={`Preview of ${selectedFile.name}`} /> }
                </div>
    
            </div>
          );
        } else {
          return (
            undefined
          );
        }
      };



    function UI_progressBar() {
        return <div className="progress">
            <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
                >
            {progress}%
            </div>
        </div>
    }

    const ui_header = <div className='row'>
        <div className='col col-6'>
            <h1 className="glo display-1">GLO</h1>
        </div>
        <div className='col col-6'>
            <div className='signedInInfo py-2 text-info'>
                <div>{currentUser.forename}</div>
                <Link to='/dashboard'>Dashboard</Link>
                {' | '}
                <Link to='/benchmarking'>Benchmarking</Link>
            </div>
        </div>
    </div>

    const UI_projectOptions = projects.map((obj,i) => {
        return <option value={obj.id} key={i}>{obj.id}. {obj.title}</option>
    })

    
    function selectProject(e) {
        const selected_project_id = parseInt(e.target.value);
        setSelectedProjectId(selected_project_id);
        console.log("selected project " + selected_project_id)
    }

    const UI_Tab1 = <div>
            {/* <div>
                <input type="file" onChange={onFileChange} id='imageUpload' ref={hiddenFileInput} style={{display: "none"}} accept="image/png, image/jpeg" /> 
                <button className='btn btn-primary' onClick={useBatchUpload}><Save /> Save</button>
                <button className='btn btn-primary mx-1' onClick={handleFileClick}><FileImage /> Upload image</button>
            </div> */}

            {status !== "idle" && <div>Status: {status}</div>}


            {(selectedFile !== undefined) && (status === 'uploading') && UI_progressBar()}



            <div className='row'>
                <Form.Select aria-label="Project"  value={selectedProjectId} onChange={selectProject}>
                    <option value=''>Select a project:</option>
                    {UI_projectOptions}
                </Form.Select>
            </div>

            {fileData()}
            
        </div>;



    const UI_Tab2ImageStuff = () => {
        // console.log("Tab2ImageStuff")
        var models = visionmodels.filter(obj => {
            return obj.projectId == selectedProjectId
        })

        if (preview !== undefined && originalImage === undefined) {
            // console.log("Loading img for T2IS");
            loadOriginalImage();
            // console.log('not ready')
            return;
        }

        if (selectedFile === undefined) {
            return <div>not ready - no file</div>
        }
        if (selectedFile.width === undefined) {
            console.info(selectedFile);
            return <div>no width data available for file - try refreshing</div>
        }

        const modelOptions = models.map((obj, i) => {
            return(<option key={i} value={obj.id}>{obj.modelname}</option>);
        })

        const ModelSelector = <Form.Select aria-label={`${models.length} models:`} value={currentModel && currentModel.id ? currentModel.id : ""} onChange={loadModel}> 
            <option value="">Select a model...</option>
            {modelOptions}
        </Form.Select>

        const maxNumWindows = Math.ceil(Math.max(selectedFile.width, selectedFile.height)/244)


        const windowOptions = [...Array(maxNumWindows).keys()].map((obj,i) => {
            return <option key={i} value={obj+1}>{obj+1}</option>;
        })
        
        const NumWindowsSelector = <Form.Select aria-label={`How many windows?:`} value={numWindows} onChange={setNumWindowsFromSelect}> 
            <option value="0">Select how many columns...</option>
            {windowOptions}
        </Form.Select>

        return <div>
            <div className='row pb-3'>
                <div className='col-6'>
                    {ModelSelector}
                </div>
                <div className='col-4'>
                    {NumWindowsSelector}
                </div>
                <div className='col-2'>
                    {net !== undefined && numWindows>0 && <button className='btn btn-primary' onClick={getPrediction}>Predict</button>}
                </div>
            </div>
            {/* {net === undefined ? <div>No model</div> : <ImageCanvases />} */}
            <ImageCanvases />
        </div>
    }


    const UI_Tab2 = () => {
        if (preview === undefined) {
            return <p>No preview</p>
        }
        return <UI_Tab2ImageStuff />
    }
    

    const UI_Tubes = () => {
        if (predictedTubes === null) {
            return(<>Run model first</>)
        }

        const TubeInfo = predictedTubes.tube_clusters.map((cluster, cluster_id) => {

            // console.info(`cluster ${cluster_id}`, cluster)

            if (cluster.dimension < 50) {
                return null
            }

            return <div key={cluster_id}>
                <h3>Tube {cluster_id+1}. ({cluster.dimension}px)</h3>
                
                <Canvas 
                    img={originalImage} 
                    style={{position: "relative"}} 
                    width={730}
                    height={200} 
                    id={`tube_thumb_${cluster_id}`} 
                    tube={cluster} 
                    predictedtubes={predictedTubes} 
                    />

                {/* <div>
                    X: {Math.round(cluster.x)} Y: {Math.round(cluster.y)}
                    Box: {`(${cluster.box_int[0]},${cluster.box_int[1]}) - (${cluster.box_int[2]},${cluster.box_int[3]})
                    
                    (${cluster.percentage_of_pixels_covered}% of img)

                    `}
                    
                    <p>{cluster.tip_index.length} tips: 
                    <ol>
                    { cluster.tip_index.map((tip_id,j) => { 
                        console.log("cluster-tip_id",`${cluster_id}, ${tip_id}`)
                        const tip = predictedTubes.tip_clusters[tip_id]
                        console.info("tip",tip)
                        return <li key={`ti_${j}`}>{tip_id} - ({tip.x},{tip.y})</li> 
                    } )}
                    </ol>
                    </p>
                    <p>{cluster.ellipse.printEquation()}</p>
                    <p>{`${cluster.ellipse.getCenter()[0]} , ${cluster.ellipse.getCenter()[1]}`}</p>
                </div> */}
              </div>
        })

        return <div>
            <h1>Tubes</h1>
            {TubeInfo}
            <h3>Ascii tubes</h3>
            <div style={{fontSize:'.45em', fontFamily: 'courier', lineHeight: '70%'}} dangerouslySetInnerHTML={{ __html: predictedTubes.tube_prediction }} />
        </div>
    }

    function renderBatch() {

        if (! currentUser) {
            return(
                <div>Not logged in</div>
            )
        }

        return <div>
            {ui_header}



            <Tabs
                defaultActiveKey='tab1'
                id='mytabs'
                className='mr-2 ml-2 mb-3'
            >
                <Tab eventKey='tab1' title='File'>
                    <h1>{id==='new' ? "New batch" : "Batch " + id}</h1>
                    {UI_Tab1 }
                </Tab>
                {   id !== "new" && 
                    <Tab eventKey='tab2' title='Image analysis' disabled={preview === undefined}>
                        <UI_Tab2 />
                    </Tab>
                }
                {   id !== "new" && 
                    <Tab eventKey='tab3' title='Tubes'>
                        <UI_Tubes />
                    </Tab>
                }

            </Tabs>

        </div>
    }

    return renderBatch()
}