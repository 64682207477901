export default function Terms() {
  return (
    <>
      <h1>Terms of use</h1>
      <p>
        Reprehenderit ipsum excepteur mollit sit incididunt ipsum magna ea
        mollit ipsum Lorem culpa. Ea dolor nisi dolor sunt sit ea ea est
        excepteur mollit eu. Excepteur minim culpa et commodo sit laborum sint
        sint ad. Qui et sint Lorem irure duis laborum sint magna. Laboris in
        proident cupidatat exercitation ut cupidatat Lorem minim nisi.
        Reprehenderit qui est dolore anim incididunt non commodo cupidatat
        cillum occaecat velit ullamco laborum. Velit in non anim consectetur ex
        ea enim officia dolor.
      </p>
      <p>
        Proident est id ea cillum. Sunt proident mollit labore minim proident ex
        duis duis officia minim exercitation eiusmod. Ullamco excepteur laboris
        ea proident. Enim exercitation laborum amet Lorem occaecat minim ea non.
        Est nisi in id tempor. Ea exercitation duis et veniam.
      </p>
      <p>
        Cillum deserunt et occaecat consequat. Dolore voluptate proident esse
        ullamco ut veniam labore. Sit incididunt eiusmod eu officia laboris ad
        reprehenderit eiusmod do est. Incididunt eu magna in cupidatat excepteur
        et.
      </p>
    </>
  );
}
