import { Component, useRef } from "react"
import { Button } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import AuthService from "../services/auth.service";
import Webcam from "react-webcam";
import { CameraFill } from "react-bootstrap-icons";

function CaptureSingleTube({ props }) {

    const currentUser = AuthService.getCurrentUser();
    const { assay } = useParams();
    
    const webcamRef = useRef()

    const ui_header = <div className='row'>
        <div className='col col-6'>
            <h1 className="glo display-1">GLO</h1>
        </div>
        <div className='col col-6'>
            <div className='signedInInfo py-2 text-info'>
                <div>{currentUser.forename}</div>
                <Link to='/dashboard'>Dashboard</Link>
                {' | '}
                <Link to='/benchmarking'>Benchmarking</Link>
            </div>
        </div>
    </div>

    return <div>
        {ui_header}
        <h1>Capture Single Tube</h1>
        <div>
            <small style={{
                backgroundColor: 'aliceblue',
                color: 'maroon',
                padding: '5px 10px',
                fontWeight: 'bold',
            }}>Assay: {assay}</small>
        </div>
        <div style={{
                position:'relative',
                // border: "10px solid hotpink",
                height: '600px',
                backgroundColor: '#00000099',
                // textAlign: 'center'
                margin: 'auto'
            }}
        >
            <Webcam ref={webcamRef}
                muted={true} 
                    //  height={size.height} 
                    //  width={size.width}
                    // height={880}
                    // width={640}
                videoConstraints={{
                    // facingMode: 'user', 
                    facingMode: 'environment', 
                    // aspectRatio: ratio
                    // aspectRatio:1.375,
                    height: 600,
                    width: 600
                }}
                style={{
                    // float: 'right',

                    // objectFit: 'cover',
                    // objectPosition: 'center center'
                    position: "absolute",
                    // marginLeft: "auto",
                    // marginRight: "auto",
                    // left: 0,
                    // right: 0,
                    textAlign: "center",
                    zindex: 9,
                    // border:'1px solid orange'
                }}
            />
            <img src='/600x600_tube_outline_faded.png' style={{
                    position: 'absolute',
                    zIndex: 20,
                    // border: '1px solid blue',
                }}
                alt=''
            />
            <div style={{
                position:'absolute',
                zIndex: 30,
                backgroundColor: '#00000066',
                color: '#FFF',
                fontWeight: 'bold',
                padding: '3px 6px',
                top: '250px',
                left: '280px'
                
            }}> Tube</div>
            <div style={{
                position:'absolute',
                zIndex: 30,
                backgroundColor: '#00000066',
                color: '#FFF',
                fontWeight: 'bold',
                padding: '3px 6px',
                top: '450px',
                left: '340px'
            }}> Liquid</div>
            <div style={{
                position:'absolute',
                zIndex: 30,
                backgroundColor: '#00000066',
                color: '#FFF',
                fontWeight: 'bold',
                padding: '3px 6px',
                top: '490px',
                left: '320px'
            }}> Tip</div>
        </div>
        <div>
            <Button variant='primary'>
                <CameraFill /> Capture
            </Button>
        </div>
    </div>
}


export default CaptureSingleTube