import * as tf from "@tensorflow/tfjs"

export default function predictMask(net, tensor, box) {
    // make a prediction on the window portion of originalTensor
    var windowTensor = tf.image.cropAndResize(tensor, [box], [0], [net.feedInputShapes[0][1],net.feedInputShapes[0][2]])

    // make prediction
    const timer_start = performance.now();
    const prediction =  net.predict(windowTensor);
    const timer_end = performance.now();

    // dispose windowTensor
    windowTensor.dispose()

    // console.log(`Made a prediction in ${timer_end - timer_start}ms`)

    // console.log(prediction);
    const pred_mask = tf.argMax(prediction, -1)
    const pred_mask_array = pred_mask.dataSync()

    prediction.dispose()

    return({
        box: box,
        duration: timer_end - timer_start,
        window: window,
        predicted_mask: tf.reshape(
            pred_mask_array,
            [net.feedInputShapes[0][1],net.feedInputShapes[0][2]]
            ).arraySync()
    })
}