export function  get_chart_data_times(benchmarks) {
    const xaxis_values = benchmarks.map((benchmark, i) => {
        return(i)
    })
    const yaxis_values = benchmarks.map((benchmark, i) => {
        return(Math.round(benchmark.duration))
    })

    var options = {
        options: {
          chart: {
            animations: {
              enabled: false
            },
            id: "basic-bar",
            toolbar: {
                show: true,
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                }
            }
          },
          xaxis: {
            // categories: xaxis_values
            labels: {
                show: false
            }
          },
          dataLabels: {
            enabled: false
          }
        },
        series: [
          {
            name: "Duration (ms)",
            data: yaxis_values
          }
        ]
      };

    return(options);
}




export function  get_chart_data_memory(benchmarks) {
    const xaxis_values = benchmarks.map((benchmark, i) => {
        return(i)
    })
    const y_numBytes = benchmarks.map((benchmark, i) => {
        return(Math.round(benchmark.numBytes))
    })
    const y_numBytesInGPU = benchmarks.map((benchmark, i) => {
        return(Math.round(benchmark.numBytesInGPU))
    })
    const y_numBytesInGPUAllocated = benchmarks.map((benchmark, i) => {
        return(Math.round(benchmark.numBytesInGPUAllocated))
    })
    const y_numTensors = benchmarks.map((benchmark, i) => {
        return(Math.round(benchmark.numTensors))
    })

    var options = {
        options: {
          chart: {
            id: "line",
            toolbar: {
                show: true,
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                }
            }
          },
          xaxis: {
            // categories: xaxis_values
            labels: {
                show: false
            }
          },
          dataLabels: {
            enabled: false
          }
        },
        series: [
          {
            name: "Num bytes",
            data: y_numBytes
          },
          {
            name: "Num bytes in GPU",
            data: y_numBytesInGPU
          },
          {
            name: "Num bytes in GPU Allocated",
            data: y_numBytesInGPUAllocated
          },
          {
            name: "Num tensors",
            data: y_numTensors
          }
        ]
      };

    return(options);
}