// show list of annotations

import axios from "axios";
import { useMemo } from "react";
import { API_URL, REMOTE_URL } from "../config/api";
import { useState } from "react";
import authHeader from "./services/auth-header";
import { Link, useParams } from "react-router-dom";
import authService from "./services/auth.service";
import { useRef } from "react";
import { Button, CloseButton, Col, Row } from "react-bootstrap";
import { Dash, DoorClosed, Plus, X } from "react-bootstrap-icons";
import { getImagePromise } from "./helpers/getImagePromise";
import CanvasAnnotation from "../annotationCanvas";

const AnnotationUI = () => {
    const [annotation, setAnnotation] = useState(null);
    const [tubes, setTubes] = useState([]);
    const props = useParams();
    const currentUser = authService.getCurrentUser();
    const edit_tube_controls = useRef();    
    const [currentTube, setCurrentTube] = useState(null);
    const [originalImage, setOriginalImage] = useState(null);

    const loadImageFromURL = async (url) => {
        console.log("loadImageFromURL", url);

        await getImagePromise(url)
            .then(im => {
                console.log(im);
                setOriginalImage(im);
            })
    }

    // when page loads, load annotation info, and image.
    useMemo(() => {
        axios.get(
            `${API_URL}api/annotation/${props.id}`,
            // params
            {
                headers: Object.assign(authHeader())
            }
        )
        .then((data) => {
            console.warn('data', data.data)
            setAnnotation(data.data)
            setTubes(data.data.tubes)

            // load image from URL.
            const url = `${REMOTE_URL}images/preview/${data.data.user.id}/${data.data.image.id}/${data.data.image.width}/${data.data.image.height}`
            loadImageFromURL(url)
        })
    },[])
   
    
    const dragTube = (e_mousemove, e_mousedown, tube) => {
        const move_x = e_mousemove.clientX - e_mousedown.clientX
        const move_y = e_mousemove.clientY - e_mousedown.clientY        

        e_mousemove.target.parentElement.style.top = (tube.dimensions.top + (move_y)) +'px'
        e_mousemove.target.parentElement.style.left = (tube.dimensions.left + (move_x)) +'px'

        // edit_tube_controls.current.style.top= `${(tube.dimensions.top + (move_y)) + tube.dimensions.height + 10}px`
        // edit_tube_controls.current.style.left = `${(tube.dimensions.left + (move_x))}px`
    }
    
    const dragTubeHeight = (e_mousemove, e_mousedown, tube) => {
        const y_adjustment = e_mousemove.clientY - e_mousedown.clientY        
        e_mousemove.target.parentElement.style.height = (tube.dimensions.height + y_adjustment) +'px'
        // edit_tube_controls.current.style.top= `${parseFloat(e_mousemove.target.parentElement.style.top.replace('px','')) + tube.dimensions.height + y_adjustment + 10}px`
    }

    const dragTubeWidth = (e_mousemove, e_mousedown, tube) => {
        const x_adjustment = e_mousemove.clientX - e_mousedown.clientX        
        e_mousemove.target.parentElement.style.width = (tube.dimensions.width + x_adjustment) +'px'
    }

    const dragTubeWithTouch = (e_touchmove, e_touchdown, tube) => {
        console.log(`dragTubeWithTouch`, e_touchmove)
        const move_x = e_touchmove.touches[0].clientX - e_touchdown.touches[0].clientX
        const move_y = e_touchmove.touches[0].clientY - e_touchdown.touches[0].clientY        

        e_touchmove.target.parentElement.style.top = (tube.dimensions.top + (move_y)) +'px'
        e_touchmove.target.parentElement.style.left = (tube.dimensions.left + (move_x)) +'px'

        // edit_tube_controls.current.style.top= `${(tube.dimensions.top + (move_y)) + tube.dimensions.height + 10}px`
        // edit_tube_controls.current.style.left = `${(tube.dimensions.left + (move_x))}px`

        e_touchmove.preventDefault()
    }
    
    const dragTubeHeightWithTouch = (e_touchmove, e_touchdown, tube) => {
        const y_adjustment = e_touchmove.touches[0].clientY - e_touchdown.touches[0].clientY        
        e_touchmove.target.parentElement.style.height = (tube.dimensions.height + y_adjustment) +'px'
        // edit_tube_controls.current.style.top= `${parseFloat(e_touchmove.target.parentElement.style.top.replace('px','')) + tube.dimensions.height + y_adjustment + 10}px`
        e_touchmove.preventDefault()
    }

    
    const dragTubeWidthWithTouch = (e_touchmove, e_touchdown, tube) => {
        const x_adjustment = e_touchmove.touches[0].clientX - e_touchdown.touches[0].clientX        
        e_touchmove.target.parentElement.style.width = (tube.dimensions.width + x_adjustment) +'px'        
        e_touchmove.preventDefault()
    }

    const updateTubeDimensions = (tube, i, element) => {
        // console.info(`update tube dimensions for [${i}]`, tube)
        // console.info(`top: ${element.style.top} height:${element.style.height}`)
        const height = parseFloat(element.clientHeight)
        const width = parseFloat(element.clientWidth)
        const top = parseFloat(element.style.top)
        const left = parseFloat(element.style.left)
        
        console.info(`position: ${left},${top} w:${width} h:${height}`)

        const newTubes = annotation.tubes

        newTubes[i].dimensions = {
            height:height,
            width: width,
            top:   top, 
            left:  left
        }

        newTubes[i].status = 'modified'

        console.log('setting tubes', newTubes)
        setTubes(newTubes)
    }

    const TubeOverlay = useMemo(() => {
        console.log("Refreshing tubeOverlay", annotation)

        if (annotation == null) {
            console.warn('annotation is null')
            return null
        }

        return <div id='tube_overlay' style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0px',
            left:'0px',
        }}
            onMouseDown={(e_mousedown) => {
                const tubeOverlayDiv = document.getElementById("tube_overlay")               
                const y1 = e_mousedown.clientY -  tubeOverlayDiv.getBoundingClientRect().top
                const x1 = e_mousedown.clientX - tubeOverlayDiv.getBoundingClientRect().left

                var newTubeBox = document.getElementById("newTubeBox")

                if (newTubeBox === null) {
                    newTubeBox = document.createElement('div')
                    newTubeBox.id = "newTubeBox"
                } else {
                    // console.log(`ntb not new`, newTubeBox)
                }

                newTubeBox.style.cssText = `position: absolute; border: 5px solid hotpink; top:${y1}px; left:${x1}px; width:4px; height:4px`
                tubeOverlayDiv.appendChild(newTubeBox);

                e_mousedown.target.onmousemove = (e_mousemove) => {
                    const xOffset = e_mousemove.clientX - e_mousedown.clientX
                    const yOffset = e_mousemove.clientY - e_mousedown.clientY

                    const width = (xOffset < 0 ? xOffset*-1: xOffset)
                    const height= (yOffset < 0 ? yOffset*-1: yOffset)
                    const left = (xOffset < 0 ? x1-width: x1)
                    const top  = (yOffset < 0 ? y1-height: y1)
                    newTubeBox.style.cssText = `position: absolute; border: 5px solid blue; top:${top}px; left:${left}px; width:${width}px; height:${height}px`
                }
            }}
            onMouseUp={(e_mouseup) => {
                e_mouseup.target.onmousemove = null
                e_mouseup.stopPropagation()

                // add this tube to the list
                var newTubeBox = document.getElementById("newTubeBox")

                if (newTubeBox == null) {
                    console.warn("new tube box is null")
                    return;
                }

                const height= parseFloat(newTubeBox.style.height)
                const width = parseFloat(newTubeBox.style.width)
                const top   = parseFloat(newTubeBox.style.top)
                const left  = parseFloat(newTubeBox.style.left)

                newTubeBox.remove()
                
                if (width < 15 | height < 15) {
                    console.info(`width=${width} height=${height} - this is too small for a tube so will ignore`)
                    return;
                }

                const newTubes = tubes

                const newTube ={
                    // x: left,
                    // y: top,
                    dimensions: {
                        height: height,
                        width: width,
                        top: top, 
                        left: left
                    },
                    status: 'new',
                }

                newTubes.push(newTube)
                console.log('pushed new + setting tubes', newTubes)

                // setTubes(newTubes)
            }}
            onClick={e => {
                console.warn('click')
                e.target.onmousemove = null
            }} 
        >
            {tubes.map((tube,i) => {
                // console.log(`tube ${i}`, tube)
                if (tube === null) {
                    return null
                }

                return <div 
                    style={{ 
                        position: 'absolute', 
                        top:`${tube.dimensions.top}px`, 
                        left:`${tube.dimensions.left}px`, 
                        width:`${tube.dimensions.width}px`, 
                        height:`${tube.dimensions.height}px`}}
                    className={`tubeRect ${tube.result}`}
                    onMouseEnter={(e) => {
                        console.log(`entered tube`, tube)
                        // edit_tube_controls.current.style={
                        //     position:'absolute',
                        //     top: `${tube.dimensions.top + tube.dimensions.height + 100}px`
                        // }
                        // edit_tube_controls.current.style.position='absolute'
                        // edit_tube_controls.current.style.top= `${tube.dimensions.top + tube.dimensions.height + 10}px`
                        // edit_tube_controls.current.style.left= `${tube.dimensions.left}px`
                        // edit_tube_controls.current.style.display='block'
                        setCurrentTube({i:i, tube:tube})
                        window.etc = edit_tube_controls;
                    }}
                    >
                        <div 
                          className="tubeRectMain"
                          style={{ 
                            position: 'absolute', 
                            top:`0px`, 
                            left:`0px`, 
                            right:`15px`,
                            bottom:`15px`,
                            }}
                          onMouseDown={(e_mousedown) => {
                                console.log(`mousedown`)
                                e_mousedown.target.onmousemove=(e_mousemove) => {
                                    dragTube(e_mousemove, e_mousedown, tube)
                                }
                                e_mousedown.stopPropagation()
                            }}
                          onMouseUp={(e) => {
                                console.log(`mouseup`)
                                e.target.onmousemove=null
                                updateTubeDimensions(tube, i, e.target.parentElement)
                                e.stopPropagation()
                            }}
                          onMouseLeave={(e) => {
                                e.target.onmousemove=null
                                e.stopPropagation()
                            }}
                          onTouchStart={(e_touchstart) => {
                            console.log('touchstart', e_touchstart)
                            e_touchstart.target.ontouchmove = (e_touchmove) => {
                                console.log(`touchmove`)
                                dragTubeWithTouch(e_touchmove, e_touchstart, tube)
                            }
                          }}
                          onTouchEnd={(e) => {
                            console.log(`onTouchEnd`, e)
                            e.target.ontouchmove = null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                          }}
                        
                            >
                            {`${i+1}`}
                        </div>
                        <div 
                          className="tubeRectBottom"
                          style={{ /* bottom */
                            position: 'absolute',
                            bottom: '0px',
                            left:`0px`, 
                            right: `0px`,
                            height:`15px`,
                        }}
                        
                        
                        onMouseDown={(e_mousedown) => {
                            e_mousedown.target.onmousemove=(e_mousemove) => dragTubeHeight(e_mousemove, e_mousedown, tube)
                            e_mousedown.stopPropagation()
                        }}
                        onMouseUp={(e) => {
                            e.target.onmousemove=null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                            e.stopPropagation()
                        }}
                        onTouchStart={(e_touchstart) => {
                            e_touchstart.target.ontouchmove=(e_touchmove) => dragTubeHeightWithTouch(e_touchmove, e_touchstart, tube)
                        }}
                        onTouchEnd={(e) => {
                            e.target.ontouchmove=null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                        }}
                        onMouseLeave={(e) => {
                            e.target.onmousemove=null
                        }}
                        
                        ></div>
                        <div 
                          className='tubeRectRight'
                          style={{ /* right */
                            position: 'absolute',
                            top:`0px`,
                            right:'0px',
                            width:`15px`, 
                            bottom:`0px`,
                            // outline: '3px solid magenta'
                        }}
                        
                        onMouseDown={(e_mousedown) => {
                            e_mousedown.target.onmousemove=(e_mousemove) => dragTubeWidth(e_mousemove, e_mousedown, tube)
                            e_mousedown.stopPropagation()
                        }}
                        onMouseUp={(e) => {
                            e.target.onmousemove=null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                            e.stopPropagation()
                        }}
                        onMouseLeave={(e) => {
                            e.target.onmousemove=null
                            // updateTubeDimensions(tube, i, e.target.parentElement)
                        }}


                        
                        onTouchStart={(e_touchstart) => {
                            e_touchstart.target.ontouchmove=(e_touchmove) => dragTubeWidthWithTouch(e_touchmove, e_touchstart, tube)
                        }}
                        onTouchEnd={(e) => {
                            e.target.ontouchmove=null
                            updateTubeDimensions(tube, i, e.target.parentElement)
                        }}
                        
                        ></div>
                    </div>
            })}

        </div>
    },[tubes])

    const EditTubeControls = useMemo(() => {
        console.log('etc', currentTube)

        if (currentTube == null) {
            return null
        }

        return <div 
            id='edit_tube_controls' 
            ref={edit_tube_controls}
            style={{
                position:'absolute', 
                zIndex:9999,
                top: `${currentTube.tube.dimensions.top + currentTube.tube.dimensions.height + 10}px`,
                left: `${currentTube.tube.dimensions.left}px`
            }}
        >
            <Button variant='danger' onClick={(e) => {
                const newTubes = tubes.filter((v,i) => i !== currentTube.i)
                console.log('new tubes', newTubes)
                setTubes(newTubes)
                // edit_tube_controls.current.style.display='none'
            }}>
                <DoorClosed /> Delete
            </Button>
            <Button variant='warning' style={{margin:'0px 1em'}} onClick={(e) => {
                const newTubes = tubes.map((tube, i) => {
                    if (i !== currentTube.i) {
                        return tube
                    }

                    tube.result = 'positive'
                    return tube
                })
                setTubes(newTubes)
            }}>
                <Plus /> +ve
            </Button>
            <Button variant='warning' style={{marginRight:'1em'}} onClick={(e) => {
                const newTubes = tubes.map((tube, i) => {
                    if (i !== currentTube.i) {
                        return tube
                    }

                    tube.result = 'negative'
                    return tube
                })
                setTubes(newTubes)
            }}>
                <Dash /> -ve
            </Button>
            <Button onClick={(e) => {
                // edit_tube_controls.current.style.display='none'
                setCurrentTube(null)
            
            }}>
                <X /> Close
            </Button>
        </div>
    }, [currentTube])

    const ListOfTubes = useMemo(() => {

        
        if (annotation == null) {
            console.warn('annotation is null')
            return null
        }

        if (originalImage === null) {
            console.warn('originalImage is null')
            return null
        }
        
        return <>
            <h2>{annotation.number_of_tubes} tubes</h2>
            {annotation.tubes.map((tube,i) => {
                console.log(`v ${i}`,tube)

                // if (i >= 5) {
                //     return null;
                // }

                const style = (
                    tube.result === "positive"
                    ? {
                        backgroundColor: '#FFEBEB', 
                        marginBottom: '1em', 
                        border: "5px solid red"
                      }
                    : { 
                        backgroundColor: '#EBEBEB', 
                        marginBottom: '1em', 
                        border: "1px solid blue"
                    }
                )

                if (! tube?.box) {
                    console.warn("CanvasAnnotation - no box", props.tube)
                    return null
                }


                return <Row style={style}>
                    <Col>
                        <CanvasAnnotation 
                            img={originalImage} 
                            // style={{float:'left'}} 
                            width={600}
                            height={200} 
                            id={`tube_thumb_${i}`} 
                            tube={tube}
                            />
                        <div>
                            {i+1}. 
                            {tube.result} {tube.status}
                        </div>
                    </Col>
                </Row>
            })}
        </>
    }, [tubes, originalImage])
    
    const AnnotateHeader = () => {
        return <div className='row'>
            <div className='col col-6'>
                <h1 className="glo display-1">
                    <Link to='/'>GLO</Link>
                </h1>
            </div>
            <div className='col col-6'>
                {
                currentUser
                ? <div className='signedInInfo py-2 text-info'>
                    <div>{currentUser.forename}</div>
                    <Link to='/'>Home</Link>
                    </div>
                : <div className='signedInInfo py-2 text-info'>
                    <div>Not signed in</div>
                    <Link to='benchmarking'>Benchmarking</Link>
                    </div>
                }
            </div>
        </div>
    }

    if (annotation === null) {
        return <>Please wait</>
    }

    // userSelect:'none'
    return <>
        <AnnotateHeader />
        <div style={{color:'#424242', fontSize:'.8em',marginBottom:'1em',}}>
            <Link to='/annotations'>Annotations</Link> &gt; #{annotation.id}. {annotation.image.filename} ({annotation.image.filesize_human})
        </div>
        <div>{annotation.number_of_tubes} tubes</div>
        <div>Created by <span style={{color: 'cornflowerblue'}}>{annotation.user.forename} {annotation.user.surname}</span></div>
        <div>{annotation.createdAt}</div>
        {ListOfTubes}
        <div style={{position: 'relative'}}> 
            <img 
                src={`${REMOTE_URL}images/preview/${annotation.user.id}/${annotation.image.id}/${annotation.image.width}/${annotation.image.height}`} 
                style={{position:'absolute' }}
                alt='Tubes' />
            {TubeOverlay}
            {EditTubeControls}
        </div>
    </>
}


export default AnnotationUI;