import * as tf from "@tensorflow/tfjs"

export function benchmarkImages(net, benchmark_images, boxes, imageFileCache, normalise=false, final_callback) {

    function update_benchmark_progress(percentCompleted, element_id='glo_progressbar') {
        const progress_element = document.getElementById(element_id)
        if (progress_element != null) {
            progress_element.style.width = `${percentCompleted}%`;
            progress_element.setAttribute('aria-valuenow',`${percentCompleted}`)
            progress_element.innerHTML = `${percentCompleted}%`
            // progress_element.css({ 'transition-duration' : '0s' })
            document.getElementById("glo_progressbar").style.transitionDuration='0s'
            // console.warn(`found ${element_id} and set ${percentCompleted}`)
        } else {
            console.warn(`unable to find ${element_id} to set ${percentCompleted}`)
        }
    }
    
    function run_benchmark(t_img, box) {

        // console.log("running benchmark with box", box)
    
        const cropped = tf.image.cropAndResize(t_img, [box], [0], [net.feedInputShapes[0][1],net.feedInputShapes[0][2]])
    
    
        const casted = cropped.cast('float32')
    
        const input_tensor = (normalise ? casted.div(tf.scalar(255)) : casted)
    
        // make prediction
        const timer_start = performance.now();
        const prediction =  net.predict(input_tensor);
        const timer_end = performance.now();
    
        const duration = timer_end - timer_start;
    
        const memory_usage = tf.memory()
    
        
        const newBenchmark = {
            timer_start: timer_start,
            timer_end: timer_end,
            duration: duration,
            box: JSON.stringify(box),
            numBytes: memory_usage.numBytes,
            numBytesInGPU: memory_usage.numBytesInGPU,
            numBytesInGPUAllocated: memory_usage.numBytesInGPUAllocated,
            numBytesInGPUFree: memory_usage.numBytesInGPUFree,
            numDataBuffers: memory_usage.numDataBuffers,
            numTensors: memory_usage.numTensors,
            unreliable: memory_usage.unreliable
        }
    
        return newBenchmark
    }



    // create benchmark queue
    var benchmark_queue = []
    var benchmark_results = []

    console.log('boxes',boxes)
    
    for (var i = 0; i < benchmark_images.length; i++) {
        const img = benchmark_images[i]

        for (var j = 0; j < boxes.length; j++) {
            const benchmark = Object.assign({}, img)
            benchmark['box'] = boxes[j]

            benchmark_queue.push(
                benchmark
            )
        }
    }

    // console.info('benchmark_queue', benchmark_queue)


    var i = 0;

    const benchmark_callback = () => {
        const next_benchmark = benchmark_queue[i];

        i += 1
        // console.log(`${i}. benchmark_callback`)

        // console.log(`${i}. next_benchmark`,next_benchmark)

        
        // ---- run benchmark here ---


        // use tf.tidy to dispose of intermediate gumpf and avoid memory leaks
        var t_img = tf.tidy(() => {
            var t_orig = tf.browser.fromPixels(imageFileCache[next_benchmark.id], 3)

            if (t_orig.shape.length < 4) {
                t_orig = t_orig.expandDims(0)
            } 

            return(t_orig)
        });


        const new_benchmark = tf.tidy(() => run_benchmark(t_img, next_benchmark.box))

        new_benchmark['id'] = next_benchmark.id
        new_benchmark['execution_order'] = i;
        new_benchmark['execution_order_img'] = j;




        benchmark_results.push(new_benchmark)


        const progress = 100 * i / numBenchmarks_total
        const update_progress = Math.round(progress) > progress_percent

        if (update_progress) {
            progress_percent = Math.round(progress)
            console.log(`${i}/${numBenchmarks_total} (${progress}% ${progress_percent}%)`)
            update_benchmark_progress(progress_percent);
        }
        
            // }
        // if (benchmark_queue.length > 0) {
        if (i < numBenchmarks_total) {
            if (update_progress) {
                setTimeout(()=>{ tf.tidy(() => {benchmark_callback()})},100)
            } else {
                tf.tidy(() => {benchmark_callback()})
            }
        }
        else {
            console.log("finished")

            final_callback(benchmark_results)
        }
    }


    const numBenchmarks_total = benchmark_queue.length

    console.log(`There are ${numBenchmarks_total} total benchmarks to do.`)


    var progress_percent = 0;

    console.log("starting callback")
    benchmark_callback()

    console.log("benchmarks", benchmark_results)

    // for (var i = 0; i < benchmark_queue.length; i++) {
    //     console.log(i)
    //     const img = benchmark_queue[i]

    //     // console.log(img)

    //     const im = imageFileCache[img.id];

    //     console.log(im)


    //     // use tf.tidy to dispose of intermediate gumpf and avoid memory leaks
    //     var t_img = tf.tidy(() => {
    //         var t_orig = tf.browser.fromPixels(im, 3)

    //         if (t_orig.shape.length < 4) {
    //             t_orig = t_orig.expandDims(0)
    //         } 

    //         return(t_orig)
    //     });



    //     for (var j = 0; j < boxes.length; j++) {
    //         const box = boxes[j]

    //         // Okay, we've all the basics we need to get cracking.
    //         const new_benchmark = tf.tidy(() => benchmark_callback(t_img, box))

    //         new_benchmark['id'] = img.id
    //         new_benchmark['execution_order'] = numBenchmarks_completed;
    //         new_benchmark['execution_order_img'] = j;
            
    //         benchmarks.push(new_benchmark)

    //         numBenchmarks_completed += 1

    //         const progress = 100 * numBenchmarks_completed / numBenchmarks_total;



    //         if (Math.round(progress) > progress_percent) {
    //             progress_percent = Math.round(progress)
    //             console.log(`${numBenchmarks_completed}/${numBenchmarks_total} (${progress}% ${progress_percent}%)`)
    //             update_benchmark_progress(progress_percent);
    //         }
            

    //     }

    //     t_img.dispose()
    // }


    // console.info("Finished benchmarking", benchmarks)
    // return benchmarks




}

