import { Link, useParams } from "react-router-dom";
import {
  QuestionCircle,
  CheckCircle,
  ExclamationCircle,
  ArrowLeft,
  ArrowRight,
  House,
  Camera,
} from "react-bootstrap-icons";
import { useState } from "react";
import Webcam from "react-webcam";
import Image from "react-bootstrap/Image";


function Onboarding_1() {
  return (
    <div>
      <div className="container px-4 py-5">
        <h2 className="display-2">Onboarding 1</h2>

    
        <div className="row">
          <div className="col-md-3">
            <p>
              <Image
                src={process.env.PUBLIC_URL + "/tube_images/362c.jpg"}
                className="rounded img-fluid w-100"
              />
            </p>
          </div>
          <div className="col-md-9" >
            <p><b style={{color: 'orange'}}>glo</b> - an ESR pioneer project to develop a core capability to deploy machine learning models to mobile devices
            </p>
            
            <p>
            The data science component of ESR’s CRISPR rapid diagnostics SSIF is funding the minimal implementation of a computer vision algorithm whereby a machine learning model can analyse images captured by mobile phones, identify PCR tubes in a lightbox and determine whether the reagent is fluorescing, indicating the presence or absence of the test subject.
            </p>
          </div>
          <div className='row'>

            <p>As the CRISPR SSIF has progressed, we have been approached by scientists across ESR business groups who have ideas about how mobile computer vision could be applied to their domains.
            </p>

            <p>
            This project focusses on the specific task of deploying a deep learning computer vision model to a mobile phone. This means the model needs to be small enough to run in the memory, on the CPU and GPU of the mobile device itself. It creates a much richer user experience, eliminating the need for complex cloud infrastructure to receive and process images.
            </p>

            <p>
            This project aims to explore where the state of the art is, and to build a showcase app, allowing us to build and demonstrate a core capability that could be widely applicable across our science domains. 
            </p>
          </div>
        </div>
        

        <div className="row">
          <div className="col-md-6">
            <p>
            We believe that developing a core capability to deploy machine learning models to mobile devices has the potential to open ESR to a world of opportunities beyond the scope of the CRISPR SSIF. This is an emergent and rapidly developing field where most industry expertise is limited to a few “low hanging fruit” use cases such as messaging apps deploying selfie segmentation. 

            </p>
          </div>
          <div className="col-md-6">
            <Image
              src={process.env.PUBLIC_URL + "/tube_images/8088.jpg"}
              className="rounded img-fluid w-100"
            />
          </div>
        </div>

        <p>
          {/* <Link to="/" className="btn btn-secondary"><ArrowLeft /> Return</Link> */}
          <Link to="/" className="btn btn-secondary"><House /> Home</Link>
          {' '}
          <Link to="/onboarding/2" className="btn btn-primary"><ArrowRight /> Continue</Link>
        </p>
      </div>
    </div>
  );
}

function Onboarding_2() {
  return (
    <div className="container px-5 py-5">
      <h2 className="display-2">More onboarding</h2>
      <p>
        The next couple of screens will test that your device is capable of running features which we want to develop in future versions of glo.
      </p>
      <p>
        First, glo will ask for your permission to access your phone's camera.
      </p>
      <p>
        Second, glo will check that it can access your location.
      </p>
      {/* <p><Link to="/onboarding/camera">Continue</Link></p> */}

      <p>
      <Link to="/" className="btn btn-secondary"><House /> Home</Link>
      {' '}
      <Link to="/onboarding/camera" className="btn btn-primary"><ArrowRight /> Continue</Link>
      </p>
    </div>
  );
}

export default function Onboarding() {
  const { id } = useParams();

  const [cameraOK, setCameraOK] = useState(null);
  const [locationOK, setLocationOK] = useState(null);
  const [message, setMessage] = useState("No message");

  function checkCamera() {
    if (navigator.mediaDevices.getUserMedia === null) {
      console.log("navigator.mediaDevices.getUserMedia === null");
      setMessage("navigator.mediaDevices.getUserMedia === null");
      setCameraOK(false);
      return;
    }

    var options = {
      video: true,
      audio: false,
    };

    navigator.getUserMedia(
      options,
      function (stream) {
        // close the stream
        stream.getTracks().forEach((x) => x.stop());
        setCameraOK(true);
        setMessage("looks good");
        return;
      },
      function (e) {
        console.log("background error : " + e.name);
        setMessage("background error : " + e.name);
        setCameraOK(false);
        console.warn(e);
        return;
      }
    );
  }

  function CameraStatus() {
    if (cameraOK === true) {
      return (
        <>
          <p className="my-5">
            Camera <CheckCircle color="green" alt="Camera permission okay" />{" "}
            <span style={{ color: "green" }}>okay</span>
          </p>

          <p className="my-5">
            <Link to="/onboarding/location" className='btn btn-primary'>Continue</Link>
          </p>
        </>
      );
    }

    if (cameraOK === null) {
      return (
        <>
          <p className="my-5">
            Camera{" "}
            <QuestionCircle color="blue" alt="Camera permission unknown" />{" "}
            <span style={{ color: "grey" }}>unknown</span>
          </p>
          <div>
            <button
              type="button"
              onClick={checkCamera}
              className="btn btn-primary"
            >
              <Camera />  Check
            </button>
          </div>
        </>
      );
    }
  }

  function DebugMessage() {
    return <p>Message: {message}</p>;
  }

  function Onboarding_Camera() {
    return (
      <div>
        <div className="container px-4 py-5">
          <h2 className="display-2">Camera</h2>
          <p>
            This page checks that <span className="glo">glo</span> has access to
            your device's camera.
          </p>

          <CameraStatus />
          <DebugMessage />
          
          {/* <p><Link to="/" className="btn btn-secondary"><ArrowLeft /> Return</Link></p> */}
          <p><Link to="/" className="btn btn-secondary"><House /> Home</Link></p>
        </div>
      </div>
    );
  }

  function checkLocation() {
    if (!"geolocation" in navigator) {
      console.log("geolocation not in navigator");
      setMessage("geolocation not in navigator");
      setLocationOK(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("received position");
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        setLocationOK(true);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
        setLocationOK(false);
      }
    );
  }

  function LocationStatus() {
    if (locationOK === null) {
      return (
        <div>
          <p>
            Location permission <QuestionCircle color="gray" /> unknown
          </p>
          <p>
            <button onClick={checkLocation} className="btn btn-primary">
              Check location
            </button>
          </p>
        </div>
      );
    }

    if (locationOK) {
      return (
        <div>
          <p>
            Location permission <CheckCircle color="green" />{" "}
            <span style={{ color: "green" }}>okay</span>
          </p>
          {/* <p><Link to="/">Return to main menu</Link> */}
        </div>
      );
    }

    // location not null or true --- error!
    return (
      <div>
        <p>
          Location permission <ExclamationCircle color="red" />{" "}
          <span style={{ color: "red" }}>error</span>
        </p>
      </div>
    );
  }

  function Onboarding_Location() {
    return (
      <div>
        <div className="container px-4 py-5">
          <h2 className="display-2">Location</h2>
          <p>
            This page checks that <span className="glo">glo</span> has access to
            your device's camera.
          </p>
          <LocationStatus />
          <p><Link to="/" className="btn btn-secondary"><House /> Home</Link></p>
        </div>
      </div>
    );
  }

  if (id === "location") {
    return Onboarding_Location();
  }
  if (id === "camera") {
    return Onboarding_Camera();
  }
  if (id === "2") {
    return Onboarding_2();
  }

  return Onboarding_1();
}
