import { useEffect, useRef } from "react";
import radians_to_degrees from "./radiansToDegrees";

export default function Canvas(props) {
  
    const canvasRef = useRef(null)
    
    if(props.img !== undefined) {
        // console.log("canvas has img");
        // console.log(props.img);
    }

    
    const draw = (ctx, new_width, new_height) => {

        ctx.drawImage(props.img, 0, 0, new_width, new_height);

        if (props.tube !== undefined) {


          // console.log("highlighting cluster", props.tube)
          window.props = props

          ctx.lineWidth = 2;
          ctx.strokeStyle = "aqua";
          
          const x = new_width  * props.tube.box[0]
          const y = new_height * props.tube.box[1]
          const w = new_width  * (props.tube.box[2] - props.tube.box[0])
          const h = new_height * (props.tube.box[3] - props.tube.box[1])

          ctx.strokeRect(x,y,w,h);

          // calc crop xy etc
          const cw = props.img.naturalWidth  * (props.tube.box[2] - props.tube.box[0])
          const ch = props.img.naturalHeight * (props.tube.box[3] - props.tube.box[1])
          const cx = props.img.naturalWidth  * props.tube.box[0] 
          const cy = props.img.naturalHeight * props.tube.box[1]
          const crop_wh_ratio = cw / ch

          // calc same xywh stats but with some padding.
          const padding = 0.1 // proportion of w+h to pad

          // these calcs are a bit more complicated, min/max is used to ensure the padding doesn't go <0 or >naturalWidth or naturalHeight
          const padded_x = Math.max(0, cx - (cw*padding))
          const padded_y = Math.max(0, cy - (ch*padding))

          // calculating width
          const padded_w = Math.min(props.img.naturalWidth,  cx + cw + (cw*padding)) - padded_x
          const padded_h = Math.min(props.img.naturalHeight, cy + ch + (ch*padding)) - padded_y

          const padded_wh_ratio = padded_w / padded_h


          // set up destination canvas area
          const dx = 200 // point to start drawing cropped version on canvas
          const dy = 0   //
          var dWidth = 530
          var dHeight = 200

          var dest_wh_ratio = dWidth / dHeight
          var msg = "xx"
          var crop_scale = 1

          if (dest_wh_ratio < padded_wh_ratio) {
            // limited by destination height
            crop_scale = dWidth/padded_w
            const crop_height = padded_h * crop_scale
            dHeight = crop_height
            msg = `W:${Math.round(dWidth)} Recalc'd height: ${Math.round(dHeight)}`

          } else {
            // limited by destination width
            
            crop_scale = dHeight/padded_h
            const crop_width = padded_w * crop_scale
            dWidth = crop_width
            msg = `Recalc'd width: ${Math.round(dWidth)} H:${Math.round(dHeight)}`
          }

          ctx.drawImage(props.img, padded_x, padded_y, padded_w, padded_h, dx, dy, dWidth, dHeight);
          ctx.font = "20px Arial";
          ctx.fillStyle = "red";
          ctx.fillText(msg, dx, 30)
          ctx.fillText(`P: ${padded_x},${padded_y} -> ${padded_w},${padded_h}`, dx, 60)

          // console.log('props.predictedtubes', props.predictedtubes)
          const xOutputScale = new_width / props.predictedtubes.width;
          const yOutputScale = new_height / props.predictedtubes.height;

          // console.log(`xOutputScale:${xOutputScale} yOutputScale:${yOutputScale}`)

          const center = props.tube.ellipse.getCenter()
          const axisLength = props.tube.ellipse.getAxisLength()


          const angle = radians_to_degrees(props.tube.ellipse.equation.angle)

          // radius x+y?
          const rx = Math.max(axisLength[0],axisLength[1])
          const ry = Math.min(axisLength[0],axisLength[1])

          // calc ellipse centre x+y
          const ex = center[0] * xOutputScale
          const ey = center[1] * yOutputScale

          // console.log(`Ellipse from ${Math.round(ex)},${Math.round(ey)} 
          // rx:${rx} ry:${ry} 
          // L1:${axisLength[0]} L2:${axisLength[1]}
          // angle:${angle}
          // xos:${xOutputScale} yos:${yOutputScale}`)
          
          ctx.lineWidth = 2;
          ctx.strokeStyle = "chartreuse";

          ctx.beginPath();
          ctx.ellipse(
              ex, 
              ey, 
              rx * xOutputScale, 
              ry * yOutputScale, 
              -props.tube.ellipse.equation.angle, 
              10, 
              350);
          ctx.stroke();

          // draw small dot at centre of ellipse
          ctx.strokeStyle = "red";

          ctx.beginPath();
          ctx.ellipse(
              center[0]* xOutputScale, 
              center[1]* yOutputScale, 
              2,
              2, 
              0, 
              10, 
              350);
          ctx.stroke();

          ctx.fillText(`E: ${Math.round(ex)},${Math.round(ey)} ${Math.round(angle*100)/100}deg`, dx, 120)
          ctx.fillText(`E: ${Math.round(center[0])},${Math.round(center[1])} ${Math.round(angle*100)/100}deg`, dx, 140)

          // finally, draw an overlay on top of the cut out.            
          createImageBitmap(props.predictedtubes.predicted_mask).then((sprites) => {
              // console.log(`drawingbitmap 0,0 => ${new_width},${new_height}`, sprites)

              // draw overlay on thumbnail
              ctx.drawImage(sprites, 0, 0, Math.round(new_width), Math.round(new_height))

              // // draw overlay on big img
              const xScale = props.predictedtubes.width / props.img.naturalWidth
              const yScale = props.predictedtubes.height / props.img.naturalHeight

              // console.log(`xScale: ${xScale} nw: ${props.img.naturalWidth} pw:${props.predictedtubes.width}`)
              // console.log(`X:${props.predictedtubes.width * padded_x / props.img.naturalWidth}`)


              ctx.drawImage(sprites, 
                props.predictedtubes.width * padded_x / props.img.naturalWidth,   // crop x on prediction
                props.predictedtubes.height * padded_y / props.img.naturalHeight, // crop y on prediction
                props.predictedtubes.width * padded_w / props.img.naturalWidth, 
                props.predictedtubes.height * padded_h / props.img.naturalHeight,  
                dx,
                dy, 
                dWidth, 
                dHeight
                )

            }
          )

        }
    }
    
    useEffect(() => {
      
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      
      // calc some padding?
      const natural_wh_ratio = props.img.naturalWidth / props.img.naturalHeight;
      const canvas_wh_ratio = props.width / props.height;

      var new_height = 1;
      var new_width = 1;

      // console.log(`Canvas fn w${canvas.width} h${canvas.height}`)

      if (natural_wh_ratio <= canvas_wh_ratio) {
        // console.log("Cpad x");
        new_height = canvas.height;
        new_width = canvas.height * natural_wh_ratio;
        // console.log(`newh:${new_height} neww:${new_width}`)
      } else {
        // pad h
        // console.log("Cpad h")
        new_width = canvas.width;
        new_height = canvas.width / natural_wh_ratio;
        // console.log(`Cnewh:${new_height} neww:${new_width}`)
      }
      // console.log(`Cnewh:${new_height} neww:${new_width}`)
      // console.log(props.img.naturalWidth + "x" + props.img.naturalHeight)

      // context.drawImage(props.img, 0, 0, new_width, new_height);

      //Our draw come here
      draw(context, new_width, new_height)
    }, [draw])
    
    return <canvas ref={canvasRef} {...props}/>
}