
import React, { createRef } from "react";
import AuthService from "./services/auth.service";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL, REMOTE_URL } from "../config/api";
import authHeader from "./services/auth-header"
import { ImageFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";

export default function Dashboard() {

    const [currentUser, setCurrentUser] = useState({forename:'unknown'});
    const [batches, setBatches] = useState([]);
    const [projects, setProjects] = useState([]);
    // const selectedProject = createRef();
    const [selectedProject, setSelectedProject] = useState(undefined);
    const [projectTitleLookup, setProjectTitleLookup] = useState({});

    // replace componentDidMount
    useEffect(() => {
        setCurrentUser(AuthService.getCurrentUser());


        // get batches
        axios.post(
            // url
            API_URL + "api/batches",
            // data
            {},
            // params
            {
                headers: Object.assign(authHeader())
            }
        ).then((response) => {
            setBatches(response.data.batches);
        })

        // get projects
        axios.get(
            // url
            API_URL + "api/projects",
            // params
            {
                headers: Object.assign(authHeader())
            }
        ).then((response) => {
            console.log(response.data)
            setProjects(response.data.projects);

            window.proj = response.data.projects;

            var lookup = {};
            response.data.projects.map((pr, i) => {
                lookup[pr.id] = pr.title;
            })

            setProjectTitleLookup(lookup);
        })

    },[])



    function BatchSummary(batch, i) {
        // console.log(batch)            
        const navigate = useNavigate();

        function LoadBatch() {
            const url = "/batch/" + batch.id
            console.log("Redirecting to " + url)
            // window.location.href = ";
            navigate(url)
        }

        var img_element = <>
            <ImageFill />
            <div>no image</div>
        </>

        if (batch.images.length > 0) {
            const image_url = REMOTE_URL + 'images/thumbnails/' + batch.userId + '/' + batch.images[0].id;

            img_element = <div>
                <img src={image_url} alt={batch.images[0].filename} style={{maxWidth:'100%',maxHeight:'100%'}} />
            </div>
        }


        // return <Link to={'batches/'+batch.id}>
        return <div className='row mb-2 batchRow p-2' onClick={LoadBatch} key={i}>
                <div className='col col-1'>{batch.id}.{i}</div>
                <div className='col col-3'>
                    {img_element}
                </div>
                <div className='col col-8'>
                    <div>{(batch.protocol ? batch.protocol.title : "Unknown protocol")}</div>
                    <div style={{ fontSize: '.7em', color: "orange" }}>Created: {batch.createdAt}</div>
                    <div style={{ fontSize: '.7em', color: "orangered" }}>Updated: {batch.updatedAt}</div>
                    {    selectedProject === undefined 
                      && batch.projectId !== undefined 
                      && batch.projectId > 0 
                      && <div style={{ fontSize: '.7em', color: 'grey' }}>{projectTitleLookup[batch.projectId]}</div> 
                    }
                </div>
            </div>
        // </Link>
    }

    
    const Batches = () => {
        if (batches.length == 0) {
            return <div>None</div>
        }
        
        const selected_project_id = (selectedProject == undefined ? "all" : selectedProject.id)

        var batches_to_show = batches;

        if (selected_project_id !== "all") {
            console.log("Filtering by project id=" + selected_project_id)
            batches_to_show = batches_to_show.filter(
                batch => {
                    return batch.projectId == selected_project_id
                }
            )
        }

        batches_to_show.sort((a,b) => {
            if (a.updatedAt > b.updatedAt) return -1;
            if (a.updatedAt < b.updatedAt) return 1;
            return 0;
        })

        return <div className='batches'>
            {batches_to_show.map(BatchSummary)}
        </div>
    }


    const Header = () => {
        return <div className='row'>
            <div className='col col-6'>
                <h1 className="glo display-1"><a href='/'>GLO</a></h1>
            </div>
            <div className='col col-6'>
                <div className='signedInInfo py-2 text-info'>
                    <div>{currentUser.forename}</div>
                    <Link to='/'>Home</Link>
                    {' | '}
                    <Link to='/benchmarking'>Benchmarking</Link>
                </div>
            </div>
        </div>
    }

    function renderDashboard() {
        if (! currentUser) {
            return(
                <div>Not logged in</div>
            )
        }

        const selectProjectEvent = (e) => {
            if (e.target.value == "all") {
                setSelectedProject(undefined);
            }

            const project = projects.find(
                proj => {
                    return proj.id == e.target.value
                })
            setSelectedProject(project);
        }

        const SelectProject = () => {
            console.info(projects)

            const project_options = projects.map((project, i) => {
                        // if(selectedProject !== undefined && project.id == selectedProject.id) {
                            
                        //     return <option key={i} value={project.id} selected='selected'>{project.title}</option>
                        // }
                        return <option key={i} value={project.id}>{project.title}</option>
                    })

            const value = (selectedProject !== undefined ? selectedProject.id : 'all')

            return <Form.Select aria-label="Filter by project" onChange={selectProjectEvent} value={value}>
                    <option value='all'>All projects</option>
                    {project_options}
                </Form.Select>
        }


        return(
            <div>
                <Header />
                <hr />
                <div className='row'>
                    <div className='col col-6'>
                        <Link to='/batch/new' className='btn btn-primary'>New batch</Link>
                    </div>
                    <div className='col col-6'>
                        <SelectProject />
                    </div>
                </div>
                <hr />
                <Batches />

            </div>
        )
    }

    return renderDashboard();
}