import { makeArray } from "./makeArray";

export default function combineMasks(predictions) {

    // create new array of xy using predictions.
    const single_prediction_h = Math.round(predictions[0].predicted_mask.length / (predictions[0].box[2] - predictions[0].box[0]))
    const single_prediction_w = Math.round(predictions[0].predicted_mask[0].length / (predictions[0].box[3] - predictions[0].box[1]))


    var combined_mask = makeArray(single_prediction_w,single_prediction_h,0)

    // for each of the sub-prediction windows, add the results to 'combined_mask'
    for (const p in predictions) {
        const prediction = predictions[p]
        // console.log(prediction)

        var y = Math.round(prediction.box[0] * single_prediction_h)

        for (const row_id in prediction.predicted_mask) {
            var x = Math.round(prediction.box[1] * single_prediction_w)

            const row = prediction.predicted_mask[row_id]
            for (const col_id in row) {
                const predicted_pixel = row[col_id]
                if (predicted_pixel > 0) {
                    combined_mask[y][x] = (
                        combined_mask[y][x] == 0 ? 
                        predicted_pixel : 
                        Math.min(combined_mask[y][x], predicted_pixel))
                }
                x++
            }

            y++
        }
    }

    return combined_mask
}