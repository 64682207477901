import { Component } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import AuthService from "../services/auth.service";

function SelectAssay({ props }) {

    const currentUser = AuthService.getCurrentUser();


    const ui_header = <div className='row'>
        <div className='col col-6'>
            <h1 className="glo display-1">GLO</h1>
        </div>
        <div className='col col-6'>
            <div className='signedInInfo py-2 text-info'>
                <div>{currentUser.forename}</div>
                <Link to='/dashboard'>Dashboard</Link>
                {' | '}
                <Link to='/benchmarking'>Benchmarking</Link>
            </div>
        </div>
    </div>

    return <div>
        {ui_header}
        <h1>Select assay:</h1>
        <div>
            <small style={{
                backgroundColor: 'aliceblue',
                color: 'maroon',
                padding: '5px 10px',
                fontWeight: 'bold',
            }}>NB - this list is currently hardcoded but will ultimately be populated based on user + organisation profile</small>
        </div>

        <Container style={{
            marginTop: '10px'
        }}>
            <Row>
                <Col>
                    <h2>Respiratory</h2>
                    <Link to="/capture/covid" style={{
                        backgroundColor: '#FFFF1199',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0',
                        color: 'black',
                        textAlign: 'center',
                        // width: '70%'
                    }}>Covid</Link>
                    <Link to="/capture/influenza" style={{
                        backgroundColor: '#FFEE1199',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0 .125em',
                        color: 'black',
                        textAlign: 'center',
                    }}>Influenza</Link>
                    <Link to="/capture/covid" style={{
                        backgroundColor: '#FFCC2299',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0 .125em',
                        color: 'black',
                        textAlign: 'center',
                    }}>RSV</Link>
                    {/* <Link to="/capture/rheumatic_fever" style={{
                        backgroundColor: '#FFCC1199',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0 .125em',
                        color: 'black',
                        textAlign: 'center',
                    }}>Rheumatic Fever<br /><div style={{
                        fontSize: '.5em'
                    }}>(Strep A)</div></Link> */}
                    {/* <Link to="/capture/strep"  style={{
                        backgroundColor: '#FFBB0099',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0 .125em',
                        color: 'black',
                        textAlign: 'center',
                        // width: '70%'
                    }}>Strep<span style={{color:'#999999'}}>tococcus</span></Link> */}
                
                
                </Col>
                <Col>
                    {/* <h3>Water</h3>
                    <Link to="/capture/kai_moana"  style={{
                        backgroundColor: '#6699FF99',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0',
                        color: 'black',
                        textAlign: 'center',
                        // width: '70%'
                    }}>Kai Moana</Link>
                    <Link to="/capture/freshwater"  style={{
                        backgroundColor: '#AA99FF99',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0',
                        color: 'black',
                        textAlign: 'center',
                        // width: '70%'
                    }}>Freshwater</Link> */}

                    <h3>Health</h3>
                    <Link to="/capture/rheumatic_fever" style={{
                        backgroundColor: '#FFDD2299',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0 .125em',
                        color: 'black',
                        textAlign: 'center',
                    }}>Rheumatic Fever <div style={{
                        fontSize: '.5em'
                    }}>(Strep A)</div></Link>
                    {/* <Link to="/capture/chlamydia" style={{
                        backgroundColor: '#FF771199',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0 .125em',
                        color: 'black',
                        textAlign: 'center',
                    }}>Chlamydia</Link>
                    <Link to="/capture/gonorrhea" style={{
                        backgroundColor: '#FF441199',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0 .125em',
                        color: 'black',
                        textAlign: 'center',
                    }}>Gonorrhea</Link>
                    <Link to="/capture/Hep C" style={{
                        backgroundColor: '#FF331199',
                        padding: '10px',
                        fontSize: '2em',
                        display: 'block',
                        margin: '.125em 0 .125em',
                        color: 'black',
                        textAlign: 'center',
                    }}>Hep C</Link> */}

                
                </Col>
            </Row>
        </Container>

    </div>
}


export default SelectAssay