
import React, { Component, useEffect, useRef, useState } from "react";
import AuthService from "./services/auth.service";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL, REMOTE_URL, MODEL_URL } from "../config/api";
import authHeader from "./services/auth-header"
import { FiletypeXlsx, Save, Speedometer2, Trash } from "react-bootstrap-icons"
import { filesize } from "filesize";
import * as tf from "@tensorflow/tfjs"
import Chart from "react-apexcharts";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {get_chart_data_memory, get_chart_data_times} from './helpers/benchmarkCharts'
import { benchmarkImages } from "./helpers/benchmarkImages";
// import ProgressBar from 'react-bootstrap/ProgressBar';

// export default function Benchmarking() {
// class Benchmarking extends Component {
const Benchmarking = () => {
    const modelSelector = useRef(null);
    const device = useRef("");
    const not_logged_in_user = useRef("")

    const [benchmarks, setBenchmarks] = useState([])
    const [currentUser, setCurrentUser] = useState();
    const [currentModel, setCurrentModel] = useState();
    const [net, setNet] = useState(undefined);
    const [netStatus, setNetStatus] = useState('idle');
    const [progress, setProgress] = useState(0);
    const [durationLoadingModel, setDurationLoadingModel] = useState();
    const [imageFileCache, setImageFileCache] = useState({}); // cache of image files
    const [images, setImages] = useState([]); // image metadata
    const [visionmodels, setVisionmodels] = useState([]);
    const [showBenchmarks, setShowBenchmarks] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);


    const TUBE_SEGMENTATION_COLOURS = [
        [200, 220, 220, 30],   // background - grey
        [255, 0, 0 , 128],      // tip
        [80, 160, 255, 128]     // tube
      ]



    // componentDidMount()
    useEffect(() => {
        const user = AuthService.getCurrentUser()
        setCurrentUser(user);

        // load vision models
        axios.get(
            // url
            API_URL + "api/visionmodels",
            // data
            {},
            // extra stuff
            {
                headers: Object.assign(authHeader())
            }
        ).then((response) => {
            var models = response.data

            if (user === null) {
                models = models.filter(function(m) { return m.public_benchmarking_order !== null })
                models = models.sort((a,b) => a.public_benchmarking_order - b.public_benchmarking_order)

                // rename the models, prepending with the public_benchmarking_order
                for (var i = 0; i < models.length; i++) {
                    const model = models[i]
                    models[i].modelname = `${i+1}. ${model.modelname}`
                }
            }
            console.info(models)
            console.warn('user',user)


            setVisionmodels(models);
        });
    },[])

    // if (! currentUser) {
    //     return(
    //         <div>Not logged in</div>
    //     )
    // }

    
    // imageData could be from images, but due to async nature it's best to just include it in the function params.
    const loadImagesToMemory = async (imageData) => {
        console.log(`Loading ${imageData.length} images to memory.`);
        console.log(imageData);

        var newImageFileCache = imageFileCache;
        
        

        for (let i = 0; i < imageData.length; i++) {
            const img = imageData[i]

            const src = REMOTE_URL + "images/preview/" + img.userId + "/" + img.id + "/" + Math.min(1000,img.width) + "/" + Math.min(1000,img.height);
            const wh_key = `${img.width}x${img.height}`

            // check if already in cache?
            if (newImageFileCache[img.id] === undefined) {
                // console.log(`Loading ${img.id}`)
                await getImagePromise(src)
                    .then(im => {
                        // Save both.
                        newImageFileCache[img.id] = im;
                    })
            }
            progressEvent((i+1) / imageData.length);
        }

        // console.log('saving to cache')
        setImageFileCache(newImageFileCache);
        setNetStatus('ready')
    }

    // load a list of images associated with this model's project.
    function loadProjectImages(currentProjectId = 0) {
        if (currentProjectId == 0) {
            currentProjectId = (currentModel !== undefined ? currentModel.projectId : modelSelector.current.value)
        }

        console.log("Loading images associated with project " + currentProjectId);

        // load vision models
        axios.get(
            // url
            API_URL + "api/images/project/" + currentProjectId,
            // data
            {},
            // extra stuff
            {
                headers: Object.assign(authHeader())
            }
        ).then((response) => {
            console.info("recieved x images:")
            console.log(response)

            setImages( response.data );

            loadImagesToMemory(response.data); //, 224, 224);
        });
    }

    const GloProgressBar = (props) => {        
        const className = "progress-bar progress-bar-striped bg-" + (props.variant ? props.variant : "info")

        return <div className="progress">
            <div
                id='glo_progressbar'
                className={className}
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
                >
            {progress}%
            </div>
        </div>
    }
    
    const progressEvent = (progressFraction) => {
        var percentCompleted = Math.round( 100 * progressFraction );

        if (percentCompleted !== progress) {
            setProgress(percentCompleted);
            const progress_element = document.getElementById("glo_progressbar")
            if (progress_element != undefined) {

                progress_element.style.width = `${percentCompleted}%`;
                progress_element.setAttribute('aria-valuenow',`${percentCompleted}%`)
                progress_element.innerHTML = `${percentCompleted}%`
            }
            console.log(percentCompleted + '%');
        }
    }

    const loadModel = () => {

        if (modelSelector.current.value === "") {
            if (net !== undefined) {
                tf.dispose(net)
            }
            setNet(undefined);
            setCurrentModel(undefined);
            setNetStatus('idle');
            setBenchmarks([]);
            setShowBenchmarks(false);
            return;
        }


        // search visionmodels by id  
        var model = visionmodels.find(obj => {
            return obj.id == modelSelector.current.value
        })
        console.log(model);

        // const modelUrl = process.env.PUBLIC_URL + "/" + model.path + "/model.json";
        const modelUrl = MODEL_URL + "" + model.path + "/model.json";

        if (net !== undefined) {
            tf.dispose(net)
        }
        setNet(undefined);
        setCurrentModel(model);
        setNetStatus('loading');
        setBenchmarks([]);
        setShowBenchmarks(false);
        // record timestamp before loading model.
        const ts_loading_start = performance.now();
        const localKey = `indexeddb://net_${model.id}`;


        // define callback once net loaded
        const onModelLoadCallback = (net, saveToLocal) => {

            const ts_loading_end = performance.now();
            const duration_loading = ts_loading_end - ts_loading_start;
            console.log("loaded model in " + duration_loading + 'ms');
            setDurationLoadingModel(duration_loading)
            setNet(net)
            window.net = net;
            window.tf = tf;
            
            // save net to localstorage
            if (saveToLocal) {
                console.log(`Saving model to ${localKey}`)
                net.save(localKey)
                .then(() => {
                    console.log(`saved to ${localKey}`)
                });
            }

            progressEvent(0);
            setNetStatus('loading_images_to_cache');
            loadProjectImages(model.projectId);
        }

        tf.loadLayersModel(localKey)
            .then(net => {
                console.log("loaded model from " + localKey);
                onModelLoadCallback(net, false);
            })
            .catch(err => {
                console.log("not found - loading model from URL.")
                tf.loadLayersModel(modelUrl, {
                    onProgress: progressEvent
                }).then(net => {
                    onModelLoadCallback(net, true);
                })
            })
    }

    const Header = () => {
        return (
            <div className='row'>
                <div className='col col-6'>
                    <h1 className="glo display-1">GLO</h1>
                </div>
                <div className='col col-6'>
                
                    <div className='signedInInfo py-2 text-info'>
                        {   currentUser 
                            ?
                                <div>
                                    <div>{currentUser.forename}</div>
                                    <Link to='/'>Home</Link>
                                    {' | '}
                                    <Link to='/dashboard'>Dashboard</Link>
                                </div>
                            :
                                <div>
                                    <div>Not logged in</div>
                                    <Link to='/'>Home</Link>
                                    {' | '}
                                    <Link to='/login'>Login</Link>
                                </div>
                        }
                        <div className='row pt-1'>
                            <Link to='/clear_cache'>
                                <button className='btn btn-warning'><Trash /> Clear cache</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            );
    }

    const openSaveBenchmarksModal = () => {
        console.log('openSaveBenchmarksModal')
        // useEffect(() => {
        //     setShowSaveModal(true);
        // }, []);
        setShowSaveModal(true);
    }

    // status of whether the neural net has loaded
    const NetStatus = () => {
        if (netStatus == 'loading') {
            return <div>
            {/* <div className="spinner-border text-info" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> */}
            
            <GloProgressBar variant="info" />
            </div>
        }

        if (netStatus == 'ready') {
            const saveButton = (
                    benchmarks.length > 0
                    ? <button className='btn btn-success ml-3' onClick={openSaveBenchmarksModal}><Save /> Save Benchmarks</button>
                    : null
                )

            return <div>
            <button onClick={runBenchmark} className='btn btn-primary'><Speedometer2 /> Run benchmark on {images.length} images</button>
            {' '}
            {saveButton}
            </div>
        }

        

        if (netStatus == 'loading_images_to_cache') {
            return <div>
                <p>caching images</p>
                <GloProgressBar variant="success" />

            </div>
        }

        if (netStatus == 'benchmarking') {
            return <div>
                <GloProgressBar variant="warning" />

            </div>
        }

        return <div className='info'>{netStatus}</div>
    }


    const ModelSelector = () => {
        if (visionmodels.length == 0) {
            return(<div>no models</div>);
        }
        const modelOptions = visionmodels.map((obj, i) => {
            return(<option key={i} value={obj.id}>{obj.modelname}</option>);
        })

        return(<div>
            {visionmodels.length} models: {' '}
            <Form.Select aria-label={`${visionmodels.length} models:`} ref={modelSelector} value={currentModel && currentModel.id ? currentModel.id : ""} onChange={loadModel}> 
                <option value="">Select a model...</option>
                {modelOptions}
            </Form.Select>

        </div>);
    }

    const CurrentModelMetadata = () => {
        if (! currentModel) {
            return <>load a model to show metadata</>
        }

        return(
            <div className='alert alert-info mt-2' >
                <h3>{currentModel.modelname}</h3>
                <p>{currentModel.description}</p>
                <p>Model size: {filesize(currentModel.filesize_bytes, {round: 1})}</p>
                <NetStatus />
            </div>
        );
    }

    // add Promise around img.onload:
    const getImagePromise = (src) => {
        return new Promise((resolve, reject) => {
          let img = new Image()
          img.onload = () => resolve(img)
          img.onerror = reject
          img.crossOrigin = "anonymous";
          img.src = src
        })
    }

    
    const runBenchmark = async (e) => { 
        setNetStatus('benchmarking');
        progressEvent(0);

        const n = 2 // max number of column/windows in sliding windows

        const nWindows = n * (n+1) * (2 * n + 1) / 6
        const nTotalWindows = nWindows * Object.keys(imageFileCache).length

        const boxes = [];

        // calculate bins
        for (var nRows = 1; nRows <= n; nRows++) {
            const step = 1/nRows;

            for (var row = 0; row < nRows; row++) {
                for (var col = 0; col < nRows; col++) {
                    const box = [step*row, col*step, (row+1)*step, (col+1)*step ]
                    boxes.push(box)
                }
            }
        }
        // console.log("boxes",boxes)

        // console.log(`Running benchmarks on ${images.length} images, max ${n}x${n} windows (${nWindows} per image) = ${nTotalWindows} total`)
        // console.log(e)
        setShowBenchmarks(false);

        // const wh_key = `${w}x${h}`;

        // console.log(imageFileCache);
        window.imageFileCache = imageFileCache;

        const benchmark_queue = [...images];

        // console.log("QUEUE:")
        // console.info(benchmark_queue)

        // console.warn("TF Memory at start", tf.memory())


        // Prevents OutOfMemory - forces TFJS to clear WebGL textures when reaching 256Mb
        // tf.env().set("WEBGL_DELETE_TEXTURE_THRESHOLD", 256000000);
        tf.env().set("WEBGL_DELETE_TEXTURE_THRESHOLD", 100000000);

        const callback = () => {

            const final_callback = (benchmark_results) => {
                // console.warn("TF Memory at end", tf.memory())
                setBenchmarks(benchmark_results)
        
        
                console.log(`Received ${benchmark_results.length} benchmarks`);
                setShowBenchmarks(true);
                setNetStatus('ready');
            }




            const benchmark_results = tf.tidy(() => benchmarkImages(net, benchmark_queue, boxes, imageFileCache, currentModel.normalise, final_callback))

            
        }

        
        console.log("Pausing for 1 second...")

        setTimeout(() => {

            console.log("resuming...")
            tf.tidy(() => callback())
        },1000);

        
        console.log("end of main thread :-)")

        // callback();


    }



    
    const saveBenchmarks = () => {
        console.log("Saving benchmarks");

        localStorage.setItem("device_last_saved", JSON.stringify(device.current.value));

        if (not_logged_in_user.current.value !== '') {
            localStorage.setItem("not_logged_in_user_text", JSON.stringify(not_logged_in_user.current.value));
        }
        

        const data = {
            benchmarks: benchmarks,
            device: device.current.value,
            userId: (currentUser ? currentUser.id : null),
            modelId: currentModel.id,
            load_time_ms: durationLoadingModel,
            not_logged_in_user: not_logged_in_user.current.value
        }

        return axios
            .post(
                API_URL + "api/benchmarking", 
                data,
                { 
                    headers: Object.assign(authHeader())
                }
                )
            .then(response => {
                console.log("recieved response:")
                console.log(response)
                setBenchmarks([]);
                setShowBenchmarks(false);
                setShowSaveModal(false);
            })
            .catch(err => {
                console.warn(err);


                if (err.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else if (err.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(err.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', err.message);
                }

            })
    }

    const SaveModal = () => {

        const closeModal = () => {
            console.log("closing modal")
            setShowSaveModal(false)
        }

        const device_last_saved = (
            localStorage.getItem("device_last_saved") === null
            ? ""
            : JSON.parse(localStorage.getItem("device_last_saved"))
            );

        
        var not_logged_in_user_UI = null
        if (currentUser === null) {
            const not_logged_in_user_text = (
                localStorage.getItem("not_logged_in_user_text") === null
                ? ""
                : JSON.parse(localStorage.getItem("not_logged_in_user_text"))
                );

            not_logged_in_user_UI = <Form.Group controlId='saveModal.not_logged_in_user'>
                    <Form.Label className='pt-2'>
                        User (optional)
                    </Form.Label><br />
                    <Form.Text className='text-muted'>
                      As you're not logged in, you're invited to provide a name and/or email which helps us understand who's been helping us :-)
                    </Form.Text>
                    <Form.Control as='textarea' rows={2} autoFocus ref={not_logged_in_user} defaultValue={not_logged_in_user_text} maxLength='250' />
                </Form.Group>
        }


        return(
            <Modal show={showSaveModal} onHide={closeModal}> 
                <Modal.Header closeButton>
                    <Modal.Title>Save benchmarks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId='saveModal.device'>
                            <Form.Label>
                                Device
                            </Form.Label>
                            <Form.Control as='textarea' rows={2} autoFocus ref={device} defaultValue={device_last_saved}  maxLength='250'/>
                        </Form.Group>
                        {not_logged_in_user_UI}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-primary' onClick={saveBenchmarks}><Save /> Save benchmarks</button>
                </Modal.Footer>
            </Modal>
        )
    }

    // const downloadBenchmarks = () => {
    //     console.warn("Download benchmarks called")
    //     setProgress(0)
    //     setNetStatus('downloading')

    //     axios.get(
    //         API_URL + "api/get_benchmarks",
    //         {
    //             headers: Object.assign(authHeader())
    //         }
    //     ).then((response) => {
    //         console.info("received response", response)
    //     }).catch((err) => {
    //         console.warn("error", err)
    //     })


    // }


    const Benchmarks = () => {
        if (! showBenchmarks) {
            return(null);
        }

        window.benchmarks = benchmarks

        const chart_data_times = get_chart_data_times(benchmarks);
        const chart_data_memory = get_chart_data_memory(benchmarks);

        return <div>
            <h3>Benchmark results from {benchmarks.length} predictions:</h3>
            <Chart
              options={chart_data_times.options}
              series={chart_data_times.series}
              type="bar"
              width="100%"
              height= "400px"
            />
            <h4>GPU memory usage</h4>
            
            <Chart
              options={chart_data_memory.options}
              series={chart_data_memory.series}
              type="bar"
              width="100%"
              height= "400px"
            />
        </div>
    }

    return (
        <>
            <Header />
            <h1>Benchmarking <Speedometer2 /></h1>
            <ModelSelector/>
            <CurrentModelMetadata />
            <div id='benchmark_chart' />
            <Benchmarks />
            <SaveModal />
        </>
        );

};

        
        
            
        
export default Benchmarking;