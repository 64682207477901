    // const CanvasOverlay = (props) => {
    //     return useMemo(() => {
    //         console.info("Updating ImageCanvases.")
    //         return <canvas id='myCanvasOverlay' ref={canvasOverlay} style={{position: "absolute" }} width={canvasWidth} height={canvasHeight}></canvas>
    //     },[props.predictedtubes])
    // }

import { useEffect, useRef } from "react"
import radians_to_degrees from "./radiansToDegrees"

import * as tf from "@tensorflow/tfjs"
import * as KMeans from "tf-kmeans"

export const CanvasOverlay = (props) => {
    console.log("CanvasOverlay - props:", props)

    const canvasRef  = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')

        // if no predicted tubes, just print a msg and return.
        if (props.predictedtubes === null) {
            const msg = `No predicted tubes  ${Date().toLocaleString()}`
            ctx.font = "12px Arial";
            ctx.fillStyle = "red";
            ctx.fillText(msg, 10, 20)
            return
        }

        const canvas_wh_ratio = props.width / props.height;        
        const pXscale = props.width / props.predictedtubes.width
        const pYscale = props.height / props.predictedtubes.height
        const padding_x = props.width * 0.015
        const padding_y = props.height * 0.015


        

        // convert img to a rgb array.
        const rgb_array = tf.tidy(() => {
            var originalTensor = tf.browser.fromPixels(props.img, 3);
            const rgbs = originalTensor.arraySync()
            tf.dispose(originalTensor)
            return rgbs
        })


        const analysePixels = (points, ctx, box) => {
            // console.log("analysePixels:", points)


            const pixel_rgbs = []

            // console.log(`RGBARRAY w:${rgb_array[0].length} h:${rgb_array.length}`, rgb_array)
    
            const nSteps = 100
            const xStep = (points.x2-points.x1)/(nSteps-1)
            const yStep = (points.y2-points.y1)/(nSteps-1)

            for (var i = 0; i < nSteps; i++) {
                const px = Math.round(points.x1 + i * xStep)
                const py = Math.round(points.y1 + i * yStep)

                // ctx.lineWidth = 2;
                // ctx.strokeStyle = '#FFFFFF88';

                // // Start a new Path
                // ctx.beginPath();
                // ctx.moveTo(px-2, py-2);
                // ctx.lineTo(px+2, py+2);
                
                // ctx.moveTo(px+2, py-2);
                // ctx.lineTo(px-2, py+2);

                // // console.log(`line ${box.x + i * box.w/nSteps}, ${box.y+box.h-5} to ${box.x + (i+1) * box.w/nSteps}`)
                // // Draw the Path
                // ctx.stroke();


                const rgb_x = Math.round(  px * rgb_array[0].length / props.width )
                const rgb_y = Math.round(  py * rgb_array.length / props.height )

                // console.log(` rgbx:${rgb_x},${rgb_y}`)

                // get rgb for this pixel
                const rgb = rgb_array[rgb_y][rgb_x]
                pixel_rgbs.push(rgb)

                // console.info(`step ${i} ${px},${py} rgbx:${rgb_x},${rgb_y}`, rgb)

                // // draw this pixel back on to the box
                // ctx.lineWidth = 8;
                // ctx.strokeStyle = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;

                // // Start a new Path
                // ctx.beginPath();
                // ctx.moveTo(box.x + i * box.w/nSteps, box.y+box.h-5);
                // ctx.lineTo(box.x + (i+1) * box.w/nSteps, box.y+box.h-5);

                // // console.log(`line ${box.x + i * box.w/nSteps}, ${box.y+box.h-5} to ${box.x + (i+1) * box.w/nSteps}`)
                // // Draw the Path
                // ctx.stroke();
            }

            // run KMeans on the rgbs ------------------------------
            

            const k = 3

            const kmeans = new KMeans.default({
                k: k,
                maxIter: 30,
                distanceFunction: KMeans.default.EuclideanDistance
            });
            const dataset = tf.tensor(pixel_rgbs);
            const predictions_tensor = kmeans.Train(dataset);
    
            const predictions = predictions_tensor.arraySync()
            const centroids_tensor = kmeans.Centroids()
    
            var centroids = centroids_tensor.arraySync()
            // round rgb values in centroids to ints
            centroids = centroids.map(a2 => a2.map(v => Math.round(v)))
    
            tf.dispose(dataset)
            tf.dispose(predictions_tensor)
            tf.dispose(centroids_tensor)
    
            console.log("KMPredictions", predictions)
    
    
            // console.log("Assigned To ", predictions);
            console.log("Centroids Used are ", centroids);
            console.log("Prediction for Given Value is");

            const occurrences = predictions.reduce(function (acc, curr) {
                return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
              }, {});

            console.info("occurrences", occurrences)

            // display k clusters top right
            var nGlowing = 0

            for (var cluster_id = 0; cluster_id < k; cluster_id++) {
                const n = occurrences[cluster_id]
                const rgb = centroids[cluster_id]

                
                const c_box_w = (box.w * 2/3)/k
                const c_box_x = box.x + box.w/3 + (cluster_id * c_box_w)

                const c_box_y = box.y
                const c_box_h = box.h / 5

                // // Draw rectangle
                // ctx.beginPath();
                // ctx.lineWidth = "4";
                // ctx.fillStyle = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
                // ctx.fillRect(c_box_x, c_box_y, c_box_w, c_box_h);
                // ctx.stroke();

                // // draw %
                // ctx.font = "14px Arial";
                const glowing = (rgb[0] > 210)

                // ctx.fillStyle = (glowing ? "#000000FF" : "#FFFFFFFF")

                if (glowing) {
                    nGlowing = nGlowing + n
                }
                // ctx.textBaseline = "top"
                // ctx.fillText(`${Math.round(100 * n / nSteps)}%` , c_box_x+2, c_box_y+c_box_h*.1)
            }

            // return proportion of pixels which are "glowing".
            return (nGlowing / nSteps)

        }


        const drawTube = (tube, i) => {
            const x = props.width * tube.box[0] - padding_x
            const y = props.height * tube.box[1] - padding_y
            const w = (props.width * tube.box[2]) - x + padding_x
            const h = (props.height * tube.box[3]) - y + padding_y
            
            // // // draw imageMask here.
            // createImageBitmap(tube.hq_prediction.mask_image).then((sprites) => {  
            //     // draw overlay on thumbnail
            //     ctx.drawImage(sprites, x, y, w, h)
            // })

            var proportion_glowing = -1

            // draw ellipse + plot line
            if (true) {
                console.log(`tube ${i}`,tube)

                // draw ellipse?!
                const center = tube.hq_prediction.ellipse.getCenter()

                // place an X at the centre of the ellipse
                const cx = x + (center[0] / tube.hq_prediction.model_dimensions.w) * w
                const cy = y + (center[1] / tube.hq_prediction.model_dimensions.h) * h

                // mark the tip
                const center_tip = tube.hq_prediction.tip_ellipse.getCenter()

                // place an X at the centre of the ellipse
                const cx_tip = x + (center_tip[0] / tube.hq_prediction.model_dimensions.w) * w
                const cy_tip = y + (center_tip[1] / tube.hq_prediction.model_dimensions.h) * h


                // ctx.lineWidth = 3;
                // ctx.strokeStyle = "#0000FFCC";

                // // Start a new Path
                // ctx.beginPath();
                // ctx.moveTo(cx_tip-5, cy_tip-5);
                // ctx.lineTo(cx_tip+5, cy_tip+5);

                // ctx.moveTo(cx_tip+5, cy_tip-5);
                // ctx.lineTo(cx_tip-5, cy_tip+5);

                // // Draw the Path
                // ctx.stroke();


                // Draw a line between tip + tube centers
                // ctx.strokeStyle = "#FF00FFCC";

                // calc length of a line between cx,cy and cx_tip,cy_tip
                const tip_end_x = cx - 1.5 * (cx-cx_tip)
                const tip_end_y = cy - 1.5 * (cy-cy_tip)

                // // Start a new Path
                // ctx.beginPath();
                // // ctx.moveTo(cx_tip, cy_tip);
                // ctx.moveTo(tip_end_x, tip_end_y);
                // ctx.lineTo(cx, cy);

                // // Draw the Path
                // ctx.stroke();

                if (tip_end_x !== undefined & tip_end_y !== undefined & tip_end_x >= 0 & tip_end_y >= 0) {
                    proportion_glowing = analysePixels({x1: tip_end_x, y1: tip_end_y, x2: cx, y2: cy}, ctx, {x:x,y:y,w:w,h:h})
                }
            }
            
            ctx.font = "bold 26px Arial";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle"

            if (proportion_glowing > .2) {
                ctx.lineWidth = 4;
                const gradient = ctx.createLinearGradient(x, y, x+w, y+h);
                gradient.addColorStop("0", "magenta");
                gradient.addColorStop("0.5", "#FF0000");
                gradient.addColorStop("1.0", "orange");

                ctx.fillStyle = gradient;

                ctx.fillText(`+VE` , x+w/2, y+h/2)

                ctx.strokeStyle = "#FF6666";  
                ctx.fillStyle = "#FF6666"
            } 
            else {
                ctx.lineWidth = 2;
                ctx.strokeStyle = "#FFFFFF99";
                ctx.fillStyle = "#FFFFFF99";  
                // ctx.fillText(`-VE` , x+w/2, y+h/2)
            }
            
            ctx.textBaseline = "top"
            ctx.font = "18px Arial";
            ctx.strokeRect(x, y, w, h);
            ctx.textAlign = "left";
            ctx.fillText(`${i+1}` , x+6, y+4)
        }

        // createImageBitmap(props.predictedtubes.predicted_mask).then((sprites) => {
        //     // draw overlay on thumbnail
        //     ctx.drawImage(sprites,0,0,props.width, props.height)
        // })
        
        // draw tubes on to the canvas.
        props.predictedtubes.tube_clusters.forEach((tube,i) => {
            drawTube(tube, i)    
        });

        // print a timestamp msg on ctx
        const msg = Date().toLocaleString()
        ctx.font = "12px Arial";
        ctx.fillStyle = "chartreuse";
        ctx.textBaseline = "top"
        ctx.fillText(msg, 10, 20)



    }, [canvasRef, props.predictedtubes])

    

    return <canvas ref={canvasRef} id={props.id} style={props.style} width={props.width} height={props.height}></canvas>
}