import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Bicycle, BoxArrowInRight, CameraReels, PersonBoundingBox, QuestionCircleFill, RecordBtn, Rocket } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";

import AuthService from "./components/services/auth.service";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Link,
  useRouteError
} from "react-router-dom";

import ClearCache from "./components/clear_cache";
import Image from "react-bootstrap/Image";
import Onboarding from "./components/onboarding.jsx";
import Legal from "./components/legal";
import Terms from "./components/terms";
import Login from "./components/login";
import Logout from "./components/logout";
import Register from "./components/register";
import Dashboard from "./components/dashboard";
import Batch from "./components/batch";
import Batch2 from "./components/batch2";
import Benchmarking from "./components/benchmarking";
import Test from "./components/test";
import ModelAdmin from "./components/model_admin";
import BodyPixDemo from "./components/demos/bodyPix"
import GloVideoDemo from "./components/demos/glo_video";
import About from "./about/about";
import GloAnnotate from "./components/demos/annotate";
import AnnotationList from "./components/annotation_list.js";
import AnnotationUI from "./components/annotationUI.js";
import GloObjectDemo from "./components/demos/glo_object_demo.js";
import GloObjectTubesDemo from "./components/demos/glo_object_tube_demo.js";
import DemoOnnx from "./components/demos/demo_onnx.js";
import SelectAssay from "./components/app_single_assay/select_assay.jsx";
import CaptureSingleTube from "./components/app_single_assay/capture_single_tube.jsx";
import QRScanner from "./components/app_single_assay/scan_qr.jsx";

function Root() {
  const image_uri = `${process.env.PUBLIC_URL }/tube_images/8088.jpg`

  const currentUser = AuthService.getCurrentUser();
  console.info("Current user:")
  console.info(currentUser)

  return (
    <div className="container vh-100 d-flex flex-column gloBody" >
      <div className='row'>
        <div className='col col-6'>
          <h1 className="glo display-1">GLO</h1>
        </div>
        <div className='col col-6'>
          {
            currentUser
            ? <div className='signedInInfo py-2 text-info'>
                <div>{currentUser.forename}</div>
                <Link to='dashboard'>Dashboard</Link>
                {' | '}
                <Link to='benchmarking'>Benchmarking</Link>
              </div>
            : <div className='signedInInfo py-2 text-info'>
                <div>Not signed in</div>
                <Link to='benchmarking'>Benchmarking</Link>
              </div>
          }
        </div>
      </div>
      

      

      {/* {
        currentUser
        ?
        <div className="alert alert-info" role="alert">
          Signed in as {currentUser.forename}
        </div>

        :
        <div className="alert alert-info" role="alert">
          Welcome to GLO!
        </div>
      } */}




      {/* <Image src={image_uri} fluid='true' /> */}

      
      <div className="row">
        <div className="col-md-4 py-3">
          <Link
            to="/about"
            className="btn btn-primary py-3"
            style={{ width: "100%" }}
          >
            <QuestionCircleFill /> About
          </Link>
        </div>
        <div className="col-md-4 py-3">
          <Link
            to="/onboarding/1"
            className="btn btn-primary py-3"
            style={{ width: "100%" }}
          >
            <Bicycle /> Onboarding
          </Link>
        </div>
        <div className="col-md-4 py-3">
          {
            currentUser 
            ?
            <Link
                  to="/logout"
                  className="btn btn-primary py-3"
                  style={{ width: "100%" }}
                ><BoxArrowInRight /> Sign out
              </Link>
            :
            <Link
                to="/login"
                className="btn btn-primary py-3"
                style={{ width: "100%" }}
              >
                <BoxArrowInRight /> Sign in
              </Link>
          }
        </div>
      </div>

      <video 
        // width={size.width} 
        // height={size.height} 
        // ref={videoRef}
        poster={`/app_videos/what_is_crispr.jpeg`}
        autoPlay muted loop disablePictureInPicture playsInline 
        style={{zIndex: 400}}>
                <source src={`/app_videos/about_what_is_crispr_001_lo.mp4`} type='video/mp4' />
                Your browser doesnt support this video.
            </video>



      <div className="row">
        <div className="col-md-6 py-3">
          <Link
            to="/demos/bodypix"
            className="btn btn-secondary py-3"
            style={{ width: "100%" }}
          >
            <PersonBoundingBox /> Demo - real time body segmentation
          </Link>
        </div>
        <div className="col-md-6 py-3">
          <Link
            to="/demos/video"
            className="btn btn-secondary py-3"
            style={{ width: "100%" }}
          >
            <CameraReels /> Demo - video tube segmentation
            
          </Link>
        </div>
        <div className="col-md-6 py-3">
          <Link
            to="/demos/objects"
            className="btn btn-secondary py-3"
            style={{ width: "100%" }}
          >
            <RecordBtn /> Demo - object detection
            
          </Link>
        </div>
        <div className="col-md-6 py-3">
          <Link
            to="/demos/tubes"
            className="btn btn-secondary py-3"
            style={{ width: "100%" }}
          >
            <Rocket /> Demo - tube detection
          </Link>
        </div>
      </div>

      <footer className="mt-auto">
        <Link to="/terms" className="link-secondary">
          Terms of use
        </Link>
        &nbsp;|&nbsp;
        <Link to="/legal" className="link-secondary">
          Legal stuff
        </Link>
      </footer>
    </div>
  );
}


function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  window.x = error

  return (
    <div>
      <h1 className='display-1' style={{color: "maroon"}}>{error.status} {error.statusText}</h1>
      <div>{error.error.message}</div>
    </div>
  );
}

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorBoundary />
    },
    {
      path: "/about",
      element: <div  className='gloBody'><About /></div>,
    },
    {
      path: "/about/:id",
      element: <div  className='gloBody'><About /></div>,
    },
    {
      path: "/annotations",
      element: <div  className='gloBody'><AnnotationList /></div>,
    },
    {
      path: "/annotations/:id",
      element: <div  className='gloBody'><AnnotationUI /></div>,
    },
    {
      path: "/clear_cache",
      element: <div  className='gloBody'><ClearCache /></div>,
    },
    {
      path: "/benchmarking",
      element: <div  className='gloBody'><Benchmarking /></div>,
    },
    {
      path: "/dashboard",
      element: <div  className='gloBody'><Dashboard /></div>,
    },
    {
      path: "/demos/bodypix",
      element: <BodyPixDemo />
    },
    {
      path: "/demos/video",
      element: <GloVideoDemo />
    },
    {
      path: "/demos/annotate",
      element: <GloAnnotate />
    },
    {
      path: "/demos/objects",
      element: <GloObjectDemo />
    },
    {
      path: "/demos/onnx101",
      element: <DemoOnnx />
    },
    {
      path: "/legal",
      element: <div  className='gloBody'><Legal /></div>,
    },
    {
      path: "/login",
      element: <div  className='gloBody'><Login /></div>,
    },
    {
      path: "/logout",
      element: <div  className='gloBody'><Logout /></div>,
    },
    {
      path: "/admin/models",
      element: <div  className='gloBody'><ModelAdmin /></div>,
    },
    {
      path: "/onboarding/:id",
      element: <div  className='gloBody'><Onboarding /></div>,
    },
    {
      path: "/test",
      element: <Test />,
    },
    {
      path: "/batch/:id",
      element: <div  className='gloBody'><Batch /></div>,
    },
    {
      path: "/batch2/:id",
      element: <div  className='gloBody'><Batch2 /></div>,
    },
    {
      path: '/register',
      element: <div  className='gloBody'><Register /></div>
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    // paths related to Tauranga proof of concept
    {
      path: '/select_assay',
      element: <div  className='gloBody'>
          <SelectAssay />
        </div>
    },
    {
      path: '/scanqr',
      element: <div  className='gloBody'>
          <QRScanner />
        </div>
    },
    {
      path: '/capture/:assay',
      element: <div className='gloBody'>
        <CaptureSingleTube />
      </div>
    }
  ],
  {
    // basename: "/glovid_pwa",
    basename: "/"
  }
);

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
