import { Link } from "react-router-dom";
import React, { Component, useEffect, useRef, useState } from "react";

export default function ClearCache() {

    localStorage.clear();
    indexedDB.deleteDatabase('tensorflowjs');
  

  return (
    <div className="container">
      <h1 className="display-1">Cache cleared</h1>

      <p>Indexdb and localStorage have been cleared</p>
      <Link to="/" className='py-2'>Return to homepage</Link>
    </div>
  );
}
